import React from "react";
import { translate } from 'react-admin';
import compose from "recompose/compose";
import { withWidth } from '@material-ui/core';
import params from "./params";

//Chart imports
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


class Chart2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.fontSize = this.fontSize.bind(this)
    }

    fontSize = function () {
        if (this.props.width && this.props.width === 'xs') {
            return params.font_size_mobile;
        } else {
            return params.font_size_desktop;
        }
    };

    componentDidMount() {
        am4core.useTheme(am4themes_animated);

        let chart = am4core.create("chartdiv2", am4charts.XYChart);
        chart.responsive.enabled = true;
        chart.padding(30, 30, 30, 30);

        let title = chart.titles.create();
        title.text = this.props.translate('resources.campaigns.satellites.chart2_title');
        title.fontSize = this.fontSize(); // TODO: tamanho de letra dinamico em relacao a resolucao
        title.marginBottom = 10;

        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "date_st";
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.fontSize = this.fontSize();// TODO: tamanho de letra dinamico em relacao a resolucao

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minGridDistance = 315;
        valueAxis.renderer.fontSize = this.fontSize();// TODO: tamanho de letra dinamico em relacao a resolucao


        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "date_st";
        series.dataFields.valueX = "total_satellites";
        series.tooltipText = "{valueX.value}"
        series.columns.template.strokeOpacity = 10;
        series.columns.template.column.cornerRadiusBottomRight = 5;
        series.columns.template.column.cornerRadiusTopRight = 5;
        series.columns.template.fill = am4core.color(this.props.chart2_color);

        let labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.horizontalCenter = "middle";
        labelBullet.label.dx = 22;
        labelBullet.label.dy = 2;
        labelBullet.label.text = "{values.valueX.workingValue}";
        labelBullet.label.fontSize = this.fontSize();// TODO: tamanho de letra dinamico em relacao a resolucao

        categoryAxis.sortBySeries = series;

        this.series = series;
        this.chart = chart;
        this.title = title;
        this.categoryAxis = categoryAxis;
        this.valueAxis = valueAxis;
        this.labelBullet = labelBullet;
        this.chart.data = this.props.data;
    }

    componentDidUpdate(oldProps) {
        if ((oldProps.min_cor !== this.props.chart1_color) || (oldProps.chart2_color !== this.props.chart2_color)) {
            this.series.columns.template.fill = am4core.color(this.props.chart2_color);
        }
        if (oldProps.width !== this.props.width && (this.props.width === 'xs' || oldProps.width === 'xs')) {
            this.title.fontSize = this.fontSize();
            this.categoryAxis.renderer.fontSize = this.fontSize();
            this.valueAxis.renderer.fontSize = this.fontSize();
            this.labelBullet.label.fontSize = this.fontSize();
        }
        if(oldProps.data !== this.props.data) {
            this.chart.data = this.props.data;
        }
    }
    
    componentWillUnmount(){
        this.chart.dispose();
    }

    render() {
        return (<div id="chartdiv2" className="chart-div2">
        </div>);
    }
}

const enhance = compose(
    translate,
    withWidth()
);

export default enhance(Chart2);