import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset as resetFormAction } from 'redux-form';
import compose from 'recompose/compose';
import { reduxForm } from 'redux-form';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  translate,
  TextInput,
  getDefaultValues,
  required,
  REDUX_FORM_NAME,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
} from 'react-admin';
import {
  Dialog, IconButton,
  DialogTitle,
  DialogContent,
  withStyles,
  // TextField,
  // Select,
  // InputLabel,
  // MenuItem
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons'
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DateTime } from 'luxon';

import EditToolbar from './EditToolbar';
// import DateTimeInput from './DateTime';
// import { DateTimePicker as DateTimeInput } from './Picker';
import { DateTimeInput } from '..';
import { dataFetch } from '../../providers';

const styles = function styles(theme) {

  var height = 32;
  var backgroundColor = theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700];
  // var deleteIconColor = (0, _colorManipulator.fade)(theme.palette.text.primary, 0.26);
  return {
    /* Styles applied to the root element. */
    tag_root: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(14),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: height,
      color: theme.palette.getContrastText(backgroundColor),
      backgroundColor: backgroundColor,
      borderRadius: height / 2,
      whiteSpace: 'nowrap',
      transition: theme.transitions.create(['background-color', 'box-shadow']),
      // label will inherit this from root, then `clickable` class overrides this for both
      cursor: 'default',
      // We disable the focus ring for mouse, touch and keyboard users.
      outline: 'none',
      textDecoration: 'none',
      border: 'none',
      position: 'relative',
      top: '15px',
      // left: '24px',
      // Remove `button` border
      padding: 0, // Remove `button` padding,
      float: 'left',

    },
    root: {
      minWidth: 300
    },
    tag_label: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 12,
      paddingRight: 12,
      userSelect: 'none',
      whiteSpace: 'nowrap',
      cursor: 'inherit'
    },
    content: { padding: 0 },
    titleDiv: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    closeButton: {
      marginTop: '0.5em',
      marginRight: '0.5em'
    },
    TextField: {
      maxWidth: "80%",
      display: 'flex',
      margin: '0 auto'
    },

    times: {
      paddingLeft: "24px",
      paddingRight: "24px"
    }
  }
};

const sanitizeRestProps = ({
  basePath,
  classes,
  crudDelete,
  filterValues,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  label,
  pristine,
  resource,
  saving,
  selectedIds,
  submitOnEnter,
  redirect,
  refreshView,
  showNotification,
  push,
  hasList,
  hasCreate,
  hasShow,
  hasEdit,
  translate,
  openEdit,
  fetchEnd,
  fetchStart,
  reset,
  anyTouched,
  array,
  asyncBlurFields,
  asyncValidate,
  asyncValidating,
  autofill,
  blur,
  change,
  clearAsyncError,
  clearFields,
  clearSubmit,
  clearSubmitErrors,
  destroy,
  dirty,
  dispatch,
  form,
  initialize,
  initialized,
  initialValues,
  pure,
  resetSection,
  refreshData,
  save,
  submit,
  submitFailed,
  submitSucceeded,
  submitting,
  touch,
  triggerSubmit,
  undoable,
  untouch,
  valid,
  validate,
  ...rest
}) => rest;

const Selection = ({ selected, basePath, tagIds, handleChange, ...props }) => {
  props.input.value = props.input.value === "" ? selected : props.input.value;

  props.input.onChange = (...e) => {handleChange(...e)};

  return <SelectArrayInput {...props}>
    <ChipField source='name' />
  </SelectArrayInput>;
}

class DetailsEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openEdit: false,
      description: '',
      multiSelect: [],
      loading: true,
      minDate: undefined,
      maxDate: undefined,
      changed: false,
      beginDate: undefined,
      endDate: undefined,
      // description: undefined,
      tags: undefined,
      errorBegin: false,
      errorEnd: false,
    }
  }

  handleSave = (e) => {
    const { record, refreshData, showNotification } = this.props;
    const { beginDate, endDate, description, tags, changed } = this.state;
    const body = {
      derivedsetId: record.derivedsetId,
      text: description
    };
    const headers = new Headers({
      Accept: 'application/json',
      "Content-Type": "application/json"
    });
    if(record.interval && (beginDate || endDate)) {
      const _beginDate = beginDate ? beginDate : record.beginTime;
      const _endDate = endDate ? endDate : record.endTime;
      
      body.intervals = [{beginTime: new Date(_beginDate), endTime: new Date(_endDate) }]
    } 
    if(tags) {
      body.tags = tags;
    }

    if(changed) {
      dataFetch('PUT', `/annotations/${record.id}`, headers, JSON.stringify(body))
      .then(_ => {
        refreshData();
      })
      .catch(_ => {
        showNotification('resources.annotations.messages.updateError', 'warning')
      })
    }
  }

  handleChangeSelection = (tags) => {
    this.setState({tags, changed: true})
  }

  handleChangeDescription = (e) => {
    this.setState({description: e.target.value, changed: true})
  }
  
  handleChangeDates = (type, date) => {
    let errorBegin = undefined;
    let errorEnd = undefined;

    if(type === 'beginDate') {
      if(date < this.state.minDate) {
        errorBegin = this.props.translate(`resources.annotations.messages.dateGt`, {date: `${DateTime.fromJSDate(this.state.minDate).toFormat('dd/MM/yyyy HH:mm:ss')}`});
      } else if(date > this.state.maxDate) {
        errorBegin = this.props.translate(`resources.annotations.messages.dateLt`, {date: `${DateTime.fromJSDate(this.state.maxDate).toFormat('dd/MM/yyyy HH:mm:ss')}`});
      }
    }
    if(type === 'endDate') {
      if(date < this.state.minDate) {
        errorEnd = this.props.translate(`resources.annotations.messages.dateGt`, {date: `${DateTime.fromJSDate(this.state.minDate).toFormat('dd/MM/yyyy HH:mm:ss')}`});
      } else if(date > this.state.maxDate) {
        errorEnd = this.props.translate(`resources.annotations.messages.dateLt`, {date: `${DateTime.fromJSDate(this.state.maxDate).toFormat('dd/MM/yyyy HH:mm:ss')}`});
      }
    }

    this.setState({[type]: date, changed: true, errorBegin, errorEnd});
  }

  componentDidMount() {
    const { record, showNotification } = this.props;
    this.props.reset('annotation');

    if(record.interval)
      this.setState({ description: record.text });
      // this.setState({ beginDate: record.beginTime, endDate: record.endTime, description: record.text, tags: record.tags ? record.tags.map(({id}) => id) : [] });
    
    this.setState({ description: record.text });

    Promise.all([
      dataFetch('GET', `/derivedsets/${record.derivedsetId}/observations?filter={"order":["timestamp ASC"],"fields":["id"],"limit":1}`),
      dataFetch('GET', `/derivedsets/${record.derivedsetId}/observations?filter={"order":["timestamp DESC"],"fields":["id"],"limit":1}`),
    ])
      .then(result => {
        this.setState({ loading: false, minDate: new Date(result[0][0].timestamp), maxDate: new Date(result[1][0].timestamp) });
      })
      .catch(err => {
        showNotification('resources.annotations.messages.getDatesError', 'warning')
      });
  }

  render() {
    const { translate, onClose, fullWidth, open, classes, className, record, refreshData, ...rest } = this.props;
    return (
      <Dialog open={open} onClose={onClose} fullWidth={fullWidth}>
        <span className={classes.titleDiv}>
          <DialogTitle >
            {translate('containers.annotations.modalEdit')}
          </DialogTitle>

          <IconButton classes={{ root: classes.closeButton }} onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </span>

        <DialogContent onClick={this.handleCLick} className={classes.root}>
          {record.interval ?
            <div className={classes.times}>

              <DateTimeInput
                validate={required()}
                source='beginTime'
                label={translate('resources.launches.fields.beginTimestamp')}
                options={{
                  format: 'dd/MM/yyyy HH:mm:ss',
                  clearable: true,
                  selectTimezone: true,

                }}
                meta={{ touched: this.state.errorBegin ? true : false, error: this.state.errorBegin }}
                onChange={(_, newDate) => this.handleChangeDates('beginDate', new Date(newDate))}
              />
              <DateTimeInput
                validate={required()}
                source='endTime'
                label={translate('resources.launches.fields.endTimestamp')}
                options={{
                  format: 'dd/MM/yyyy HH:mm:ss',
                  clearable: true,
                  selectTimezone: true,
                }}
                meta={{ touched: this.state.errorEnd ? true : false, error: this.state.errorEnd }}
                onChange={(_, newDate) => this.handleChangeDates('endDate', new Date(newDate))}
              />

              <form
                className={classnames('simple-form', className)}
                {...sanitizeRestProps(rest)}
              >
                <br />
                <TextInput
                  {...sanitizeRestProps(this.props)}
                  source='text'
                  label='containers.annotations.description'
                  onChange={this.handleChangeDescription}
                />
                <br />
              </form>
              <br />

              <ReferenceArrayInput reference='tags' resource={'tags'} source='tagIds' label='containers.annotations.tags'>
                <Selection selected={record.tags.map(tag => tag.id)} handleChange={this.handleChangeSelection}/>
              </ReferenceArrayInput>
              <br />
            </div>
            :
            <div className={classes.times}>
              <form
                className={classnames('simple-form', className)}
                {...sanitizeRestProps(rest)}
              >
                <TextInput
                  {...sanitizeRestProps(this.props)}
                  source='text'
                  label='containers.annotations.description'
                  onChange={this.handleChangeDescription}
                />
                <br />

                <ReferenceArrayInput reference='tags' resource={'tags'} source='tagIds' label='containers.annotations.tags'>
                  <Selection selected={record.tags.map(tag => tag.id)} handleChange={this.handleChangeSelection}/>
                </ReferenceArrayInput>
              </form>
            </div>
          }

        </DialogContent>

        <EditToolbar save saveDisabled={!this.state.changed || this.state.errorBegin || this.state.errorEnd} onClick={this.handleSave} record={record} resource="annotations" refreshOnDelete={() => refreshData(true)}/>

      </Dialog>
    )
  }
}

DetailsEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

const mapStateToProps = (state, props) => ({
  form: props.form || REDUX_FORM_NAME,
  initialValues: getDefaultValues(state, props),
  saving: props.saving || state.admin.saving,
});

const enhance = compose(
  translate,
  withStyles(styles),
  connect(mapStateToProps, {
    fetchStart,
    fetchEnd,
    showNotification,
    reset: resetFormAction,
  }),
  reduxForm({
    // destroyOnUnmount: false,
    // enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
  })
);

export default enhance(DetailsEdit);