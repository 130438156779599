import React, { Component, Fragment } from 'react';
import { Responsive, Title, crudGetAll as crudGetAllAction } from 'react-admin';
import { connect } from 'react-redux'

import Welcome from './Welcome';
import CampaignCount from './CampaignCount';
import LastCampaign from './LastCampaign';

const styles = {
  flex: { display: 'flex', marginTop: '1em' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
  welcome: { width: '100%' }
};

class Dashboard extends Component {
  state = {
    campaignCount: undefined,
    recentCampaign: undefined
  };

  componentDidMount() {
    this.props.crudGetAll('campaigns', { field: 'beginDate', order: 'DESC' }, { hide: false }, 15, this.setData)
  }

  setData = ({payload}) => {
    this.setState({campaignCount: payload.total, recentCampaign: payload.data[0]})
  }

  render() {
    const {
      campaignCount,
      recentCampaign
    } = this.state;

    return (
      <Fragment>
        <Title title="WAVY Operation" />
        <Responsive
          xsmall={
            <div>
              <div style={styles.flexColumn}>
                <div style={{ marginBottom: '2em' }}>
                  <Welcome />
                </div>
                <div style={styles.flex}>
                  <LastCampaign value={recentCampaign} />
                </div>
                <div style={styles.flex}>
                  <CampaignCount value={campaignCount} />
                </div>
              </div>
            </div>
          }
          medium={
            <div>
              <div style={styles.welcome}>
                <Welcome />
              </div>
              <div style={styles.flex}>
                <div style={styles.leftCol}>
                  <div style={styles.flex}>
                    <LastCampaign value={recentCampaign} />
                    <CampaignCount value={campaignCount} />
                  </div>
                </div>
                <div style={styles.rightCol}>
                </div>
              </div>
            </div>
          }
        />
      </Fragment>
    );
  }
}

export default connect(null, {
  crudGetAll: crudGetAllAction 
})(Dashboard);