import React from 'react'
import {
  Button,
  Link
} from 'react-admin'
import { RemoveRedEye as DefaultIcon } from '@material-ui/icons'

const LinkButton = ({to='/', label, variant='text', hasIcon, icon }) => (
  <Link to={to}>
    <Button variant={variant} label={label} >{hasIcon && (icon || <DefaultIcon/>)}</Button>
  </Link>
)

export default LinkButton