import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  withStyles 
} from '@material-ui/core';
import { translate } from 'react-admin'

import MobileDatagridCard from './MobileDatagridCard';

const styles = {
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    margin: '0.5rem 0',
  },
  cardTitleContent: {
    display: 'flex',
    flexDirection: 'rows',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardActions: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
};

class MobileDatagrid extends Component {

  handleSelectAll = event => {
    const { onSelect, ids, selectedIds } = this.props;
    if (event.target.checked) {
        onSelect(
            ids.reduce(
                (idList, id) =>
                    idList.includes(id) ? idList : idList.concat(id),

                selectedIds
            )
        );
    } else {
        onSelect([]);
    }
};

  render() {
    const {
      actions,
      basePath,
      classes,
      children,
      data,
      ids,
      resource,
      rowStyle,
      hasBulkActions = false,
      selectedIds,
      onToggleItem,
      translate
    } = this.props;

    const isSelected = selectedIds.length > 0 &&
    ids.length > 0 &&
    !ids.find(
        it => selectedIds.indexOf(it) === -1
    )
    
    return (
      <div style={{ margin: '1em' }}>
        {hasBulkActions && <Fragment>
          <Checkbox
            className="select-all"
            color="primary"
            checked={isSelected}
            onChange={this.handleSelectAll}
          />
          {translate(`pos.general.${isSelected?'un':''}select_all`)}
        </Fragment>}
        {ids.map((id, rowIndex) => (
          <MobileDatagridCard
            actions={actions}
            basePath={basePath}
            classes={classes}
            id={id}
            key={id}
            record={data[id]}
            resource={resource}
            hasBulkActions={hasBulkActions}
            checked={
              selectedIds.length > 0 &&
              ids.length > 0 &&
              selectedIds.indexOf(id) !== -1
            }
            onToggleItem={onToggleItem}
            style={rowStyle ? rowStyle(data[id], rowIndex) : null}
          >
            {children}
          </MobileDatagridCard>
        ))}
      </div>
    );
  }
}

MobileDatagrid.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  resource: PropTypes.string,
  rowStyle: PropTypes.func,
};

MobileDatagrid.defaultProps = { data: {}, ids: [], selectedIds: [] }; 

export default translate(withStyles(styles)(MobileDatagrid));

