// TODO: https://medium.com/sight-machine/building-a-time-zone-enabled-date-picker-3b50e2626fa3 
// https://js.coach/package/react-datetime
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addField, FieldTitle } from 'ra-core';
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from './Picker';
import LuxonUtils from './Picker/utils/luxon-utils';

const makePicker = (PickerComponent) => {
  class _makePicker extends Component {
    onChange(date) {
        const { month } = this.props;
        if(month)
            date = new Date(date)
        this.props.input.onChange(date);
        this.props.input.onBlur();
    }

    componentDidMount(){
      const { dateNow } = this.props;
      if(dateNow){
        this.props.input.onChange(new Date());
      }
      if(this.props.source && this.props[this.props.source]) {
        this.props.input.onChange(new Date(this.props[this.props.source]));
      }
    }

    componentDidUpdate(oldProps){
      if(this.props.source && this.props[this.props.source] && this.props[this.props.source] !== oldProps[this.props.source]) {
        this.props.input.onChange(new Date(this.props[this.props.source]));
      }
    }

    render() {
      const {
        input,
        options,
        label,
        source,
        resource,
        isRequired,
        className,
        meta,
        providerOptions,
        month,
      } = this.props;
      const { touched, error } = meta;
      return (
        <div className="picker">
          <MuiPickersUtilsProvider {...providerOptions}>
            <PickerComponent
              {...options}
              label={<FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />}
              margin="normal"
              error={!!(touched && error)}
              helperText={touched && error}
              ref={(node) => { this.picker = node; }}
              className={className}
              value={input.value ? (month ? new Date(input.value) : input.value) : null}
              onChange={date => this.onChange(date)}
            />
          </MuiPickersUtilsProvider>
        </div>
      );
    }
  }
  _makePicker.propTypes = {
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    ampm: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    labelTime: PropTypes.string,
    className: PropTypes.string,
    providerOptions: PropTypes.shape({
      utils: PropTypes.func,
      locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
  };

  _makePicker.defaultProps = {
    input: {},
    isRequired: 'false',
    meta: { touched: false, error: false },
    options: {},
    resource: '',
    source: '',
    labelTime: '',
    className: '',
    providerOptions: {
      utils: LuxonUtils,
      locale: undefined,
      ampm: false,
      seconds: false,
      selectTimezone: false
    },
  };
  return _makePicker;
};

export const DateInput = addField(makePicker(DatePicker));
export const TimeInput = addField(makePicker(TimePicker));
export const DateTimeInput = addField(makePicker(DateTimePicker));