import React, { Component } from 'react';
import {
  List,
  Responsive
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  DefaultCardActions
} from '../index'

const styles = {
  card: { padding: '1em' }
}

class ResponsiveList extends Component {

  render(){
    const { bulkActionButtons, children, classes, exporter, listActions, listFilters, perPage, recordActions, version, selectedIds, onToggleItem, hasBulkActions, onSelect, ...rest } = this.props;



    return <Responsive
      small={
        <List
          {...rest}
          bulkActionButtons={bulkActionButtons}
          perPage={typeof perPage === 'object' ? perPage.small : perPage}
          actions={listActions}
          filters={listFilters}
          exporter={exporter || false}
        >
          {children}
        </List>
      }
      medium={
        <List
          {...rest}
          bulkActionButtons={bulkActionButtons}
          perPage={typeof perPage === 'object' ? perPage.medium : perPage}
          actions={listActions}
          filters={listFilters}
          exporter={exporter || false}
          classes={{card: classes.card}}
        >
          {children}
        </List>
      }
    />
  }
}

export default withStyles(styles)(ResponsiveList);


ResponsiveList.defaultProps = {
  bulkActionButtons: false,
  listActions: <DefaultCardActions/>,
  perPage:{
    small: 10,
    medium: 25
  }
}