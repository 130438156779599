import { CHANGE_THEME } from './actions';
import { storage } from 'react-admin-loopback';

const loadThemeFromLocalstorage = () =>{
  const userStorage = storage.load('lbtoken');
  const { user } = userStorage;
  return (user && user.settings) ? userStorage.user.settings.theme : 'light';
}

export default (previousState = loadThemeFromLocalstorage(), { type, payload }) => {
  if (type === CHANGE_THEME) {
    return payload;
  }
  return previousState;
};
