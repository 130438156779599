import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  Card,
  List,
} from '@material-ui/core';

import {
  Button,
  NumberInput,
  SimpleForm,
  TextInput,
  Title,
  WithPermissions,
  minValue,
  required,
  translate,
  fetchStart,
  fetchEnd,
  showNotification 
} from 'react-admin';

import AddIcon from '@material-ui/icons/Add';

import { reset as resetFormAction } from 'redux-form';

import {
  SimpleModal
} from '../../components';

import ManualSection from './ManualSection';
import MyToolbar from '../../components/Toolbar/CustomToolbar';

import {
  openSectionModal as openSectionModalAction,
  closeManualModals as closeManualModalsAction,
  editingSection as editingSectionAction
} from './actions';

import { startCheck, createObj, editObj, removeObj } from './localStorage';

class Manual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: {},
      subsections: {},
      editing: false
    };
    this.changeState = this.changeState.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.deleteOff = this.deleteOff.bind(this);
  }

  deleteOff() { // disable editing -> remove button
    this.setState({ editing: false });
    this.props.closeModal();
  }

  componentDidMount() {
    startCheck(this.props.language, this);
  }

  changeState(type, newState) { // update manual
    this.setState({ [type]: newState });
  }
  resetFields() {
    this.props.reset("section");
  }
  addSection = (data) => { // Add / Edit section (just Title and SectionId)
    data.subsection = null;
    const isEditing = this.props.editingSection;
    if(!isEditing) {
      data.id = null;
      data.language = this.props.language;
      data.text = null;
      createObj(data, this.props.closeModal, "sections", this, this.resetFields);
      // this.props.reset("section");
    }
    else {
      editObj(data, this.props.editingSection.id, this.props.closeModal, "sections", this, this.resetFields);
      // this.props.reset("section");
    }
  }

  deleteSection = (data) => { // Remove section
    removeObj(data.id, "sections", this);
  }

  filterData = (data, language) => {
    const filteredData = {};
    for(var manualKey in data){
      if(data[manualKey] !== undefined){
        if(data[manualKey].language === language && data[manualKey].subsection === null){
          filteredData[manualKey] = data[manualKey];
        }
      }
    };
    return filteredData;
  }

  sort = (a, b) => {
    if(parseInt(a.section) < parseInt(b.section)) return -1;
    if(parseInt(a.section) === parseInt(b.section)) return 0; 
    if(parseInt(a.section) > parseInt(b.section)) return 1;
  }

  handleAddClick = () => {
    this.props.openSectionModal(true);
  }

  render(){
    const { closeModal, editingSection, goEditSection, language, openSectionModal, sectionModalOpen, title, translate } = this.props;
    const { sections, subsections, editing } = this.state;
    const filteredSections = this.filterData(sections, language);
    return <WithPermissions 
      render={({permissions}) => <Card>
        <Title title={title} defaultTitle={'Manual'}/>
        <List style={{marginRight: '16px', marginLeft: '16px'}}>
          {
            Object.values(filteredSections).sort(this.sort).map(section => {  return(
              <ManualSection changeState={this.changeState} key={section.id} subsections={subsections} section={section} onEdit={ (section) => {openSectionModal(section.id); goEditSection(section); this.setState({editing: true})} } permissions={permissions}/>
            )})
          }
        </List>
        {permissions === 'sysadmin' && <div>
          <Button style={{marginLeft:'24px', marginBottom:'16px'}} label='containers.manual.addSection' onClick={this.handleAddClick}><AddIcon/></Button>
          <SimpleModal open={sectionModalOpen === true || editingSection !== null} onClose={closeModal} title={translate('containers.manual.addSection')} maxWidth='sm'>
            <SimpleForm toolbar={
                      <MyToolbar editing={editing}  
                          toDelete={this.deleteSection } 
                          toUnmount={this.deleteOff}
                      /> } 
                      form='section' 
                      save={this.addSection}  
                      record={editingSection ? editingSection : {}}
            >
              <TextInput label={translate('containers.manual.fields.title')} source='title' validate={required()} />
              <NumberInput label={translate('containers.manual.fields.section')} source='section' validate={[required(), minValue(1)]} />
            </SimpleForm>
          </SimpleModal>
        </div>}
      </Card>}
    />
  }
}

const mapStateToProps = state => ({
  language: state.i18n.locale,
  sectionModalOpen: state.manual.sectionModalOpen,
  editingSection: state.manual.section
});

const enhance = compose(
  translate,
  connect(mapStateToProps, {
    closeModal: closeManualModalsAction,
    goEditSection: editingSectionAction,
    openSectionModal: openSectionModalAction,
    reset: resetFormAction,
    showNotification,
    fetchStart,
    fetchEnd
  })
);

export default enhance(Manual);