import React from 'react';
import { connect } from 'react-redux';
import {
  UPDATE,
  Title, 
  changeLocale,
  fetchEnd,
  fetchStart,
  translate
} from 'react-admin';
import compose from 'recompose/compose';
import { changeTheme } from './actions';
import {
  Paper,
  CardHeader,
  RadioGroup,
  FormControlLabel,
  Radio,
  withStyles
} from '@material-ui/core';

import { dataProvider, storage } from '../../providers';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  radioGroup: {
    width: '94%',
    alignSelf: 'center'
  }

};

// Defaults at index 0
const themes = ['light', 'dark'];
const languages = ['en-GB', 'pt'];

const updateUserStorageAndSave = (field, value, fetchEnd, fetchStart) => {
  let settings = {
    language: languages[0],
    theme: themes[0] 
  };
  const userStorage = storage.load('lbtoken');
  if(userStorage){
    userStorage.user.settings = userStorage.user.settings || {};
    userStorage.user.settings[field] = value;
    Object.keys(settings).forEach(key => {
      if (!userStorage.user.settings[key]) 
        userStorage.user.settings[key] = settings[key];
    });
    storage.update('lbtoken', userStorage);
    changeInPersistence(userStorage, fetchEnd, fetchStart);
  }
};

const changeInPersistence = (userStorage, fetchEnd, fetchStart) => {
  fetchStart();
  dataProvider(UPDATE, 'users', {
    id: userStorage.user.id,
    data: {
      settings: userStorage.user.settings
    }
  }).finally(fetchEnd());
};

const handleLanguageClick = (changeLocale, language, fetchEnd, fetchStart) => {
  updateUserStorageAndSave('language', language, fetchEnd, fetchStart);
  changeLocale(language);
};

const handleThemeClick = (changeTheme, theme, fetchEnd, fetchStart) => {
  updateUserStorageAndSave('theme', theme, fetchEnd, fetchStart);
  changeTheme(theme);
};

const Configuration = ({ locale, theme, classes, fetchEnd, fetchStart, changeLocale, changeTheme, translate }) => {
  return <Paper className={classes.root}>
    <Title title={translate('pos.configuration')} />
    <CardHeader title={translate('pos.language.name')}/>
    <RadioGroup className={classes.radioGroup} row name={'Language'} value={locale} onChange={(_, language) => handleLanguageClick(changeLocale, language, fetchEnd, fetchStart)}>
      {languages.map(element => (
        <FormControlLabel key={element} value={element} control={<Radio/>} label={translate(`pos.language.${element}`)} />
      ))}
    </RadioGroup>
    <CardHeader title={translate('pos.theme.name')}/>
    <RadioGroup className={classes.radioGroup} row name={'Theme'} value={theme} onChange={(_, theme) => handleThemeClick(changeTheme, theme, fetchEnd, fetchStart)}>
      {themes.map(element => (
        <FormControlLabel key={element} value={element} control={<Radio/>} label={translate(`pos.theme.${element}`)} />
      ))}
    </RadioGroup>
  </Paper>
};

const mapStateToProps = state => ({
  theme: state.theme,
  locale: state.i18n.locale,
});

const enhance = compose(
  connect(
    mapStateToProps,
    {
      changeLocale,
      changeTheme,
      fetchEnd,
      fetchStart
    }
  ),
  translate,
  withStyles(styles)
);

export default enhance(Configuration)