import React from 'react';
import Card from '@material-ui/core/Card';
import DateIcon from '@material-ui/icons/DateRange';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { 
  Link,
  translate 
} from 'react-admin';
import moment from 'moment';

import CardIcon from './CardIcon';

const styles = {
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  },
};

const LastCampaign = ({ value={}, translate, classes }) => {
  let link = `campaigns/create`;
  if(value.id)
    link = `campaigns/${value.id}/show`
  return (
    <div className={classes.main}>
      <Link to={link}>
        <CardIcon Icon={DateIcon} bgColor="#ff9800" />
        <Card className={classes.card}>
          <Typography className={classes.title} color="textSecondary">
            {translate('pos.dashboard.last_campaign')}
          </Typography>
          <Typography variant="headline" component="h2">
            {value.name || ''}
          </Typography>
          <Typography variant="headline" component="h2">
            {value.beginDate ? moment(value.beginDate).utc().format('DD-MM-YYYY') : ''}
          </Typography>
        </Card>
      </Link>
    </div>
  )
};

export default translate(withStyles(styles)(LastCampaign));
