import restProvider, { storage } from 'react-admin-loopback';
import { API_URL } from '../Configuration';
import * as fetchUtils from './fetch'

const httpClient = (url, options = {}) => {
  options.user = {
      authenticated: true,
      token: storage.load('lbtoken').id
  }
  return fetchUtils.fetchJson(url, options);
}

const dataProvider = restProvider(API_URL, httpClient);
export default (type, resource, params) => 
  new Promise(resolve => 
    setTimeout(() => {
      const filter = params.filter;
      params.filter && Object.keys(filter).forEach(key => {
        if (key.charAt(0) === '_') {
          params[key.substr(1)] = filter[key];
          delete filter[key];
        }    
      });
      return resolve(dataProvider(type, resource, params))
    }, 500)  
  );
