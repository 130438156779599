import React from 'react';
import { 
  translate,
  showNotification 
} from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux'
import { Toolbar } from './';
import { ShowTime } from '../'

export default compose(
  translate, 
  connect(
    null, {
      showNotification
    }
  ))(({translate, nextstage, beginTime, showNotification, ...props}) => {
  
    const nextStageControl = () => {
      if(props.map.time <= beginTime){
        showNotification('containers.annotations.need_be_bigger', 'warning');
      }
      else {
        nextstage();
      }
    }

    return (
      <div style={{ marginTop: "5%", paddingLeft: '10px', paddingRight: '10px' }}>
          {ShowTime(translate('containers.annotations.begin'), beginTime)}
          <div>
            { translate('containers.annotations.choose', { hour: translate('containers.annotations.end')}) }
          </div>
          
          <Toolbar nextStageControl={nextStageControl} {...props}/>
        </div>
    );
});