import React from 'react';

import {
  Create,
  Filter,
  SearchInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
  translate
} from 'react-admin';

import withStyles from '@material-ui/core/styles/withStyles';
import LabelIcon from '@material-ui/icons/Label';
import { 
  DefaultCardActions,
  Edit,
  ResponsiveList
} from '../../components';

import { hexToRgb } from '../../utils/util';
import { ColorInput, ColorField } from 'react-admin-color-input';
export const TagIcon = LabelIcon;

const listStyles = {};

const TagFilter = translate(({translate, ...props}) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn label={translate('pos.search')} />
  </Filter>
));

export const TagList = withStyles(listStyles)(({ classes, ...props }) => (
  <ResponsiveList
    {...props}
    listFilters={<TagFilter />}
    recordActions={[<ShowButton />]}
  >
    <TextField source="name" />
    <TextField source="description" />

  </ResponsiveList>
));

export const TagShow = withStyles(listStyles)(({ classes, ...props }) => {
  return(
  <Show actions={<DefaultCardActions />} {...props}>
        <SimpleShowLayout>
          <TextField source="name" />
          <TextField source="description" />
          <ColorField source="color"/>
        </SimpleShowLayout>
  </Show>
)});

export const TagEdit = withStyles(listStyles)(({ classes, ...props }) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        validate={required()}
      />
      <TextInput
        source="description"
      />
      <ColorInput source="color" parse={hexToRgb} />
    </SimpleForm>
  </Edit>
));

export const TagCreate = withStyles(listStyles)(({ classes, ...props }) => (
  <Create actions={<DefaultCardActions/>} {...props}>
    <SimpleForm redirect='show'>
      <TextInput
        source="name"
        validate={required()}
      />
      <TextInput
        source="description"
      />
      <ColorInput source="color" parse={hexToRgb} />
    </SimpleForm>
  </Create>
));