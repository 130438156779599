import { FeatureGroup } from 'react-leaflet';
import L from 'leaflet';
import './KML.js';

export default class KMLControl extends FeatureGroup {

  createLeafletElement( {data} ){
    const { map } = this.context;
    const track = new L.KML(data, {async: true});
    track.on("loaded", function(e) {
			map.fitBounds(e.target.getBounds());
    });
    map.addLayer(track);

    return track;
  }
 
}