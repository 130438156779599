import React, { Component } from 'react'
import{
  Button,
  translate,
  refreshView
} from 'react-admin'
import {
  withStyles
} from '@material-ui/core'
import { Add } from '@material-ui/icons'

import { CustomButton } from '../../components'
import { Details } from '../../components/notes'
import { connect } from 'react-redux'
import compose from 'recompose/compose';

const styles = {
  button:{
    marginTop: '1em',
    marginRight: '0.5em'
  },

  rm_button:{
    marginTop: '1em',
    backgroundColor: 'rgba(255, 0, 0, 0.8)',
    "&:hover": {
      background: 'rgba(255, 0, 0, 1)'
    }
  },
  rm_icon: {
    marginTop: '1em',
    color: 'red',
    "&:hover": {
      background: 'rgba(255, 0, 0, 0.08)'
    }
  }
}

class RelationShow extends Component{
  state = {
    isOpen: false
  }

  sanitizeProps = ({
    dialogContent,
    children,
    reference,
    classes,
    parentrecord,
    secondPress,
    Icon,
    secondLabel,
    refreshView,
    ...props
  }) => ({...props})

  openDialog = () => this.setState({isOpen: true})
  success = () => this.props.refreshView();
  
  closeDialog = () => this.setState({isOpen: false})

  render() {
    const { classes, children, translate, secondPress, Icon, secondLabel, parentrecord } = this.props
    const { isOpen } = this.state;
    return <div>
      <Button className={classes.button} variant='contained' onClick={() => { this.setState({ isOpen: !isOpen })}} label={translate('pos.general.add_button')} /*label={translate('pos.general.add_referencing', {reference: referenceName})}*/><Add/></Button>
      { secondPress !== undefined && <CustomButton classes={{button: classes.rm_button, icon: classes.rm_icon}} variant="contained" onClick={secondPress} label={secondLabel ? secondLabel : ''}>{ Icon ? <Icon/> : null }</CustomButton> }
      { isOpen && <Details open={isOpen} onClose={this.closeDialog} success={this.success} campaign={parentrecord}/>}
      {React.cloneElement(children, this.sanitizeProps(this.props))}
    </div>
  }
}



export default compose(
  withStyles(styles),
  translate,
  connect(
    null, 
    {
      refreshView
    }
  )
)(RelationShow);