import {CREATE, CRUD_CREATE, CRUD_UPDATE, UPDATE} from 'react-admin';

export const UPDATE_RELATED_PROPERTIES = 'UPDATE_RELATED_PROPERTIES';
export const updateRelatedProperties = data => ({ type: UPDATE_RELATED_PROPERTIES, data: data });

export const createEquipModelAction = (data, resource, callback) => ({
  type: CRUD_CREATE,
  payload: { data },
  meta: {
    resource,
    fetch: CREATE,
    onSuccess: {
      callback,
    },
    onFailure: {
      notification: {
        body: 'ra.notification.http_error',
        level: 'warning',
      },
    },
  },
})

export const editEquipModelAction = (resource, id, data, previousData, callback) => ({
  type: CRUD_UPDATE,
  payload: {id, data, previousData},
  meta:{
    resource,
    fetch: UPDATE,
    onSuccess: {
      notification: {
        body: 'ra.notification.updated',
        level: 'info',
        messageArgs: {
          smart_count: 1
        }
      },
      callback
    },
    onFailure: {
      notification: {
        body: 'ra.notification.http_error',
        level: 'warning'
      }
    }
  }
})
