import React, { Component } from 'react';
import compose from 'recompose/compose';
import {
    // Button,
    Toolbar,
    SaveButton,
    translate,
} from 'react-admin';
import { withStyles, createStyles } from '@material-ui/core/styles';
// import MuiButton from '@material-ui/core/Button';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import CustomDeleteButton from '../button/CustomDeleteButton'

const styles = theme =>
    createStyles({
        toDeleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
        defaultToolbar: {
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
        },
    });

class CustomToolbar extends Component {
    constructor(props) {
        super(props)
        this.toDelete = this.toDelete.bind(this)
        this.state = {
            disabled: false
        }
    }

    toDelete() {
        this.setState({disabled: true})
        this.props.toDelete(this.props.record)
        this.componentWillUnmount()
    }

    componentWillUnmount() {
        if(this.props.toUnmount) {
            this.props.toUnmount()
        }
    }

    render() {
        const { editing, classes, toDelete, toUnmount, active = true, saving, ...saveB } = this.props
        // const { submitOnEnter, handleSubmit, handleSubmitWithRedirect, basePath, ...deleteB } = this.props
        return (
        <Toolbar className={classes.defaultToolbar} >
            <SaveButton disabled={!active} onClick={() => saving()} {...saveB}/>
            {/* {editing ? <MuiButton className={classes.toDeleteButton} onClick={this.toDelete}> 
                <ActionDelete />  
                REMOVE
            </MuiButton> : undefined }  */}

            {editing ? <CustomDeleteButton className={classes.toDeleteButton} onClick={this.toDelete} label={"REMOVE"}> 
                <ActionDelete />  
            </CustomDeleteButton> : undefined } 
        </Toolbar>
    )}
 }

const enhance = compose(
    translate,
    withStyles(styles),
)

export default enhance(CustomToolbar);