import React from 'react';
import { connect } from 'react-redux';
import {
  CREATE,
  SimpleForm,
  showNotification
} from 'react-admin';
import { dataProvider } from '../../providers';
import dataFetch from '../../providers/dataFetch';

import {createEquipModelAction as createaction} from './actions';
import {editEquipModelAction as editaction} from './actions';

import { isEqual, remove } from 'lodash';
import { push } from 'react-router-redux';

const sanitizeRestProps = ({
  createaction,
  editaction,
  push,
  showNotification,
  ...props
}) => props;

class CustomSimpleForm extends SimpleForm {
  saveProperty = (id, properties) => {
    return new Promise((resolve, reject) => {
      const {unitOfMeasurement, index, observedpropertyId, visible} = properties;
      dataProvider(CREATE, 'modelproperties', {
        data: {
          equipmentmodelId: id,
          observedpropertyId,
          unitOfMeasurement,
          index,
          visible
        }
      }).then(_ => resolve())
      .catch(error => reject(error));
    });
  };

  deleteProperty = (properties) => {
    return new Promise((resolve, reject) => {
      const { equipmentmodelId, index } = properties;
      // dataFetch must used to prevent Promise resolve on OPTIONS (204) request
      dataFetch('DELETE', `/modelproperties/${equipmentmodelId}/index/${index}`)
        .then(_ => resolve())
        .catch(error => reject(error));
    });
  };

  editProperties = (oldProperties, newProperties) => {
    // Clone properties
    let removeProperties = Object.assign([], oldProperties);
    let addProperties = Object.assign([], newProperties);
    // Remove index 0 for EquipmentId
    addProperties.shift();

    oldProperties && oldProperties.forEach(oldprop => {
      newProperties && newProperties.forEach(newprop => {
        if (isEqual(oldprop, newprop)) {
          remove(removeProperties, oldprop);
          remove(addProperties, newprop);
        }
      });
    });

    return {
      remove: removeProperties,
      add: addProperties,
    }
  };

  handleSave = (record) => {
    // Clone record
    let resource = Object.assign({}, record);
    delete resource.properties;
    if (record.id) {
      // UPDATE
      this.props.editaction(this.props.resource, record.id, resource, resource, (_ => {
        const operations = this.editProperties(this.props.relatedproperties, record.properties);
        let deletePromises = [];
        operations.remove && operations.remove.forEach(property => {
          deletePromises.push(this.deleteProperty(property));
        });

        let createPromises = [];
        operations.add && operations.add.forEach(property => {
          createPromises.push(this.saveProperty(record.id, property));
        });

        Promise.all(deletePromises)
          .catch(_ => this.props.showNotification('resources.equipmentmodels.errors.related_properties', 'warning'))
          .finally(_ => {
            Promise.all(createPromises)
              .catch(_ => this.props.showNotification('resources.equipmentmodels.errors.related_properties', 'warning'))
              // TODO: This should redirect to Show
              .finally(_ => this.props.push(`${this.props.basePath}`));
          });
      }));
    } else {
      // CREATE
      this.props.createaction(resource, this.props.resource, (props => {
        let promises = [];
        const {data} = props && props.payload;
        record.properties && record.properties.forEach((property, index) => {
          // Ignore EquipmentId property
          if (index > 0)
            promises.push(this.saveProperty(data.id, property));
        });

        Promise.all(promises)
          .catch(error => this.props.showNotification('resources.equipmentmodels.errors.related_properties', 'warning'))
          // TODO: This should redirect to Show
          .finally(_ => this.props.push(`${this.props.basePath}`));
      }));
    }
  };

  render() {
    const {
      ...rest
    } = this.props;

    return (
      <SimpleForm {...sanitizeRestProps(rest)} save={this.handleSave}></SimpleForm>
    );
  }
}

CustomSimpleForm.defaultProps = {
  record: {}
};

const mapStateToProps = state => ({
  relatedproperties: state.relatedproperties
});

export default connect(mapStateToProps, {
  createaction,
  editaction,
  push,
  showNotification
})(CustomSimpleForm);