import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS } from 'react-admin';
import { storage } from 'react-admin-loopback';

export const authProvider = (loginApiUrl, noAccessPage = '/login') => {
  return (type, params) => {
    if (type === AUTH_LOGIN) {
      //Include user information
      const request = new Request(loginApiUrl+'?include=user', {
        method: 'POST',
        body: JSON.stringify(params),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      });
      return fetch(request)
        .then(response => response.json())
        .then(({ ttl, role, userId, error, ...data }) => {
          if (error && (error.statusCode < 200 || error.statusCode >= 300)) {
            throw new Error('errors.' + error.code);
          }else{
            storage.save('lbtoken', data, ttl);
            storage.save('role', role, ttl);
            storage.save('userId', userId, ttl);
          }
        })
    }
    if (type === AUTH_LOGOUT) {
      storage.remove('lbtoken');
      storage.remove('userId');
      storage.remove('role');
      return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
      const { status } = params;
      if (status === 401 || status === 403) {
        storage.remove('lbtoken');
        return Promise.reject();
      }
      return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
      const token = storage.load('lbtoken');
      if (token && token.id) {
        return Promise.resolve();
      } else {
        storage.remove('lbtoken');
        return Promise.reject({ redirectTo: noAccessPage });
      }
    }
    if (type === AUTH_GET_PERMISSIONS) {
      const role = storage.load('role');
      return Promise.resolve(role);
    }
    return Promise.reject('Unkown method');
  };
};
