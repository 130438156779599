module.exports = {
  ra: {
    action: {
      delete: 'Eliminar',
      show: 'Mostrar',
      list: 'Listar',
      save: 'Guardar',
      create: 'Criar',
      edit: 'Editar',
      sort: 'Ordenar',
      cancel: 'Cancelar',
      undo: 'Desfazer',
      refresh: 'Atualizar',
      add: 'Adicionar',
      remove: 'Remover',
      add_filter: 'Adicionar filtro',
      remove_filter: 'Remover este filtro',
      back: 'Voltar atrás',
      bulk_actions: '%{smart_count} selecionado',
    },
    boolean: {
      true: 'Sim',
      false: 'Não',
    },
    page: {
      list: 'Lista de %{name}',
      edit: '%{name} #%{id}',
      show: '%{name} #%{id}',
      create: 'Criar %{name}',
      dashboard: 'Painel de controlo',
      not_found: 'Não encontrado',
      loading: 'A carregar',
    },
    input: {
      file: {
        upload_several: 'Largue alguns ficheiros para os enviar, or clique para selecionar um.',
        upload_single: 'Largue um ficheiro para o enviar, ou clique para o selecionar.',
      },
      image: {
        upload_several: 'Largue algumas imagens para as enviar, or clique para a selecionar.',
        upload_single: 'Largue uma imagem para a enviar, ou clique para a selecionar.',
      },
      references: {
        all_missing: 'Incapaz de encontrar dados de referência.',
        many_missing: 'Pelo menos uma das referências associadas parece não estar mais disponível.',
        single_missing: 'Referência a associada parece não estar mais disponível.',
      },
    },
    message: {
      yes: 'Sim',
      no: 'Não',
      are_you_sure: 'Tem a certeza?',
      about: 'Sobre',
      not_found: 'Escreveu mal o URL, ou seguiu um link errado.',
      loading: 'A página está a carregar, aguarde um pouco por favor.',
      invalid_form: 'O formulário está errado. Por favor verifique os erros.',
      delete_title: 'Eliminar %{name} #%{id}',
      delete_content: 'Tem a certeza que pretende eliminar este item?',
      bulk_delete_title: 'Eliminar %{name} |||| Eliminar %{smart_count} %{name} itens',
      bulk_delete_content: 'Tem a certeza que pretende eliminar este/a %{name}? |||| Tem a creteza que pretende eliminar estes %{smart_count} itens?',
    },
    navigation: {
      no_results: 'Resultados não encontrados',
      no_more_results: 'A página número %{page} está fora dos limites. Tente a página anterior.',
      page_out_of_boundaries: 'A página número %{page} está fora dos limites',
      page_out_from_end: 'Não pode ir depois da última página',
      page_out_from_begin: 'Não pode ir antes da primeira página',
      page_range_info: '%{offsetBegin}-%{offsetEnd} de %{total}',
      page_rows_per_page: 'Linhas por página:',
      next: 'Próxima',
      prev: 'Anterior',
    },
    auth: {
      username: 'Nome de utilizador',
      password: 'Senha',
      sign_in: 'Entrar',
      sign_in_error: 'Autenticação falhou, por favor volte a tentar',
      logout: 'Sair',
    },
    notification: {
      updated: 'Item atualizado |||| %{smart_count} itens atualizados',
      created: 'Item criado',
      deleted: 'Item eliminado |||| %{smart_count} itens eliminados',
      bad_item: 'Item incorreto',
      item_doesnt_exist: 'Item inexistente',
      http_error: 'Erro de comunicação com o servidor',
      data_provider_error: 'Erro de fornecedor de dados. Verifique a consola para detalhes.',
      canceled: 'Ação cancelada',
      logged_out: 'A sua sessão terminou, por favor volte a conectar-se.'
    },
    validation: {
      required: 'Obrigatório',
      minLength: 'Deve ter no mínimo %{min} caracteres',
      maxLength: 'Deve ter %{max} ou menos caracteres',
      minValue: 'Deve ser no mínimo %{min}',
      maxValue: 'Deve ser %{max} ou menos',
      number: 'Deve ser um número',
      email: 'Deve ser um email válido',
      oneOf: 'Deve ser um de: %{options}',
      regex: 'Deve corresponder a um formato específico (regexp): %{pattern}',
    },
  },
};