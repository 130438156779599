import { Control, DomUtil, DomEvent } from 'leaflet'

//Static className fixes the problem for now but may cause problems in the future
export default Control.extend({
  options: {
      className: 'leaflet-bar'
  },

  onAdd: function(map){
    var _controlDiv = DomUtil.create('div',this.options.className)
    DomEvent.disableClickPropagation(_controlDiv);
    return _controlDiv
  },

  onRemove: function(map){
    
  }

})
