import React, { Component, Fragment } from 'react';
import { Control } from 'react-leaflet-control-inesc';
import Button from '@material-ui/core/Button';
import { ViewList } from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  List,
  ListItem,
  ListItemText,
  withStyles,
  Typography
} from '@material-ui/core';
import compose from 'recompose/compose';
import {translate} from 'react-admin'
import { SimpleModal } from '../../components';
// import moment from 'moment';

const styles = {
  content: { padding: 0 },
  
  closeButton:{
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  actionButton: {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    width:'30px',
    height: '30px'
  },
  inline: {
    display: 'inline',
  },
  right: {
    float: 'right',
  }
};

class OverviewControl extends Component {
  state = {
    open: false,
    wavysTimer: {},
    intervalId: null,
  };

  closeDialog = () => this.setState({open: false});

  buildPropertiesString = (properties) => {
    let props = [];
    Object.keys(properties).forEach((key) => {
      if (properties[key].value) 
        props.push(`${key}: ${properties[key].value} ${properties[key].unit ? ` ${properties[key].unit}` : ``}`);
    });
    return props.join(' | ');
  }

  timerUpdate = () => {
    const index = setInterval(() => {
      const { elements } = this.props;
      const { wavysTimer } = this.state;
      elements.forEach((wavy) => {
        const { id } = wavy.wavy;
        const { properties } = wavy.position[wavy.position.length-1];
        const tmp = parseInt((new Date().getTime() - new Date(properties.timestamp).getTime()) / 1000);

        const sec = parseInt(tmp % 60);
        const min = parseInt(tmp / 60) >= 60 ? parseInt(tmp / 60) % 60 : parseInt(tmp / 60);
        const hour = parseInt(parseInt(tmp / 60) / 60); 

        let st = "";

        if(hour > 0) 
          st += `${hour}h`;
        else if(min > 0)
          st += `${min}m `;
        else
          st += `${sec}s`;

        wavysTimer[id] = st;
      });


      this.setState({ wavysTimer: wavysTimer })

    } , 1000);
    this.setState({ intervalId: index });
  }

  componentDidUpdate(oldProps) {
    const { elements } = this.props;
    if(elements.length > 0 && this.state.intervalId === null)
      this.timerUpdate();
  } 
  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }
  press = (id) => {
    this.props.openPopupMarker(id);
    this.closeDialog();
  }

  render() {
    const {elements, classes, translate} = this.props;
    
    return <Control position='topright'>
      <div style={{ backgroundColor: 'white' }}>
        <Button classes={{root:classes.actionButton}} onClick={() => this.setState({open:true})}>
          <ViewList style={{fontSize:'15px', padding:'8px', color:'black'}}/>
        </Button>
        <SimpleModal open={this.state.open} onClose={this.closeDialog} title='WAVYS' maxWidth={false}>
          <List>
            {elements.length!==0 ? elements.map(({wavy, position}) => {
              const { properties } = position[position.length-1];
              return <ListItem button onClick={() => this.press(wavy.id)} key={wavy.id} divider>
                <ListItemText 
                  primary={wavy.name}
                  secondary={
                    <Fragment>
                      <Typography component="span" className={classes.inline} color="textSecondary">
                        { this.buildPropertiesString(properties) }
                      </Typography>
                      <Typography component="span" className={classes.right} color="textSecondary">
                        {/* {moment(properties.timestamp).utc().format('DD-MM-YYYY HH:mm:ss')} */}
                        { this.state.wavysTimer[wavy.name] }
                      </Typography>
                    </Fragment>
                  }
                />
              </ListItem>
              
            })
            : <Typography>{translate('ra.navigation.no_results')}</Typography>}
          </List>
        </SimpleModal>
      </div>
    </Control>
  }
};

const mapStateToProps = state => ({
  elements: state.liveData
});

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, {}),
  translate
)

export default enhance(OverviewControl);