import React from 'react';
import Card from '@material-ui/core/Card';
import BoatIcon from '@material-ui/icons/DirectionsBoat';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link, translate } from 'react-admin';

import CardIcon from './CardIcon';

const styles = {
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  },
};

const CampaignCount = ({ value, translate, classes }) => (
  <div className={classes.main}>
    <Link to={'campaigns'}>
      <CardIcon Icon={BoatIcon} bgColor="#31708f" />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {translate('pos.dashboard.campaign_count')}
        </Typography>
        <Typography variant="headline" component="h2">
          {value || 0}
        </Typography>
      </Card>
    </Link>
  </div>
);

export default translate(withStyles(styles)(CampaignCount));
