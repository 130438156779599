import React, { Component } from 'react';
import { Popup } from 'react-leaflet';
import { translate } from 'react-admin';
import moment from 'moment';

const hiddenProperties = ['gpstime', 'timestamp', 'position', 'properties', 'serialNumber', 'id', 'key'];

class PathPopup extends Component {

  render () {
    const { element, last, translate } = this.props;
    const { properties } = last;

    return (
      <Popup>
        <div>
          <h3>{element.name}</h3>
          {
            properties.timestamp &&
              <div>time: {moment(properties.timestamp).utc().format('DD-MM-YYYY HH:mm:ss')} UTC</div>
          }
          {Object.keys(properties).map((key) => {
            if(!hiddenProperties.includes(key) && properties[key].value) {
              const prop = properties[key].value + (properties[key].unit ? ` ${properties[key].unit}`: '');
              return <div key={`${element.id}-${key}`}>
                {`${key}`}: {properties[key].value ? prop : translate('containers.properties.no_data')}
              </div>
            } else {
              return null;
            }
          })}
        </div>
      </Popup>
    );
  }
}

export default translate(PathPopup);