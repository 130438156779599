import React from 'react';
import {
  Button,
  showNotification,
  fetchEnd,
  fetchStart
} from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';
import { storage } from 'react-admin-loopback';
import { saveAs } from 'file-saver';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

const downloadFile = (url, filename, {fetchStart, fetchEnd, showNotification}) => {
  const headers = new Headers();
  headers.append("Content-Disposition", `attachment; filename=${filename || "file" + new Date().now()}`)
  headers.append("Authorization",`${storage.load('lbtoken').id}`)
  fetchStart();
  fetch(url,{
    method:'GET',
    headers: headers
  })
  .then(res => {
    if(res.status < 300 && res.status >= 200){
      return res.blob();
    }else{
      throw new Error('File not found');
    }
  })
  .then(blob => saveAs(blob, filename))
  .catch(error => {
    console.error('File not found error');
    showNotification('resources.datasets.download_error', 'warning');
  })
  .finally(() => fetchEnd())
}

const DownloadButton = ({ alignIcon, color, label, size, filePath, fileName, disabled=false,...props}) => (
  <Button onClick={()=> downloadFile(filePath, fileName, props)} alignIcon={alignIcon} color={color} label={label} size={size} disabled={disabled}>
    <DownloadIcon/>
  </Button>
)


export default compose(
  connect(
    null,
    {
      showNotification,
      fetchEnd,
      fetchStart
    }
  )
)(DownloadButton);

