import React, { Component } from 'react';
import { Button, refreshView, showNotification, Labeled, LinearProgress, TextField } from 'react-admin';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import { translate } from 'ra-core';

// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormGroup from '@material-ui/core/FormGroup';
// import Switch from '@material-ui/core/Switch';

import { push } from 'react-router-redux';
import Confirm from './Confirm';
import { dataFetch } from '../../providers';
// import SimpleModal from '../../components/SimpleModal';
// import CustomToolbar from '../../components/Toolbar/CustomToolbar';

const sanitizeRestProps = ({
  basePath,
  classes,
  crudDelete,
  filterValues,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  label,
  pristine,
  resource,
  saving,
  selectedIds,
  submitOnEnter,
  redirect,
  refreshView,
  showNotification,
  push,
  hasList,
  hasCreate,
  hasShow,
  hasEdit,
  translate,
  ...rest
}) => rest;

const styles = theme =>
  createStyles({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  });

class WAVYOcean extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isOpen: false,
      recoveryMode: false,
      disabled: false,
      buttonLabel: '',
      model: {},
      agreement: undefined,
      modalOpen: false
    };
  }

  getOceanModel() {
    const { record } = this.props;
    let model = {};
    let buttonLabel = '';

    dataFetch('GET', `/equipment/${record.id}/ocean`)
      .then(response => {
        model = response.data;

        if (model.locked) {
          if (model.cmdTo === 1) {
            buttonLabel = 'resources.equipment.recovery.confirm_button_label_activating';
          } else {
            buttonLabel = 'resources.equipment.recovery.confirm_button_label_deactivating';
          }
        } else if (model.mode === 1) {
          buttonLabel = 'resources.equipment.recovery.confirm_button_label_activated';
        } else {
          buttonLabel = 'resources.equipment.recovery.confirm_button_label_deactivated';
        }
      })
      .catch(error => {
        if (error && error.error && error.error.error)
          model.error = error.error.error;
        else
          model.error = 'Error getting WAVY current mode'
        this.props.showNotification('resources.equipment.recovery.failure_notification', 'warning');
        console.error('error', error.error)
      })
      .finally(() => {
        this.setState({ model, loading: false, buttonLabel });
      });
  }

  getAgreement() {
    const { record } = this.props;
    let agreement = undefined;
    dataFetch('GET', `/agreements/last`)
      .then(response => {
        agreement = response;
        return dataFetch('GET', `/agreements/valid/${record.id}`);
      })
      .then(response => {
        agreement.valid = response.valid;
      })
      .finally(() => {
        this.setState({ agreement });
      });
  }

  componentDidMount() {
    // if (this.props.record.equipmentmodelId === 4) {
      this.setState({ loading: true });
      this.getOceanModel();
      // this.getAgreement();
    // }
  }

  handleClick = e => {
    this.setState({ isOpen: true });
    // e.stopPropagation();
  };

  modalClose = () => {
    this.setState({ modalOpen: false });
  }

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleAction = () => {
    this.setState({ loading: true });

    const { showNotification, record } = this.props;
    let model = {};
    let buttonLabel = '';

    const sufix = this.state.model.mode === 1 ? 'operation' : 'recovery';

    dataFetch('POST', `/equipment/${record.id}/ocean/${sufix}`)
      .then(response => {
        model = response.data;
        console.log('OK', response);

        if (model.locked) {
          if (model.cmdTo === 1) {
            buttonLabel = 'resources.equipment.recovery.confirm_button_label_activating';
          } else {
            buttonLabel = 'resources.equipment.recovery.confirm_button_label_deactivating';
          }
        } else if (model.mode === 1) {
          buttonLabel = 'resources.equipment.recovery.confirm_button_label_activated';
        } else {
          buttonLabel = 'resources.equipment.recovery.confirm_button_label_deactivated';
        }
        showNotification('resources.equipment.recovery.success_notification');
      })
      .catch(error => {
        model.error = 'Error getting WAVY current mode';
        showNotification('resources.equipment.recovery.failure_notification', 'warning');
        console.error('error', error.error)
      })
      .finally(() => {
        this.setState({ model, loading: false, buttonLabel });
        this.handleDialogClose();
      });
  };

  save_agreement = () => {
    const { showNotification, record } = this.props;

    dataFetch('PUT', `/agreements/${this.state.agreement.id}/equipments/rel/${record.id}`)
      .then(_ => {
        showNotification('resources.equipment.agreement.success', 'info');
        this.getAgreement();
        this.modalClose();
      })
      .catch(error => {
        showNotification('resources.equipment.agreement.failure', 'warning');
        console.error('error', error.error)
      })
  }

  render() {
    const {
      className,
      icon,
      label,
      c_title,
      c_content,
      onClick,
      record,
      source,
      resource,
      translate,
      hasList,
      ...rest
    } = this.props;

    return <>
          {/* {
            this.state.agreement &&
            (this.state.agreement.valid === false ?
              <>
                <span key={1}>
                  {translate('resources.equipment.agreement.to_accept')}
                </span>
                <span key={2} style={{ cursor: 'pointer', color: '#283593' }} onClick={() => this.setState({ modalOpen: !this.state.modalOpen })}>
                  {translate('resources.equipment.agreement.agreement')}
                </span>
                <br />
              </>
              :
              <>
                <span key={2} style={{ cursor: 'pointer', color: '#283593' }} onClick={() => this.setState({ modalOpen: !this.state.modalOpen })}>
                  {translate('resources.equipment.agreement.agreement')}
                </span>
                <span key={1}>
                  {translate('resources.equipment.agreement.accepted')}
                </span>
              </>
            )
          }

          {
            this.state.agreement &&
            <>
              <SimpleModal footer={<CustomToolbar active={this.state.agreement.valid === false} saving={this.save_agreement} />} title={this.state.agreement.version} open={this.state.modalOpen} onClose={() => this.modalClose()}>
                <span>
                  {this.state.agreement.content}
                </span>
              </SimpleModal>
              <br />
              <br />
            </>
          } */}

          <Labeled label={'resources.equipment.recovery.button_label'}>
            {this.state.model.error
              ? <span>
                {this.state.model.error}
              </span>
              : this.state.loading
                ? <LinearProgress />
                : <Button
                  disabled={this.state.model.locked}
                  onClick={this.handleClick}
                  label={this.state.buttonLabel}
                  key="button"
                  {...sanitizeRestProps(rest)}
                >
                  {icon}
                </Button>
            }
          </Labeled>
          <br />
          <Labeled label={'resources.equipment.fields.identifier'}>

            <TextField
              {...sanitizeRestProps(this.props)}
              source='identifier'
              label='Identifier'
            />
          </Labeled>

          {/* {
            this.state.agreement &&
            <>
              <FormGroup className={className} {...sanitizeRestProps(rest)}>
                <FormControlLabel
                  control={
                    <Switch
                      // disabled={true}
                      // TODO: onClick /agreements/{id}/equipments/rel/{fk}
                      color="primary"
                      checked={!!this.state.agreement.valid}
                    />
                  }
                  label={[
                    <span key={1}>
                      {translate('resources.equipment.agreement.agree1')}
                    </span>,
                    <span key={2} style={{ cursor: 'pointer', color: '#283593' }} onClick={() => this.setState({ modalOpen: !this.state.modalOpen })}>
                      {translate('resources.equipment.agreement.agree2')}
                    </span>
                  ]}
                />
              </FormGroup>

              <SimpleModal title={this.state.agreement.version} open={this.state.modalOpen} onClose={() => this.modalClose()}>
                <span>
                  {this.state.agreement.content}
                </span>
              </SimpleModal>
            </>

          } */}

          <Confirm
            isOpen={this.state.isOpen}
            title={this.state.buttonLabel}
            content={this.state.model.mode === 1 ?
              translate('resources.equipment.recovery.confirm_text_deactivate') :
              translate('resources.equipment.recovery.confirm_text_active')
            }
            onConfirm={this.handleAction}
            onClose={this.handleDialogClose}
          />
        </>
  }
}

WAVYOcean.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  translate: PropTypes.func,
  icon: PropTypes.element,
};

WAVYOcean.defaultProps = {
  redirect: 'list',
  icon: <DonutLargeIcon />,
};

export default compose(
  connect(null, {
    refreshView,
    showNotification,
    push
  }),
  translate,
  withStyles(styles)
)(WAVYOcean);
