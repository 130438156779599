import React from 'react';

const GeoCoordinatesField = ({source, record = {}}) => <span>
  {record[source] ? record[source].lat : '---'} | {record[source] ? record[source].lng : '---'}
</span>;

GeoCoordinatesField.defaultProps ={
  addLabel: true
};

export default GeoCoordinatesField;