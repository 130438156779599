import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-admin';
import shouldUpdate from 'recompose/shouldUpdate';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import get from 'lodash/get';


const ShowRelatedButton = ({
  basePath = '',
  record = {},
  label = 'ra.action.show',
  reference,
  source_,
  icon,
  isVisible = () => true,
  ...rest
}) => {

  return (
    isVisible(record) ? <Button
      component={Link}
      to={{
        pathname: `/${reference}`,
        search: stringify({
          page: 1,
          perPage: 25,
          filter: JSON.stringify({ [source_]: get(record, 'id') }),
        }),
        state: {
          dataset: record
        }
      }}
      label={label}
      {...rest}
    >
      {icon ? icon : <ImageEye />}
    </Button> : null
  )
};

ShowRelatedButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  isVisible: PropTypes.func,
  reference: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

const enhance = shouldUpdate(
  (props, nextProps) =>
    props.translate !== nextProps.translate ||
    (props.record &&
      nextProps.record &&
      props.record.id !== nextProps.record.id) ||
    props.basePath !== nextProps.basePath ||
    (props.record == null && nextProps.record != null)
);

export default enhance(ShowRelatedButton);