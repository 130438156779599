import React from 'react';
import {
  translate
} from 'react-admin';
import compose from 'recompose/compose';
import {
  Typography,
  withStyles
} from '@material-ui/core'

const styles = {
  terminated: {
    color: 'red'
  },
  open: {
    color: 'green'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  iconButton: {
    marginLeft: '8px'
  }
}

const LaunchOpenField = ({ classes, record, translate }) => (
  record.launches.length > 0 ? <Typography color='primary' classes={{ colorPrimary: classes.terminated }}>{translate('pos.general.in_use')}</Typography> 
    : <Typography color='primary' classes={{ colorPrimary: classes.open }}>{translate('pos.general.available')}</Typography>
)

const enhance = compose(
  translate,
  withStyles(styles),
)

export default enhance(LaunchOpenField);