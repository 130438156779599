import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  ListItem, Collapse
} from '@material-ui/core';

import compose from 'recompose/compose';

import {
  Button,
  NumberInput,
  SimpleForm,
  TextInput,
  minValue,
  required,
  translate,
  fetchStart,
  fetchEnd,
  showNotification 
} from 'react-admin';

import { reset as resetFormAction } from 'redux-form';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import {
  SimpleModal
} from '../../components';

import MyToolbar from '../../components/Toolbar/CustomToolbar';
import ManualSubsection from './ManualSubsection';
import ManualInfo from "./ManualInfo";

import {
  openSubSectionModal as openSubSectionModalAction,
  closeManualModals as closeManualModalsAction,
  editingSubSection as editingSubsectionAction
} from './actions';

import { createObj, editObj, removeObj } from './localStorage'

class ManualSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subsectionOpen: false,
      editing: false
    };
    this.deleteOff = this.deleteOff.bind(this);
    this.resetFields = this.resetFields.bind(this);

  }
  resetFields() {
    this.props.reset("subsection");
  }
  addSubSection = (data) => {
    this.changeState = this.props.changeState;
    const isEditing = this.props.editingSubsection;
    if(!isEditing) {
      data.id = null;
      data.section = this.props.section.section;
      data.language = this.props.language;
      data.text = null;
      createObj(data, this.props.closeModal, "subsections", this, this.resetFields);
      // this.props.reset("subsection");
    }
    else {
      editObj(data, this.props.editingSubsection.id, this.props.closeModal, "subsections", this, this.resetFields);      
    }
  }

  handleClick = () => {
    if(!this.state.subsectionOpen) {
      this.setState({ subsectionOpen: true });
    }
    else { 
      this.setState({ subsectionOpen: false });
    }
  }

  handleEditClick = () => {
    this.props.onEdit(this.props.section);
  }

  deleteSubsection = (data) => { // Remove section
    this.changeState = this.props.changeState;
    removeObj(data.id, "subsections", this);
  }
  
  deleteOff() { // disable editing -> remove button
    this.setState({ editing: false });
    this.props.closeModal();
  }

  filterData = (data, language) => {
    const filteredData = {};
    for(var manualKey in data){
      if(data[manualKey] !== undefined){
        if(data[manualKey].language === language && data[manualKey].section === this.props.section.section && data[manualKey].subsection !== null){
          filteredData[manualKey] = data[manualKey] ;
        }
      }
    };
    return filteredData;
  }

  sort = (a, b) => {
    if(parseInt(a.subsection) < parseInt(b.subsection)) return -1;
    if(parseInt(a.subsection) === parseInt(b.subsection)) return 0; 
    if(parseInt(a.subsection) > parseInt(b.subsection)) return 1;
  }

  handleAddSubsection = () => {
    this.props.openSubSectionModal(this.props.section.id);
  }

  render() {
    const { closeModal, 
            editingSubsection, 
            goEditSubsection, 
            language, 
            openSubSectionModal, 
            permissions, 
            section, 
            subSectionModalOpen, 
            subsections, 
            translate } = this.props;

    const { subsectionOpen, editing } = this.state;
    const filteredSubsections = this.filterData(subsections, language);
    return <div>
      <div style={{display: 'flex', width:'100%', flexDirection: 'row'}}>
        <ListItem style={{flex:1}} button divider onClick={this.handleClick}>{this.props.section.section}. {this.props.section.title}</ListItem>
        {permissions === 'sysadmin' && <Button onClick={this.handleEditClick} label={'ra.action.edit'}>{<EditIcon/>}</Button>}
      </div>
      <Collapse in={subsectionOpen} unmountOnExit>
        { <ManualInfo changeState={this.props.changeState} type={"sections"} manualEntry={section} permissions={permissions}/> }
        {
          Object.values(filteredSubsections).sort(this.sort).map(subsection =>  (
            <ManualSubsection changeState={this.props.changeState} key={subsection.id} subsection={subsection} onEdit={(subsection) => {openSubSectionModal(section.id); goEditSubsection(subsection); this.setState({editing: true})}} permissions={permissions}/>
          ))
        }
      {subsectionOpen && permissions === 'sysadmin' && <Button style={{paddingLeft:'24px'}} label='containers.manual.addSubSection' onClick={this.handleAddSubsection}><AddIcon/></Button>}
      </Collapse>
      { permissions === 'sysadmin' &&
        <SimpleModal open={subSectionModalOpen === section.id} onClose={closeModal} title={translate('containers.manual.addSubSection')} maxWidth='sm'>
          <SimpleForm toolbar={
                    <MyToolbar editing={editing} 
                        toDelete={this.deleteSubsection } 
                        toUnmount={this.deleteOff} 
                    /> } 
                    form='subsection' 
                    resource='manuals' 
                    save={this.addSubSection} 
                    record={editingSubsection ? editingSubsection : {}}
          >
            <TextInput label={translate('containers.manual.fields.title')} source='title' validate={required()} />
            <NumberInput label={translate('containers.manual.fields.subsection')} source='subsection' validate={[required(), minValue(1)]} />
          </SimpleForm>
        </SimpleModal>
      }
    </div>
  }
}

const mapStateToProps = state => ({
  language: state.i18n.locale,
  subSectionModalOpen: state.manual.subSectionModalOpen,
  editingSubsection: state.manual.subsection,
});

const enhance = compose(
  translate,
  connect(mapStateToProps, {
    closeModal: closeManualModalsAction,
    openSubSectionModal: openSubSectionModalAction,
    goEditSubsection: editingSubsectionAction,
    reset: resetFormAction,
    fetchStart,
    fetchEnd,
    showNotification 
  })
);

export default enhance(ManualSection);