const params = {
    host: 'http://localhost:3001', //Endereço do servidor
    default_alt: '0', //Altura geografica enviada para o pedido de dados
    default_aditional_date: (1000 * 60 * 60 * 24 * 14), //intervalo em milisegundos da Data de Inicio para a Data de Fim
    font_size_mobile: '9', //Tamanho de letra para Mobile
    font_size_desktop: '13', //Tamanho de letra para Desktop
    colors: [{//Cores para apresentação dos graficos
        color: 'Azul', //cor default
        chart1_color: '#c3c9ef',//#a6b0f5 //Cor das colunas do 1o grafico
        chart1_color_prob: '#283593',   //Cor das "pintas" do 1o grafico
        chart2_color: '#283593' //Cor das colunas horizontais do 2o grafico
    },
    {
        color: 'Cinzento',
        chart1_color: '#cfcfcf',
        chart1_color_prob: '#4b4b4b',
        chart2_color: '#4b4b4b'
    },
    {
        color: 'Laranja',
        chart1_color: '#f0c7ad',
        chart1_color_prob: '#f76305',
        chart2_color: '#f76305'
    }],
};
export default params;