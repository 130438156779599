import React from 'react';
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import withRouter from 'react-router-dom/withRouter'

const goback = (history) => (history.goBack());

const BackButton = ({history, ...rest}) => (
  //Not sure if color=inherit works with themes
  <IconButton color='inherit' onClick={()=>goback(history)}><ArrowBack/></IconButton>
);

export default withRouter(BackButton);