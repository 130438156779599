import { LayersControl } from 'react-leaflet'
// https://stackoverflow.com/questions/40906118/is-it-possible-to-add-custom-html-to-leaflet-layer-groups-and-layers-control/40906679
// import L from 'leaflet';


// LayersControl.WithSomethingExtra = LayersControl.extend({
//   _initLayout: function() {
//     LayersControl.prototype._initLayout.call(this);
//     L.DomUtil.create('div', 'leaflet-control-layers-separator', this._form);
//     var myThing = L.DomUtil.create('div', 'some-extra-thing', this._form);
//     myThing.innerHTML = 'My custom thing inside the layers control!!';
//   }
// });
export default class ContextLayersControl extends LayersControl {

  componentDidMount(){
    let { map } = this.context;
    // const { setAnnotationTag } = this.props;
    map.time = 0;
    map.on('overlayadd', onOverlayAdd);
    function onOverlayAdd(e){
      
      if(e.layer.feature && e.layer.feature) {
        if(!e.layer.feature.properties.multiLine){
          e.layer.bringToBack();
        }
        else {
          e.layer.bringToFront();
          // e.layer.on('click', (_e) => {
          //   // const id = e.layer.feature.properties.multiLine;
          //   // setAnnotationTag(_e.target.feature.properties.multiLineIds);
          //   console.log(_e.target.feature.properties);
          // })
          // e.layer.on('hover', (_e) => {
          //   // const id = e.layer.feature.properties.multiLine;
          //   // setAnnotationTag(_e.target.feature.properties.multiLineIds);
          //   console.log("hover?");
          // })
        }
      }
    }

    const layer = this.leafletElement.addTo(map);
    this.props.sendCtrlLayer(layer);
  }
}