import React, { Component } from 'react'
import { NoteAdd } from '@material-ui/icons'
import { translate, Button} from 'react-admin'
import CreateAnnotationsDialog from './CreateAnnotationsDialog'

const AddAnnotationActionButton = translate(class extends Component {

  state = {
    open: false
  }

  handleClose = () => this.setState({open: false})

  handleOpen = () => this.setState({open: true})

  render(){

    const { translate, reference } = this.props
  
    return <div>
      <Button 
        label={translate('pos.general.add_referencing', { reference: translate(`resources.${reference}.name`, {smart_count:2} ) } )} 
        onClick={this.handleOpen}><NoteAdd/></Button>
      <CreateAnnotationsDialog open={this.state.open} onClose={this.handleClose} fullWidth edit={false} observationIds={this.props.selectedIds} insertedAnnotations={this.props.insertedAnnotations} {...this.props}/>
    </div>
  }

})

export default AddAnnotationActionButton