import React, { Component } from 'react'
import {
    showNotification,
    translate,
    Responsive
} from 'react-admin'
import { Build } from '@material-ui/icons'
import dataFetch from '../../providers/dataFetch';
import MuiButton from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = {
    button: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    label: {
        paddingLeft: '0.5em',
    },
    labelRightIcon: {
        paddingRight: '0.5em',
    },
    smallIcon: {
        fontSize: 20,
    },
    mediumIcon: {
        fontSize: 22,
    },
    largeIcon: {
        fontSize: 24,
    },
};

class BuildButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false
        }
        this.relatedData = this.relatedData.bind(this);
    }

    componentDidMount() {
        this.setState({ disabled: false })
    }

    doRequest(url) {
        const fetch = () => {
            return new Promise((resolve, reject) => {
                dataFetch('GET', url)
                    .then(data => {
                        resolve(data)
                    })
                    .catch(err => reject(err));
            });
        };

        fetch().then((result) => {
            if (result.id) {
                this.setState({ disabled: true });
                this.props.showNotification("resources.launches.message.build");
            }
            else
                this.props.showNotification('error.BUILDING_ERROR');
        })
            .catch(e => {
                // console.error('Build error', e);
                this.props.showNotification(this.props.translate('error.BUILDING_ERROR', 'warning'))
            });
    }

    relatedData = (e) => {
        const { recordId, record } = this.props;
        this.doRequest(`/observations/launch/${record[recordId]}/build`)
    }

    render() {
        const { classes, className, translate } = this.props;
        const label = 'resources.launches.buttons.build'
        return (

            <Responsive
                small={
                    !this.state.disabled ? (
                        <Tooltip title={translate(label)}>
                            <IconButton
                                aria-label={translate(label)}
                                className={className}
                                color='primary'
                                onClick={this.relatedData}
                            >
                                <Build className={classes[`smallIcon`]} />

                            </IconButton>
                        </Tooltip>
                    ) : (
                            <IconButton
                                className={className}
                                disabled={this.state.disabled}
                            // onClick={this.relatedData}
                            >
                                <Build className={classes[`smallIcon`]} />
                            </IconButton>
                        )
                }
                medium={
                    <MuiButton
                        disabled={this.state.disabled}
                        className={classnames(classes.button, className)}
                        color='primary'
                        size='small'
                        onClick={this.relatedData}
                    >
                        <Build className={classes[`smallIcon`]} />
                        <span
                            className={classnames({
                                [classes.label]: 'right'
                            })}
                        >
                            {translate(label)}
                        </span>

                    </MuiButton>
                }
            />
        )
    }
}

const enhance = compose(
    translate,
    withStyles(styles),
    connect(null, { showNotification })
)

export default enhance(BuildButton)
