import React from 'react';
import {
  translate
} from 'react-admin'
import { Button } from '@material-ui/core'
import { List } from '@material-ui/icons'


const back = (history) => {
  history.goBack()
}

const HistoryButton = ({
    basePath,
    className,
    classes,
    children,
    resource,
    translate,
    exporter,
    currentSort,
    history,
    color='primary',
    size='small',
    ...rest
}) => {
  return <Button 
    onClick={() => back(history)} 
    color={color}
    size={size}
  >
    <List/>
    {translate('ra.action.list')}
  </Button>
};

export default translate(HistoryButton);