import { storage } from 'react-admin-loopback';
import { API_URL } from '../Configuration';

// TODO: Improve this one
export default (method, path, headers = new Headers(), body) => 
  new Promise((resolve, reject)  => {
    headers.append("Authorization",`${storage.load('lbtoken').id}`);
  
    // fetch(`${API_URL}${path}`,{
    //   method,
    //   headers,
    //   body
    // })
    // .then(resp=>resp.json())
    // .then(data => {
    //   resolve(data);
    // })
    // .catch(error => {
    //   reject(error);
    // });

    let goCatch = false;
    fetch(`${API_URL}${path}`,{
      method,
      headers,
      body
    })
    .then(resp => {
      if (resp.status === 200 || resp.status === 201)
        return resp.json();
      else if(resp.status === 204) 
        return undefined;
      else {
        goCatch = true;
        return resp.json();
      }
    })
    .then(data => {
      if (goCatch) {
        return reject(data);
      }
      else
        return resolve(data);
    })
    .catch(error => {
      reject(error);
    });
  });