import dataFetch from '../../providers/dataFetch';

const getTitles = (url, filter) => (
  new Promise( (resolve, reject) => {
    dataFetch('GET', `${url}?filter=${JSON.stringify(filter)}`)
      .then(data => {
        let result = {};
        
        Object.keys(data).forEach(key => {
          const obj = data[key]
          result[obj.id] = obj;
          delete data[key];
        });
        
        resolve(result)
      })
      .catch(err => reject(err));
  })
)

const getObj = (url, id, type, showNotification) => ( 
  new Promise( (resolve, reject) => {
    dataFetch('GET', url)
      .then((result) => {
        let manual = JSON.parse(localStorage.getItem(`manual${type}`));
        manual[id] = result;
        try {
          localStorage.setItem(`manual${type}`, JSON.stringify(manual));
        }
        catch (e) {
          console.log("Local Storage is full, please empty data");
          showNotification(`errors.LOCALSTORAGE_FULL`, 'warning');
        }

        resolve()
      })
      .catch(err => reject(err));
  })
)

const checkManual = (objs, type, Parent) => {
  const { fetchStart, showNotification, fetchEnd } = Parent.props;

  let manual = JSON.parse(localStorage.getItem(`manual${type}`));
  let promises = [];
  fetchStart();

  Object.keys(objs).forEach(key => {
    if( manual[key] === undefined ||
        manual[key] === null ){
          promises.push(getObj(`/manuals/${objs[key].id}`, key, type, showNotification))
    }
    else if( !((manual[key].id === objs[key].id) &&
              (manual[key].updatedAt === objs[key].updatedAt)) ) {
                promises.push(getObj(`/manuals/${manual[key].id}`, key, type, showNotification))
    }
    delete manual[key];
  });

  Promise.all(promises).then( () => {
    const to_delete = Object.keys(manual);
    let newManual = JSON.parse(localStorage.getItem(`manual${type}`));
   
    if(to_delete.length > 0) {
      to_delete.forEach(key => {
        delete newManual[key];
      })
      try {
        localStorage.setItem(`manual${type}`, JSON.stringify(newManual));
      }
      catch (e) {
        console.log("Local Storage is full, please empty data");
        showNotification(`errors.LOCALSTORAGE_FULL`, 'warning');
      }
    }

    Parent.changeState(type, newManual);
  })
  .catch(err => {
    if(err.error && err.error.code)
      showNotification(`errors.${err.error.code}`, 'warning');
    else
      showNotification(`errors.SOMETHING`, 'warning');
  })
  .finally(_ => fetchEnd());
}

// check titles
const check = (filter, type, Parent) => {
  let manual = localStorage.getItem(`manual${type}`);
  const { fetchStart, showNotification, fetchEnd } = Parent.props;

  if(manual === null || manual === undefined) {
    try {
      localStorage.setItem(`manual${type}`, JSON.stringify({}));
    }
    catch (e) {
      console.log("Local Storage is full, please empty data");
      showNotification(`errors.LOCALSTORAGE_FULL`, 'warning');
    }
  }

  fetchStart(); 

  getTitles(`/manuals`, filter, type).then( (result) => {
    Parent.changeState(type, result); // Pode já mostrar os titulos
    checkManual(result, type, Parent);
  })
  .catch(err => {
    if(err.error && err.error.code)
      showNotification(`errors.${err.error.code}`, 'warning');
    else
      showNotification(`errors.SOMETHING`, 'warning');
  })
  .finally(_ => fetchEnd());
}

export const startCheck = (lang, Parent) => {
  let filter = {
    where: {
      and: [
        {subsection: null},
        {language: lang}
      ]
    },
      fields:{
        text: null
      }
  }
  check(filter, `sections`, Parent)

  filter = {
    where: {
      and: [
        {language: lang},
        {subsection: {
          neq: null
        }}
      ]
    },
    fields: {
      text: null
    }
  }
  check(filter, `subsections`, Parent)
}

export const editObj = (body, id, closeModal, type, Parent, reset = false) => {
  const headers = new Headers({
    Accept: 'application/json',
    "Content-Type": "application/json"
  });
  const { fetchStart, showNotification, fetchEnd } = Parent.props;
  fetchStart(); 

  dataFetch('PATCH', `/manuals/${id}`, headers, JSON.stringify(body))
    .then((result) => {

      let manual = JSON.parse(localStorage.getItem(`manual${type}`));
      const section = manual[result.id].section;
      manual[result.id] = result;

      try {
        localStorage.setItem(`manual${type}`, JSON.stringify(manual));
      }
      catch (e) {
        console.log("Local Storage is full, please empty data");
        showNotification(`errors.LOCALSTORAGE_FULL`, 'warning');
      }
      Parent.changeState(type, manual);

      if(type === "sections") {
        let manualSub = JSON.parse(localStorage.getItem(`manualsub${type}`));
        let promises = [];
        Object.keys(manualSub)
          .filter(key => manualSub[key].section === section)
          .forEach(key => {
            promises.push(getObj(`/manuals/${manualSub[key].id}`, key, `sub${type}`, showNotification));
          });
        Promise.all(promises).then( () => {
          manualSub = JSON.parse(localStorage.getItem(`manualsub${type}`));
          Parent.changeState(`sub${type}`, manualSub);
          closeModal();
        })
        .catch(err => {
          if(err.error && err.error.code)
            showNotification(`errors.${err.error.code}`, 'warning');
          else
            showNotification(`errors.SOMETHING`, 'warning');
        })
        .finally(_ => fetchEnd());
      }
      else {
        closeModal();
      }
      if(reset)
        reset();
    })
    .catch(err => {
      if(err.error && err.error.code)
        showNotification(`errors.${err.error.code}`, 'warning');
      else
        showNotification(`errors.SOMETHING`, 'warning');
    })
    .finally(_ => fetchEnd());
}

export const createObj  = (body, closeModal, type, Parent, reset = false) => {
  const headers = new Headers({
    Accept: 'application/json',
    "Content-Type": "application/json"
  });
  
  const { fetchStart, showNotification, fetchEnd } = Parent.props;
  fetchStart(); 

  dataFetch('POST', '/manuals', headers, JSON.stringify(body))
    .then((result) => {
     
      let manual = JSON.parse(localStorage.getItem(`manual${type}`));
      manual[result.id] = result;

      try {
        localStorage.setItem(`manual${type}`, JSON.stringify(manual));
      }
      catch (e) {
        console.log("Local Storage is full, please empty data");
        showNotification(`errors.LOCALSTORAGE_FULL`, 'warning');
      }
      Parent.changeState(type, manual);
      closeModal();
      if(reset)
        reset();
    })
    .catch(err => { 
      if(err.error && err.error.code)
        showNotification(`errors.${err.error.code}`, 'warning');
      else
        showNotification(`errors.SOMETHING`, 'warning');
    })
    .finally(_ => fetchEnd());
}

export const removeObj = (id, type, Parent) => {
  const { fetchStart, showNotification, fetchEnd } = Parent.props;
  fetchStart(); 
  
  dataFetch('DELETE', `/manuals/${id}`)
    .then((result) => {
      let manual = JSON.parse(localStorage.getItem(`manual${type}`));
      delete manual[id];

      try {
        localStorage.setItem(`manual${type}`, JSON.stringify(manual));
      }
      catch (e) {
        console.log("Local Storage is full, please empty data");
        showNotification(`errors.LOCALSTORAGE_FULL`, 'warning');
      }
      Parent.changeState(type, manual);
    })
    .catch(err => {
      if(err.error && err.error.code)
        showNotification(`errors.${err.error.code}`, 'warning');
      else
        showNotification(`errors.SOMETHING`, 'warning');
    })
    .finally(_ => fetchEnd());
}

export const updateObj = (id, body, type, Parent) => {
  const headers = new Headers({
    Accept: 'application/json',
    "Content-Type": "application/json"
  });

  const { fetchStart, showNotification, fetchEnd } = Parent.props;
  fetchStart();  
  
  dataFetch('PATCH', `/manuals/${id}`, headers, JSON.stringify(body))
    .then((result) => {
      let manual = JSON.parse(localStorage.getItem(`manual${type}`));
      manual[result.id] = result;

      try {
        localStorage.setItem(`manual${type}`, JSON.stringify(manual));
      }
      catch (e) {
        console.log("Local Storage is full, please empty data");
        showNotification(`errors.LOCALSTORAGE_FULL`, 'warning');
      }
      Parent.changeState(type, manual);
    })
    .catch(err => {
      console.log(err)
      if(err.error && err.error.code)
        showNotification(`errors.${err.error.code}`, 'warning');
      else
        showNotification(`errors.SOMETHING`, 'warning');
    })
    .finally(_ => fetchEnd());
}
