import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Clock from './Clock';
import * as clockType from '../../constants/clock-types';
import withUtils from '../../_shared/WithUtils';
import { getHourNumbers, getMinutesNumbers } from './ClockNumbers';

export class TimePickerView extends PureComponent {
  static propTypes = {
    date: PropTypes.object.isRequired,
    onHourChange: PropTypes.func.isRequired,
    onMinutesChange: PropTypes.func.isRequired,
    onSecondsChange: PropTypes.func.isRequired,
    utils: PropTypes.object.isRequired,
    ampm: PropTypes.bool,
    type: PropTypes.oneOf(Object.keys(clockType).map(key => clockType[key])).isRequired,
    utc:  PropTypes.bool,
  }

  static defaultProps = {
    ampm: true,
    utc: false
  }

  getViewProps = () => {
    const {
      type, ampm, date, utils, utc
    } = this.props;

    switch (type) {
      case clockType.HOURS:
        return {
          value: utils.getHours(date, utc),
          children: getHourNumbers({ date, ampm, utils, utc }),
          onChange: this.handleHourChange,
        };

      case clockType.MINUTES:
        const minutesValue = utils.getMinutes(date, utc);
        return {
          value: minutesValue,
          children: getMinutesNumbers({ value: minutesValue, utils }),
          onChange: this.handleMinutesChange,
        };

      case clockType.SECONDS:
        const secondsValue = utils.getSeconds(date, utc);
        return {
          value: secondsValue,
          children: getMinutesNumbers({ value: secondsValue, utils }),
          onChange: this.handleSecondsChange,
        };

      default:
        throw new Error('You must provide the type for TimePickerView');
    }
  }

  handleHourChange = (hours, isFinish) => {
    const { date, utils, utc } = this.props;
    const updatedTime = utils.setHours(date, hours, utc);
    this.props.onHourChange(updatedTime, isFinish);
  };

  handleMinutesChange = (minutes, isFinish) => {
    const { date, utils, utc } = this.props;
    const updatedTime = utils.setMinutes(date, minutes, utc);

    this.props.onMinutesChange(updatedTime, isFinish);
  };

  handleSecondsChange = (seconds, isFinish) => {
    const { date, utils, utc } = this.props;
    const updatedTime = utils.setSeconds(date, seconds, utc);

    this.props.onSecondsChange(updatedTime, isFinish);
  };

  render() {
    const { ampm, type } = this.props;
    const viewProps = this.getViewProps();

    return (
      <Clock
        type={type}
        ampm={ampm}
        {...viewProps}
      />
    );
  }
}

export default withUtils()(TimePickerView);

