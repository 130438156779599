import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import {
  Create,
  // DateField,
  FileField,
  FileInput,
  SimpleForm,
  TextField,
  TextInput,
  SimpleShowLayout,
  Show,
  ShowButton,
  Responsive,
  required,
  Filter,
  SearchInput,
  translate,
  SelectInput
  // showNotification
} from 'react-admin';
import {
  DefaultCardActions,
  DateField,
  DownloadButton,
  ResponsiveList
} from '../../components';
import { URL/*, SOCKET_DEBUG*/ } from '../../Configuration';

// import { 
//   emit as emitWS,
//   listen as listenWS,
//   stopListen as stopListenWS,
//   insideRoom as insideRoomWS,
// } from '../../sockets'

import { compose } from 'recompose';
import { connect } from 'react-redux';

import dataFetch from '../../providers/dataFetch';

import FilesShowContent from './FilesShowContent';
import Status from './Status';
// import { refreshView } from 'ra-core';

export { default as FilesIcon } from '@material-ui/icons/InsertDriveFile';

const listStyles = {};

//Assumes all files to download are CSV
const DownloadFileButton = ({ record, ...props }) => (
  <DownloadButton filePath={`${URL}${record.filePath}`} fileName={`${record.name}.csv`} label='Download' />
)

const ContentButton = ({ record, ...props }) => {
  if (record && record.file && record.file.split('.').pop() === 'csv') {
    return <ShowButton record={record} {...props} />
  } else {
    return null
  }
}


const sanitizeRestProps = ({
  // emit,
  // listen,
  // stopListen,
  // showNotification,
  insideRoom,
  // refreshView,
  ...props
}) => props;

const FilesFilter = translate(({ translate, ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn label={translate('pos.search')} />
    <SelectInput source="status" choices={[
    { id: 'SUCCESS', name: translate('resources.files.filter.success') },
    { id: 'DUPLICATED', name: translate('resources.files.filter.duplicated') },
    { id: 'PENDING', name: translate('resources.files.filter.pending') },
    { id: 'ERROR', name: translate('resources.files.filter.error1') },
    { id: 'CSV_DONT_MATCH_COLUMNS_LENGTH', name: translate('resources.files.filter.error2') },
    { id: 'DATE_ISSUE', name: translate('resources.files.filter.error3') },
]}/>
  </Filter>
));

class _FilesList extends Component {
  // componentDidMount() {
  //   this.props.emit('enter', {room : 'file'})
  //   .catch(err => {
  //     if(SOCKET_DEBUG)
  //       console.log("err FILE", err);
  //   });

  //   this.props.listen('file', (data) => {
  //     const { refresh, file } = data;
  //     if(refresh && this.props.ids.includes(file.id)) {
  //       // this.props.showNotification('sockets.update_data');
  //       this.props.refreshView();
  //     }
  //   })
  //   .catch(err => {
  //     if(SOCKET_DEBUG)
  //       console.log("err FILE", err);
  //   });
  // }

  // componentWillUnmount() {
  //   this.props.stopListen('file')
  //   .catch(err => {
  //     if(SOCKET_DEBUG)
  //       console.log("err FILE", err);
  //   });

  //   this.props.emit('leave', {room : 'file'})
  //   .catch(err => {
  //     if(SOCKET_DEBUG)
  //       console.log("err FILE", err);
  //   });
  // }

  render() {
    return (
      <ResponsiveList
        {...sanitizeRestProps(this.props)}
        listFilters={<FilesFilter />}
        listActions={<DefaultCardActions createLabel={'resources.files.fields.uploadFile'} />}
        recordActions={[<DownloadFileButton />, <ContentButton />]}
      >
        <TextField source="name" />
        <TextField source="originalFilename" />
        <TextField source="identifier" />
        {/* <DateField source="createdAt" /> */}
        <DateField source="createdAt" format='dd/MM/yyyy' noFields />
        <Status source="status" />
      </ResponsiveList>
    );
  }
}

const mapStateToProps = (state) => ({
  ids: state.admin.resources.files.list.ids
});

const mapDispatchToProps = dispatch => {
  return ({
  // listen: (...data) => dispatch(listenWS(...data)),
  // stopListen: (...data) => dispatch(stopListenWS(...data)),
  // emit: (...data) => dispatch(emitWS(...data)),
  // insideRoom: (...data) => dispatch(insideRoomWS(...data)),
  // showNotification: (...data) => dispatch(showNotification(...data)),
  // refreshView: (...data) => dispatch(refreshView(...data)),
})};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps)
);

export const FilesList = enhance(_FilesList);

const submit = (history) => (data) => {
  const headers = new Headers({
    Accept: 'application/json',
  });

  const formData = new FormData()
  formData.append('name', data.name)
  formData.append('identifier', data.identifier)
  formData.append('file', data.file.rawFile)
  dataFetch('POST', '/files/upload/csv', headers, formData)
    .then(_ => {
      history.goBack()
    });
};


class FileSimpleForm extends Component {
  render() {
    const { save, history, ...props } = this.props

    return <SimpleForm save={submit(history)} redirect='list' {...props}>
      <FileInput source='file' validate={required()}>
        <FileField source='file' title='fileName' />
      </FileInput>
      <TextInput source='name' validate={required()} />
      <TextInput source='identifier' validate={required()} />
    </SimpleForm>
  }
};


export const FileCreate = withStyles(listStyles)(({ classes, ...props }) => (
  <Create actions={<DefaultCardActions />}{...props}>
    <FileSimpleForm history={props.history} />
  </Create>
));


// export class FilesShow extends Component {

//   componentDidMount( ) {
//     console.log("FILES MOUNTED")
//   }

//   render(){
//     return (
//       <Show {...this.props}>
//         <SimpleShowLayout>
//           <Responsive
//             small={<FilesShowContent perPage={10}/>}
//             medium={<FilesShowContent perPage={25}/>}
//           />
//         </SimpleShowLayout>
//       </Show>
//     )}
// }

// export const FilesShow  = compose(withStyles(listStyles))(_FilesShow);

export const FilesShow = withStyles(listStyles)(({ ...props }) => (
  <Show {...props}>
    <SimpleShowLayout>
      <Responsive
        small={<FilesShowContent perPage={10} />}
        medium={<FilesShowContent perPage={25} />}
      />
    </SimpleShowLayout>
  </Show>
));