import React, { Component } from "react"
import memoize from 'memoize-one'

import {
  TextField
} from "@material-ui/core"

// TODO: filter by all => string = "q"
// arrayIndex => (optional) and just work with obj

/*
    label, => (optional)
    Parent: this,
    indexArray: string, => name of array on state (contains ids of obj)
    obj: string, => name of obj on state (contains all data)
    filter: string => name of param inside obj to filter 
*/
class LocalSearch extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = {
            originalIndexArray: [],
        }
    }

    filterIdsByFilter = memoize( (allIds, word, allData, filter) => {
        if(!word) return allIds;

        const filteredIds = allIds.reduce((acc, id) => {
            if(allData[id][filter] && allData[id][filter].toUpperCase().search(word.toUpperCase()) > -1) {
                acc.push(id);
            }
            return acc;
        }, [])
        return filteredIds;
    });
      
    // componentDidMount() {
    //     const { Parent, indexArray } = this.props;
    //     console.log("DIDMOUNT", Parent.state, indexArray)
    //     this.setState({ originalIndexArray: Parent.state[indexArray] })
    // }

    componentDidUpdate(oldProps) {
        const { Parent, indexArray, obj } = this.props;
        const { originalIndexArray } = this.state;
        
        if(indexArray && obj) {
            if(Parent.state[indexArray].length > 0 && originalIndexArray.length === 0) {
                const tmp = [...Parent.state[indexArray]];
                this.setState({ originalIndexArray: tmp });
            }
        }
    }

    onChange(event) {
        const { originalIndexArray } = this.state;
        const { Parent, filter, indexArray, obj} = this.props;
        const search = event.target.value;

        if(indexArray && obj) {
            const news = this.filterIdsByFilter([...originalIndexArray], search, Parent.state[obj], filter);
            Parent.setState({ids: news});
        }
    }

    render(){
        const { label } = this.props;
        return <TextField type="text" placeholder = {label ? label : ""} onChange = {this.onChange}/>
    }
}

export default LocalSearch;
