import React, { Component, Fragment } from 'react';
import { Typography } from '@material-ui/core';
import {
  Pagination,
  TextField,
  fetchStart,
  fetchEnd,
  translate
} from 'react-admin';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
  ResponsiveDatagrid,
} from '../../components';

import { dataFetch } from '../../providers';

class FilesShowContent extends Component {

  state={
    data: {},
    ids: [],
    fields: [],
    page: 1,
    perPage: this.props.perPage
  }

  componentDidMount(){
    const { record } = this.props;
    fetchStart();
    dataFetch('GET', `/files/${record.id}/json`)
      .then(resp => {
        let fields = [];
        if (resp.length > 0)
          fields = Object.keys(resp[0]);
        
        const data = resp.reduce((acc,row,index)=>{
          acc[index] = Object.assign({},row,{id:index});
          return acc;
        }, {});

        this.setState({data, ids: Object.keys(data), fields});
      })
      .finally(_ => {
        fetchEnd();
      });
  }

  render(){
    const {data, ids, fields, page, perPage} = this.state;
    const {record, resource, translate} = this.props;
    return <Fragment>
      {record && <Typography variant='title'>{`${translate(`resources.${resource}.name`,{smart_count:1})} ${record.name}`}</Typography>}
      <ResponsiveDatagrid currentSort={{field:'id',order:'ASC'}} data={data} ids={ids.slice((page-1)*perPage,page*perPage)} resource={resource} translate={translate}>
        {
          fields.length > 0 && fields.map((property, index) => {
            return <TextField key={index} source={property} sortable={false} />
          })
        }
      </ResponsiveDatagrid>
      <Pagination
        page={page}
        perPage={perPage}
        setPage={ page => this.setState({page})}
        setPerPage={ perPage => {this.setState({perPage})}}
        total={ids.length}
       />
    </Fragment>
  }
}

const enhance = compose(
  translate,
  connect(null, {
    fetchEnd,
    fetchStart
  })
);

export default enhance(FilesShowContent);