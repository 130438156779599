import React, { Component, Fragment } from 'react';
import {
  Create,
  SimpleForm,
  DateField,
  SimpleShowLayout,
  Show,
  NumberInput,
  NumberField,
  required,
  crudGetOne,
  BooleanInput,
  BooleanField,
  SelectInput,
  FormDataConsumer,
  TextField,
  FileInput,
  FileField,
  translate,
  showNotification
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { parse } from 'query-string';

import { DateTimeInput } from '../../components';
import dataFetch from '../../providers/dataFetch';


const redirect = (basePath, id, data) => {
  return `/equipment/${data.equipmentId}/show/calibrations`;
}

const CalibationsActions = ({ equipment, title, ...props }) => {
  return <Fragment>
    <Typography variant='title'>{title}</Typography>
  </Fragment>
}

const choices = [
  { id: 'temp_1', name: "resources.equipment.calibrations.sensor_1" },
  { id: 'temp_2', name: "resources.equipment.calibrations.sensor_2" },
];
const choices2 = [
  { id: 2, name: "resources.equipment.calibrations.polynomialDegree1" },
  { id: 4, name: "resources.equipment.calibrations.polynomialDegree2" },
];

const submit = (history, equipment, showNotification) => ({ file, ...data }) => {
  const headers = new Headers({
    Accept: 'application/json',
    "Content-Type": "application/json"
  });

  data.equipmentId = equipment.id
  
  dataFetch('POST', '/calibrations', headers, JSON.stringify(data))
    .then((result) => {

      if (file) {
        const formData = new FormData();
        formData.append('file', file.rawFile);

        const _headers = new Headers({
          Accept: 'application/json',
        });

        return dataFetch('POST', `/calibrations/${result.id}/upload`, _headers, formData);
      }
      return
    })
    .then(_ => {
      history.goBack();
    })
    .catch(_ => {
      showNotification('errors.SOMETHING');
    });
};


class FileSimpleForm extends Component {
  render() {
    const { history, equipment, showNotification } = this.props

    return <SimpleForm save={submit(history, equipment, showNotification)} redirect={redirect}>
      <DateTimeInput
        validate={required()}
        source='calibrationDate'
        label='resources.equipment.calibrations.calibrationDate'
        month={true}
        options={{
          format: 'dd/MM/yyyy HH:mm',
          clearable: true,
          selectTimezone: true
        }}
      />
      <BooleanInput label="resources.equipment.calibrations.diffValidationDate" source="diffValidationDate" />
      <FormDataConsumer>
        {({ formData, ...rest }) => formData && (formData.diffValidationDate) &&
          <DateTimeInput
            validate={required()}
            source='validDate'
            label='resources.equipment.calibrations.validDate'
            options={{
              format: 'dd/MM/yyyy HH:mm',
              clearable: true,
              selectTimezone: true
            }}
          />
        }
      </FormDataConsumer>
      <BooleanInput label="resources.equipment.calibrations.onEquipment" source="onEquipment" />
      <SelectInput validate={required()} label="resources.equipment.calibrations.sensorType" source='sensorType' choices={choices} />
      <SelectInput validate={required()} label="resources.equipment.calibrations.numVariables" source='numVariables' choices={choices2} />
      <FormDataConsumer>
        {({ formData, ...rest }) => formData && (formData.numVariables === 2) &&
          <><p>y(°C) = a0 + a1&#215;k </p>
            <p> k = {this.props.translate("resources.equipment.calibrations.formula")} [°C]</p></>
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData && (formData.numVariables === 4) &&
          <><p>y(°C) = a0 + a1&#215;k + a2&#215;k&#xB2; + a3&#215;k&#xB3; </p>
            <p> k = {this.props.translate("resources.equipment.calibrations.formula")} [°C]</p></>
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData && (formData.numVariables === 2 || formData.numVariables === 4) &&
          <NumberInput validate={required()} label='a0' source='a0' {...rest} />
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData && (formData.numVariables === 2 || formData.numVariables === 4) &&
          <NumberInput validate={required()} label='a1' source='a1' {...rest} />
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData && (formData.numVariables === 4) &&
          <NumberInput validate={required()} label='a2' source='a2' {...rest} />
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData && (formData.numVariables === 4) &&
          <NumberInput validate={required()} label='a3' source='a3' {...rest} />
        }
      </FormDataConsumer>
      <FileInput accept="application/pdf" source='file'>
        <FileField source='file' title='fileName' />
      </FileInput>
    </SimpleForm>
  }
};

class CalibrationsCreateView extends Component {
  componentDidMount() {
    if (!this.props.equipment) {
      const search = parse(this.props.location.search, { arrayFormat: 'bracket' })

      this.props.crudGetOne('equipment', search.equipmentId, '/equipment');
    }
  }

  render() {
    const { equipment, crudGetOne, history, translate, showNotification, ...props } = this.props;
    return <Create actions={<CalibationsActions title={equipment && equipment.name} />} {...props}>
      <FileSimpleForm history={history} translate={translate} equipment={equipment} showNotification={showNotification}/>
    </Create>
  }
}

class CalibrationsShowView extends Component {

  render() {
    const { crudGetOne, ...props } = this.props;
    return (
      <Show filter={{ include: ['equipment'] }} {...props} >
        <SimpleShowLayout filter={{ include: ['equipment'] }}>
          <DateField label="resources.equipment.calibrations.calibrationDate" source="calibrationDate" format='dd/MM/yyyy HH:mm:ss' />{/*TODO resources.equipment.tabs.calibration */}
          <DateField label="resources.equipment.calibrations.validDate" source="validDate" format='dd/MM/yyyy HH:mm:ss' />{/*TODO resources.equipment.tabs.calibration */}
          <BooleanField label="resources.equipment.calibrations.onEquipment" source="onEquipment" />
          <TextField label="resources.equipment.calibrations.sensorType" source='sensorType' />
          <NumberField label="resources.equipment.calibrations.numVariables" source='numVariables' />
          <NumberField source='a0' />
          <NumberField source='a1' />
          <NumberField source='a2' />
          <NumberField source='a3' />
        </SimpleShowLayout>
      </Show >
    )
  }
}

const mapStateToProps = (state, props) => {
  const search = parse(props.location.search, { arrayFormat: 'bracket' })
  return {
    equipment: state.admin.resources.equipment.data[search.equipmentId]
  }
}

const enhance = compose(
  translate,
  connect(mapStateToProps, {
    crudGetOne,
    showNotification,
  })
)

export const CalibrationsCreate = enhance(CalibrationsCreateView);
export const CalibrationsShow = enhance(CalibrationsShowView);