import React from 'react'
import { translate } from 'react-admin'
import LinkButton from './LinkButton'

const LinkButtonToResource = ({ record, basePath, translate, field, reference, to, hasIcon }) => (
  record[field] > 0 ?
    <LinkButton 
      to={to || `${basePath}/${record.id}/show`}
      label={translate(`resources.${reference}.name`, {smart_count: record[field] || 0})}
      hasIcon={hasIcon}
    />
  :null
)

export default translate(LinkButtonToResource)