import React from 'react';
import Typography from '@material-ui/core/Typography';

import Decimal from 'decimal.js';
Decimal.set({ toExpNeg: -3 })

export default ({
  className,
  source,
  record = {},
}) => {

  if(!record || !record[source])
    return null;

  const value = new Decimal(record[source]);

  return (
    <Typography
      component="span"
      variant="body1"
      className={className}
    >
      {value.toString()}
    </Typography>
  )
} 