import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, createStyles } from '@material-ui/core/styles';

const styles = theme =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'flex-start',
    },
    active: {
      color: theme.palette.text.primary,
    },
    icon: { paddingRight: '1.2em' },
  });

export class MenuItemExternalLink extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    leftIcon: PropTypes.node,
    onClick: PropTypes.func,
    primaryText: PropTypes.node,
    staticContext: PropTypes.object,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
  };

  handleMenuTap = e => {
    window.location.href = this.props.to;
  };

  render() {
    const {
      classes,
      className,
      primaryText,
      leftIcon,
      staticContext,
      to,
      ...props
    } = this.props;

    return (
      <MenuItem
        className={classnames(classes.root, className)}
        {...props}
        onClick={this.handleMenuTap}
    >
        {leftIcon && (
          <span className={classes.icon}>
            {cloneElement(leftIcon, { titleAccess: primaryText })}
          </span>
        )}
        {primaryText}
      </MenuItem>
    );
  }
}

export default withStyles(styles)(MenuItemExternalLink);