import React, { Component } from 'react';
import { Button, withStyles } from '@material-ui/core';
import { NoteAdd as NoteAddIcon } from '@material-ui/icons';
import { Control } from 'react-leaflet-control-inesc';
import { 
  StageOne,
  StageTwo,
  StageThree
} from './map'

import Details from './Details';
import {
  showNotification
} from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

const styles = {
  button: {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    width: '30px',
    height: '30px',
  },
  checkBox: {
    margin: '10px',
    background: "green"
  }
}

class AnotationControl extends Component {

  constructor(props) {
    super(props);

    this.state = {
      active: false,
      stage: 1,
      beginTime: undefined,
      endTime: undefined,
      modal_open: false
    }

    this.nextStage = this.nextStage.bind(this);
    this.preStage = this.preStage.bind(this);
  }

  onClose = () => {
    this.setState({ modal_open: false, stage: 3 });
  }

  nextStage = () => {
    let { stage } = this.state;

    if (this.props.map.time === 0 && (stage === 1 || stage === 3)) { 
      this.props.showNotification('pos.annotation.loading');
      return;
    }
    if (stage === 1)
      this.setState({ beginTime: this.props.map.time });
    else if (stage === 2)
      this.setState({ endTime: this.props.map.time });
    else
      this.setState({ modal_open: true });

    this.setState({ stage: stage + 1 });
  }

  preStage = () => {
    this.setState({ stage: this.state.stage - 1 })
  }

  success = () => {
    this.setState({ stage: 1, beginTime: undefined, endTime: undefined, active: false });
    this.props.refreshData();
  }

  render() {
    const { classes, recordId, refreshData, showNotification, ...props } = this.props
    const { active, stage, modal_open } = this.state;
    return (
      <Control position="topleft" className="leaflet-control-zoom leaflet-bar leaflet-control">
        <div style={{ background: 'white' }}>
          <Button
            {...props}
            classes={{ root: classes.button }}
            onClick={() => { this.setState({ active: !active }) }}
          >
            <NoteAddIcon style={{ fontSize: '15px', padding: '8px', color: 'black' }} />
          </Button>

          {active &&
            (
              (
                stage === 1 &&
                <StageOne map={this.props.map} nextstage={this.nextStage} />
              ) ||
              (
                stage === 2 &&
                <StageTwo map={this.props.map} nextstage={this.nextStage} prestage={this.preStage} beginTime={this.state.beginTime} />
              ) ||
              (
                stage >= 3 &&
                <StageThree map={this.props.map} nextstage={this.nextStage} prestage={this.preStage} beginTime={this.state.beginTime} endTime={this.state.endTime} />
              )
            )
          }
          {
            active &&
            stage === 4 &&
            <Details open={modal_open} onClose={this.onClose} beginTime={this.state.beginTime} endTime={this.state.endTime} recordId={recordId} success={this.success}/>
          }

        </div>
      </Control>
    );
  }
}

const enhance = compose(
  withStyles(styles),
  connect(null, {
    showNotification
  })
);

export default enhance(AnotationControl);