import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
// import inflection from 'inflection';
import { translate/*, crudDelete*/ } from 'ra-core';

// import Confirm from '../layout/Confirm';
import { Button, Confirm, refreshView, showNotification } from 'react-admin';
import dataFetch from '../../providers/dataFetch';
import { push } from 'react-router-redux';

const sanitizeRestProps = ({
    basePath,
    classes,
    crudDelete,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    label,
    pristine,
    resource,
    saving,
    selectedIds,
    submitOnEnter,
    redirect,
    refreshView,
    showNotification,
    push,
    ...rest
}) => rest;

const styles = theme =>
    createStyles({
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    });

class DeleteContentButton extends Component {
    state = { isOpen: false };

    handleClick = e => {
        this.setState({ isOpen: true });
        e.stopPropagation();
    };

    handleDialogClose = () => {
        this.setState({ isOpen: false });
    };

    handleAction = () => {
      dataFetch('POST', `/derivedsets/deleteObservationsByDerivedsetId/${this.props.derived_id}`)
      .then(_ => {
        this.props.showNotification("resources.derivedsets.forms.buttons.delete_observations_confirm_notification");
        this.props.push(`/derivedsets/${this.props.derived_id}/show`)
      })
      .catch(_=> console.log("ERRO"))

    };
 /*
    confirmaction => function to action on confirmation
    derivedsetName => name derivedSet
 */
    render() {
        const {
            classes = {},
            className,
            icon,
            label,
            c_title,
            c_content,
            onClick,
            record,
            resource,
            translate,
            // derivedsetName,
            ...rest
        } = this.props;
        return (
            <Fragment>
                <Button
                    onClick={this.handleClick}
                    label={label ? label : translate('resources.derivedsets.forms.buttons.delete_observations')}
                    className={classnames(
                        'ra-delete-button',
                        classes.deleteButton,
                        className
                    )}
                    key="button"
                    {...sanitizeRestProps(rest)}
                >
                    {icon}
                </Button>
                <Confirm
                    isOpen={this.state.isOpen}
                    title={c_title ? c_title : translate('resources.derivedsets.forms.buttons.delete_observations_confirm_title')}
                    content={c_content ? c_content : translate("resources.derivedsets.forms.buttons.delete_observations_confirm_description"/*, {name: derivedsetName}*/)}
                    onConfirm={this.handleAction}
                    onClose={this.handleDialogClose}
                />
            </Fragment>
        );
    }
}

DeleteContentButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    derived_id: PropTypes.string.isRequired,
    translate: PropTypes.func,
    icon: PropTypes.element,
};

DeleteContentButton.defaultProps = {
    redirect: 'list',
    icon: <ActionDelete />,
};

export default compose(
  connect(null, {
    refreshView,
    showNotification,
    push   
  }),
  translate,
  withStyles(styles)
)(DeleteContentButton);