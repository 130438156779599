import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset as resetFormAction } from 'redux-form';
import compose from 'recompose/compose';
import {
  LongTextInput,
  fetchEnd,
  fetchStart,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  SimpleForm,
  showNotification,
  translate,
  required
} from 'react-admin';
import {
  Dialog, IconButton,
  DialogTitle,
  DialogContent,
  withStyles
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons'
import PropTypes from 'prop-types';
import { dataFetch } from '../../providers';

import CampaignSelection from './CustomSelectInput';
import { DateTimeInput } from '..';
const styles = {
  content: { padding: 0 },
  titleDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeButton: {
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  TextField: {
    // maxWidth: "80%",
    // display: 'flex',
    // margin: '0 auto'
  }
};

class Details extends Component {

  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      valueDefault: 1
    };
  }

  handleSave = (record) => {
    const { onClose, showNotification, success } = this.props
    const headers = new Headers({
      Accept: 'application/json',
      "Content-Type": "application/json"
    });

    const { beginTime, campaign, description, tagIds } = record;

    const filter = {
      beginTime: beginTime, 
      campaignId: campaign,
      description: description,
      tagIds: tagIds
    }

    const method = 'POST', url = '/notes';
    
    dataFetch(method, url, headers, JSON.stringify(filter))
      .then(_ => {
        showNotification('resources.notes.success');
        this.props.reset('notes');
        onClose();
        if(success)
          success();
      })
      .catch(err => showNotification('resources.notes.campaignGetError', 'warning'));
  }

  componentDidMount() {
    this.props.reset('notes');
    
    const {campaign} = this.props;

    if(campaign) {
      this.setState({campaigns: [campaign], valueDefault: campaign.id});
    }
    else {
      const filter = {
        include: {
          relation: 'launches',
          scope: {
            where: {
              endTimestamp: null
            }
          }
        },
        order:['beginDate DESC']
      };
      
      dataFetch("GET", `/campaigns?filter=${JSON.stringify(filter)}`)
        .then(result => {
          let valueDefault;
          result = result.filter(({launches}) => launches.length > 0);
          if(result.length > 0)
            valueDefault = result[0].id;

          this.setState({campaigns: result, valueDefault: valueDefault});
        })
        .catch(err => showNotification());
      }
  }

  render() {
    const { translate, onClose, open, classes, description, tagIds, campaign } = this.props
    return (<Dialog open={open} fullWidth maxWidth='md' onClose={onClose} >
      <span className={classes.titleDiv}>
        <DialogTitle >
          {translate('resources.notes.title')}
        </DialogTitle>

        <IconButton classes={{ root: classes.closeButton }} onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </span>
      <DialogContent onClick={this.handleCLick}>
        <SimpleForm form='notes' save={this.handleSave} resource={'notes'}>

          <CampaignSelection 
            disabled={campaign ? true : false}
            label={translate('resources.notes.fields.campaign')}
            validate={required()}
            choices={this.state.campaigns} 
            optionText="name" 
            source='campaign' 
            defaultValue={this.state.valueDefault}
          />

          <DateTimeInput
            validate={required()}
            source='beginTime'
            label={translate('resources.notes.fields.beginDate')}
            options={{
              format: 'dd/MM/YYYY HH:mm',
              clearable: true
            }}
            dateNow
          />

          <LongTextInput multiline rows={5} label={translate('resources.notes.fields.description')} source='description' defaultValue={description || ""}/>
          
          <ReferenceArrayInput label={translate('resources.notes.fields.tagIds')} reference='tags' source='tagIds'>
            <Selection selected={tagIds}/>
          </ReferenceArrayInput>
        
        </SimpleForm>
      </DialogContent>
    </Dialog>
    )
  }
}
const Selection = ({ selected, basePath, tagIds, ...props }) => {
  props.input.value = props.input.value === "" ? selected : props.input.value;
  
  return <SelectArrayInput {...props}>
    <ChipField source='name' />
  </SelectArrayInput>;
}
Details.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

const enhance = compose(
  translate,
  withStyles(styles),
  connect(null, {
    fetchStart,
    fetchEnd,
    showNotification,
    reset: resetFormAction,

  })
);

export default enhance(Details);