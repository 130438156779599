import React, { Component } from 'react';
import {
  Toolbar,
  withStyles
} from '@material-ui/core';
import { stringify } from 'query-string';
import { Add as AddIcon } from '@material-ui/icons';
import {
  SelectInput as Select,
} from 'react-admin';
import { LinkButton } from '../../components';

import SimpleForm from './Form/SimpleForm';

const listStyles = function styles(theme) {
  return {
    select: {
      maxWidth: '1',
    }
  }
}

class CalibrationTollbar extends Component {
  render() {
    const { handleChange, classes } = this.props;
    return (
      <Toolbar>
        <LinkButton variant='raised' label='resources.launches.buttons.create'
          to={{
            pathname: '/calibrations/create',
            search: stringify({
              equipmentId: this.props.record.id
            })
          }}
          hasIcon
          icon={<AddIcon />}
        />
        <SimpleForm
          toolbar={null}
        >
          <Select
            source='filter'
            defaultValue={0}
            className={classes.select}
            onChange={(event, key, ...args) => {
              event.preventDefault();
              handleChange("filter", key)
            }}
            allowEmpty={false}
            label='resources.equipment.calibrations.filter'
            choices={[
              { id: 0, name: 'resources.equipment.calibrations.none' },
              { id: 1, name: 'resources.equipment.calibrations.sensor_1' },
              { id: 2, name: 'resources.equipment.calibrations.sensor_2' }
            ]}
          />
        </SimpleForm>
      </Toolbar >
    )
  }
}

export default withStyles(listStyles)(CalibrationTollbar)