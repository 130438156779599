import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';

const { Consumer, Provider } = React.createContext();
export const MuiPickersContextConsumer = Consumer;

export default class MuiPickersUtilsProvider extends Component {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    utils: PropTypes.func.isRequired,
    locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.oneOfType([
      PropTypes.element.isRequired,
      PropTypes.arrayOf(PropTypes.element.isRequired),
    ]).isRequired,
    moment: PropTypes.func,
    timeZone: PropTypes.string,
    ampm: PropTypes.bool,
    seconds: PropTypes.bool,
    utc: PropTypes.bool,
    selectTimezone: PropTypes.bool,
  }

  static defaultProps = {
    locale: undefined,
    moment: undefined,
    ampm: true,
    seconds: false,
    selectTimezone: false
  }

  state = {
    utils: null,
    utc: true,
  }

  changeUtcState = (utc) =>{
    this.setState({utc, isUTC: utc});
  }

  static getDerivedStateFromProps({ utils: Utils, locale, moment, timeZone, dateFormat, dateTimeFormat, timeFormat, ampm, seconds, children, selectTimezone }) {
    return {
      utils: new Utils({ 
        locale, 
        moment, 
        timeZone, 
        dateFormat, 
        dateTimeFormat, 
        timeFormat, 
        ampm, 
        seconds: children.props.format.includes('ss'),//children.props.seconds ? children.props.seconds : seconds ? seconds : false
        selectTimezone: children.props.selectTimezone ? children.props.selectTimezone : selectTimezone ? selectTimezone : false
      }),
    };
  }

  render() {
    const { ampm, selectTimezone, children } = this.props;
    return <Provider value={this.state.utils} >{cloneElement(this.props.children, { ampm, seconds: children.props.format.includes('ss'), changeUtcState: this.changeUtcState, utc: this.state.utc, selectTimezone: children.props.selectTimezone ? children.props.selectTimezone : selectTimezone ? selectTimezone : false })}</Provider>;
  }
}
