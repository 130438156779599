import React from 'react';
import { Labeled, NumberInput, translate } from 'react-admin';

const validateLatitude = (value, _, {translate}) => {
  const floatValue = parseFloat(value)
  return floatValue >= -90 && floatValue <= 90 ? undefined : translate('inputs.error.invalid_latitude')
}

const validateLongitude = (value, _, {translate}) => {
  const floatValue = parseFloat(value)
  return floatValue >= -180 && floatValue <= 180 ? undefined : translate('inputs.error.invalid_longitude')
}

export default translate(({source, className, fieldClassName, translate, hasLabel = true, ...props}) => {
  return hasLabel ? 
    <Labeled source={source} {...props} className={className}>
      <span>
        <NumberInput className={fieldClassName} source={`${source}.lat`} label={translate('inputs.coordinates.latitude')} validate={validateLatitude} />
          &nbsp;
        <NumberInput className={fieldClassName}source={`${source}.lng`} label={translate('inputs.coordinates.longitude')}  validate={validateLongitude} />
      </span>
    </Labeled>
    :<span className={className}>
      <NumberInput className={fieldClassName} source={`${source}.lat`} label={translate('inputs.coordinates.latitude')} validate={validateLatitude} />
        &nbsp;
      <NumberInput className={fieldClassName} source={`${source}.lng`} label={translate('inputs.coordinates.longitude')}  validate={validateLongitude} />
    </span>
});