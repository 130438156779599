import React, { Component } from 'react';

class InputAggregator extends Component {

  render(){
    const { source, ...props } = this.props;
    return <div>
      {
        React.Children.map(this.props.children, (child, i) => {
          return React.cloneElement(child, {source:`${source}.${child.props.source}`, key: i, ...props})
        })
      }
    </div>
  }

}

export default InputAggregator;