import React, { Component } from 'react';
import { Button, translate } from 'react-admin';
import DerivedObservationIcon from '@material-ui/icons/AlarmAdd';

import AddDerivedObservationDialog from './AddDerivedObservationDialog';

class AddDerivedObservationButton extends Component{
  
  state = {
    open: false
  }

  handleClose = () => this.setState({open: false})

  handleOpen = () => this.setState({open: true})

  render(){
    const {selectedIds, translate} = this.props
    const title = translate('pos.general.add_referencing', { reference: translate('pos.derived.to_dts', {smart_count:2} ) } )
    return <div>
      <Button
        label={title} 
        onClick={this.handleOpen}
      ><DerivedObservationIcon/></Button>
      <AddDerivedObservationDialog open={this.state.open} searchLabel={translate('pos.derived.searchLabel')} datasetId={this.props.filterValues.datasetId} onClose={this.handleClose} title={title} selectedObsIds={selectedIds} all_data={this.props.all_data}/>
    </div>
  }

}

export default translate(AddDerivedObservationButton);