import React from 'react';

import { 
  SimpleShowLayout,
  TextField,
  translate
} from 'react-admin';

const ProfileShow = ({
  value = {},
  translate
}) => (
  <SimpleShowLayout record={value}>
    <TextField label={translate('resources.users.fields.username')} source="username" />
    <TextField label={translate('resources.users.fields.name')} source="name" />
    <TextField label={translate('resources.users.fields.email')} source="email" />
  </SimpleShowLayout>
);

export default translate(ProfileShow);