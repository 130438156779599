import React, {Component} from 'react';
import {translate} from 'react-admin';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove'
import {
  Button
} from 'react-admin';

class TableFormIterator extends Component{

  renderFirstRow = () => {
    const { resource, translate } = this.props;

    const firstRow = this.props.firstRow.map((frCell, index) => {
      return <TableCell key={`frc-${index}`}>
        {React.cloneElement(frCell, {record: this.props.fields.get(0)})}
      </TableCell>
    })
    firstRow.push(<TableCell key={`frc-${firstRow.length}`}>{translate(`resources.${resource}.warning.removeProperty`)}</TableCell>)
    
    return firstRow; 
  }

  renderContent = (propertyLoc, index) => {
    const contentRow = this.props.children.map((child, index) => {
      const { resource, source, translate } = this.props;
      return <TableCell key={`tc-${index}`}>
        {React.cloneElement(child, {source:`${propertyLoc}.${child.props.source}`, label: child.props.label !== undefined ? child.props.label: translate(`resources.${resource}.fields.${source}.${child.props.source}`)})}
      </TableCell>
    })

    contentRow.push(<TableCell key={`tc-${contentRow.length}`}><Button label='ra.action.remove' onClick={() => this.props.onRowRemove(index)}><RemoveIcon/></Button></TableCell>)

    return contentRow;
  }

  render(){
    const {children, fields, resource, source, translate} = this.props;

    const data = fields.getAll();

    return <Table>
      <TableHead>
        <TableRow>
          {React.Children.map(children, (column, index) => {
            return <TableCell key={`th-${index}`}>{translate(`resources.${resource}.fields.${source}.${column.props.source}`, {_:column.props.source})}</TableCell>
          })}
          <TableCell>{translate('ra.action.remove')}</TableCell>
        </TableRow>
      </TableHead>
      {data && <TableBody>
        {
          fields.map((propertyLoc, index) => {
            return <TableRow key={`tr-${index}`}>
              {
                index === 0 ? this.renderFirstRow() : this.renderContent(propertyLoc, index)
              }
            </TableRow>  
          })
        }
      </TableBody>}
    </Table>

  }

}

export default translate(TableFormIterator);