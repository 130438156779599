import React from 'react';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import { Toolbar } from './';
import { ShowTime } from '../';


export default compose(translate)(({translate, beginTime, endTime, ...props}) => {


  return (
    <div style={{ marginTop: "5%", paddingLeft: '10px', paddingRight: '10px' }}>
        <div> {ShowTime(translate('containers.annotations.begin'), beginTime)} </div>
        <div> {ShowTime(translate('containers.annotations.end'), endTime)} </div>
        <Toolbar {...props}/>
      </div>
  );
});