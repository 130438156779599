import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  LongTextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  SimpleForm,
  translate,
  crudCreate as crudCreateAction,
  refreshView as refreshViewAction,
  setListSelectedIds as setListSelectedIdsAction,
  showNotification as showNotificationAction
} from 'react-admin';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import compose from 'recompose/compose';
import { reset as resetFormAction } from 'redux-form';

import { dataFetch } from '../../providers';

const styles = {
  titleDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeButton: {
    marginTop: '0.5em',
    marginRight: '0.5em'
  }
}

class CreateAnnotationsDialog extends Component {

  handleSave = (record) => {
    const { observationIds, onClose, refreshView, setListSelectedIds, showNotification, allData, parentrecord, insertedAnnotations } = this.props;
    let time_record = [];
    observationIds.forEach(obs => {
      const result = {
        beginTime: allData[obs].timestamp,
        record: allData[obs].record
      };

      time_record.push(result);
    });
    const headers = new Headers({
      Accept: 'application/json',
      "Content-Type": "application/json"
    });

    const body = {
      intervals: time_record,
      derivedsetId: parentrecord.id,
      text: record.description,
      tags: record.tagIds
    };

    dataFetch('POST', `/annotations`, headers, JSON.stringify(body))
      .then(result => {
        showNotification('ra.notification.created', 'info', { smart_count: observationIds.length });
        setListSelectedIds('observations', []);
        onClose()
        refreshView();
        insertedAnnotations();
      })
      .catch(err => showNotification('ra.notification.http_error', 'warning'));
  }

  componentDidMount() {
    this.props.resetForm('record-form');
  }

  render() {
    const { classes, fullWidth, edit = true, observationIds, onClose, open, record, translate } = this.props;
    return <Dialog open={open} onClose={onClose} fullWidth={fullWidth}>
      <span className={classes.titleDiv}>
        <DialogTitle >
          {edit ?
            translate('ra.page.edit', { name: translate('resources.annotations.name', { smart_count: 1 }), id: record.id })
            : translate('ra.page.create', { name: translate('resources.annotations.name', { smart_count: observationIds.length }) })}
        </DialogTitle>
        <IconButton classes={{ root: classes.closeButton }} onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </span>
      <DialogContent onClick={this.handleCLick}>
        <SimpleForm save={this.handleSave} record={edit ? record : { observationIds }} resource={'annotations'}>
          <LongTextInput source='description' />
          <ReferenceArrayInput reference='tags' source='tagIds' basePath='/tags' >
            <Selection />
          </ReferenceArrayInput>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  }

}

const Selection = ({ selected, ...props }) => {
  //Overrides props.input.value so it has initial value. If nothing is passed it assumes that record has no related fields
  props.input.value = props.input.value === "" ? selected : props.input.value;
  return <SelectArrayInput {...props}>
    <ChipField source='name' />
  </SelectArrayInput>;
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(
    null,
    {
      crudCreate: crudCreateAction,
      refreshView: refreshViewAction,
      setListSelectedIds: setListSelectedIdsAction,
      showNotification: showNotificationAction,
      resetForm: resetFormAction
    }
  )
);

export default enhance(CreateAnnotationsDialog);