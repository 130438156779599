import React, {Component} from 'react'
import { connect } from 'react-redux'
import {
  Button,
  DELETE,
  fetchStart,
  fetchEnd,
  showNotification,
  refreshView
} from 'react-admin'
import { Remove } from '@material-ui/icons'

import dataProvider from '../../providers/dataProvider'

class DissociateButton extends Component {
  
  handleClick = () =>{
    this.dissociate()
  }

  dissociate({ basePath, basePathId, resource, record, refreshView, fetchStart, fetchEnd, showNotification } = this.props){
    fetchStart()
    dataProvider(DELETE, `${basePath}/${basePathId}/${resource}/rel`, {id : record.id})
    .then(_ => {
      refreshView()
    })
    .catch(e => {
      console.error(e)
      showNotification('ra.notification.http_error')
    })
    .finally(fetchEnd)
  }

  render(){
    return <Button label='ra.action.remove' onClick={this.handleClick} >{this.props.hasIcon && <Remove/>}</Button>
  }

}

export default connect(null, { 
  fetchStart, 
  fetchEnd,
  showNotification,
  refreshView
})(DissociateButton)
