import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { 
  fetchStart,
  fetchEnd,
  SimpleForm,
  showNotification,
  TextInput,
  translate,
  UPDATE,
} from 'react-admin';
import dataProvider from '../../providers/dataProvider';

class ProfileForm extends Component {

  updateProfile = (form) => {
    const { showNotification, fetchStart, fetchEnd } = this.props;
    const data = {
      username: form.username,
      name: form.name,
      email: form.email
    };
    // TO TRIGGER THE LOADING INDICATOR - NOT WORKING
    fetchStart();
    dataProvider(UPDATE, 'users', {
      id: form.id, 
      data: data
    }).then(_ => {
      showNotification('pos.profile.profile_updated');
      this.props.updateStorage(form);
      this.props.changeEditSettings();
    }).catch((_) => {
      showNotification('pos.profile.profile_update_error', 'warning');
    }).finally(fetchEnd);
  }

  render() {
    const { 
      translate, 
      value = {}
    } = this.props;

    return ( 
      <SimpleForm defaultValue={value} save={this.updateProfile}>
        <TextInput label={translate('resources.users.fields.username')} source="username" />
        <TextInput label={translate('resources.users.fields.name')} source="name" />
        <TextInput label={translate('resources.users.fields.email')} source="email" />
      </SimpleForm>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    fetchEnd,
    fetchStart,
    showNotification,
  })
);
export default enhance(ProfileForm);