import React, { Component } from 'react'
import{
  Button,
  translate
} from 'react-admin'
import {
  withStyles
} from '@material-ui/core'
import { Add } from '@material-ui/icons'

import { SimpleModal, CustomButton } from '../../components'

const styles = {
  button:{
    marginTop: '1em',
    marginRight: '0.5em'
  },

  rm_button:{
    marginTop: '1em',
    backgroundColor: 'rgba(255, 0, 0, 0.8)',
    "&:hover": {
      background: 'rgba(255, 0, 0, 1)'
    }
  },
  rm_icon: {
    marginTop: '1em',
    color: 'red',
    "&:hover": {
      background: 'rgba(255, 0, 0, 0.08)'
    }
  },
  closeButton:{
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  titleDiv:{
    display: 'flex',
    justifyContent: 'space-between'
  },
  secondButton: {
    marginTop: '1em',
    backgroundColor: "red",
    "&:hover": {
      background: 'rgba(255, 0, 0, 0.08)'
    }
  }
}

class RelationShow extends Component{
  state = {
    isOpen: false
  }

  sanitizeProps = ({
    dialogContent,
    children,
    reference,
    classes,
    parentrecord,
    secondPress,
    Icon,
    secondLabel,
    ...props
  }) => ({...props})

  openDialog = () => this.setState({isOpen: true})
  
  closeDialog = () => this.setState({isOpen: false})
  
  render() {
    const { classes, reference, children, dialogContent, translate, data, currentSort, basePath, parentrecord, secondPress, Icon, secondLabel } = this.props
    const referenceName = translate(`resources.${reference}.name`,{smart_count:1})
    return <div>
      <Button className={classes.button} variant='contained' onClick={this.openDialog} label={translate('pos.general.add_button')} /*label={translate('pos.general.add_referencing', {reference: referenceName})}*/><Add/></Button>
      
      { secondPress !== undefined && <CustomButton classes={{button: classes.rm_button, icon: classes.rm_icon}} variant="contained" onClick={secondPress} label={secondLabel ? secondLabel : ''}>{ Icon ? <Icon/> : null }</CustomButton> }

      <SimpleModal open={this.state.isOpen} onClose={this.closeDialog} title={referenceName} >
        {dialogContent && React.cloneElement(dialogContent, {relatedData: data, resource: reference, currentSort, referencePath: basePath, parentrecord})}
      </SimpleModal>
      {React.cloneElement(children, this.sanitizeProps(this.props))}
    </div>
  }
}

export default withStyles(styles)(translate(RelationShow))