import React, { Component, Fragment } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  withStyles,
  Typography,
  // Chip
} from '@material-ui/core';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { translate, showNotification } from 'react-admin'
import { SimpleModal, DateField } from '../../../components';
import { DetailsShow, DetailsEdit } from '../../../components/annotations';
import { refreshAnnotations } from '../../../containers/derivedsets/actions'
var styles = function styles(theme) {
  var height = 32;
  var backgroundColor = theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700];
  // var deleteIconColor = (0, _colorManipulator.fade)(theme.palette.text.primary, 0.26);
  return {
    /* Styles applied to the root element. */
    tag_root: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(13),
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: height,
      color: theme.palette.getContrastText(backgroundColor),
      backgroundColor: backgroundColor,
      borderRadius: height / 2,
      whiteSpace: 'nowrap',
      transition: theme.transitions.create(['background-color', 'box-shadow']),
      // label will inherit this from root, then `clickable` class overrides this for both
      cursor: 'default',
      // We disable the focus ring for mouse, touch and keyboard users.
      outline: 'none',
      textDecoration: 'none',
      border: 'none',
      // Remove `button` border
      padding: 0, // Remove `button` padding,
      float: 'right',

    },
    tag_label: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 12,
      paddingRight: 12,
      userSelect: 'none',
      whiteSpace: 'nowrap',
      cursor: 'inherit'
    },
    content: { padding: 0 },

    closeButton: {
      marginTop: '0.5em',
      marginRight: '0.5em'
    },
    actionButton: {
      padding: 0,
      minHeight: 0,
      minWidth: 0,
      width: '30px',
      height: '30px'
    },
    inline: {
      display: 'inline',
    },
    right: {
      float: 'right',
    }
  }
}

class AnnotationDialog extends Component {
  constructor(props) {
    super(props);

    this.refreshData = this.refreshData.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.pressAnnotation = this.pressAnnotation.bind(this);
    this.onCloseAnnotationShow = this.onCloseAnnotationShow.bind(this);
    this.openByTag = this.openByTag.bind(this);
  }
  state = {
    open: false,
    openAnnotationShow: false,
    openAnnotationEdit: false,
    annotations: [],
    details: {},
    version: 0,
    lastAnnotationPressed: undefined,
    openByTag: undefined,
    deleted: false
  };

  closeDialog = () => {
    this.setState({ open: false, version: this.state.version+1, lastAnnotationPressed: undefined });
    this.props.modalClose();
  };

  refreshData = (deleted = false) => {
    this.props.refreshAnnotations(this.props.recordId);

    const open = deleted;
    this.setState({ deleted, openAnnotationEdit: false, openAnnotationShow: false, open });
  }

  openByTag = (annotationIds) => {
    this.setState({openByTag: annotationIds});
    this.refreshData();
  }

  componentDidUpdate = (oldProps) => {
    const { modalOpen, tagAnnotationDialog, inserted_annotations, annotationIdDialog } = this.props;
    if (tagAnnotationDialog && oldProps.tagAnnotationDialog !== tagAnnotationDialog) {
      this.openByTag(tagAnnotationDialog);
    }

    if (modalOpen !== oldProps.modalOpen || modalOpen !== this.state.open) {
      this.setState({ open: modalOpen, version: this.state.version+1 });
    }
    
    if (inserted_annotations !== oldProps.inserted_annotations) {
      this.refreshData();
    }

    if(tagAnnotationDialog && oldProps.tagAnnotationDialog !== tagAnnotationDialog) {
      this.openByTag(tagAnnotationDialog);
    }
   
    if(annotationIdDialog && oldProps.annotationIdDialog !== annotationIdDialog) {
      this.pressAnnotation(annotationIdDialog);
    }

    if(oldProps.annotationsAction !== this.props.annotationsAction && this.props.annotationsAction) {
      let _openByTag = this.state.openByTag;
      let result = this.props.annotationsAction.filter(({ intervals }) => {
        if (intervals && intervals.length > 0)
          return true;
        return false;
      });

      if(_openByTag) {
        
        result = result.filter(({ tags, id }) => {
          return _openByTag.includes(String(id));
        });

        _openByTag = undefined;
      }
      this.setState({ annotations: result, version: this.state.version+1 });
      // this.setState({ annotations: result, open: true, openAnnotationShow: false, version: this.state.version+1 });


      const deleted = this.state.deleted;
      
      // const openAnnotationShow = deleted ? false : this.state.openAnnotationShow;
      const open = deleted;
      
      this.setState({ openByTag: undefined, deleted: false, open, version: this.state.version+1 });
      if(!deleted) setTimeout(() => this.pressAnnotation(this.state.lastAnnotationPressed), 50);
      // if(!deleted) this.pressAnnotation(this.state.lastAnnotationPressed)
    }
  }

  componentDidMount() {
    this.refreshData();
  }

  pressAnnotation = (annotationId) => {

    if(!annotationId ) return;
    const details = { ...this.state.annotations.filter(({ id }) => parseInt(id) === parseInt(annotationId))[0] };
    if(!(details.intervals && details.intervals.length > 0)) return;
    if (
      !details.intervals[0].endTime
    ) {
      details.interval = false;
    }
    else {
      details.beginTime = details.intervals[0].beginTime;
      details.endTime = details.intervals[0].endTime;
      details.interval = true;
    }
    this.setState({ open: false, openAnnotationShow: true, details: details, version: this.state.version+1, lastAnnotationPressed: annotationId });
  }

  onCloseAnnotationShow = () => {
    this.setState({ open: true, openAnnotationShow: false, openAnnotationEdit: false, version: this.state.version+1 });
  }

  onCloseAnnotationEdit = () => {
    this.setState({ open: false, openAnnotationShow: true, openAnnotationEdit: false, version: this.state.version+1 });
  }

  render() {
    const { classes, translate } = this.props;
    const { annotations } = this.state;
    let key = 0;
    return <div>
      <SimpleModal open={this.state.open} onClose={this.closeDialog} title={`${translate('containers.annotations.show_all')}`} maxWidth={false}>
        <List>
          {annotations.length !== 0 ? annotations./*filter(note => note.intervals.length>0).*/map((note) => {

            return <ListItem key={note.id} button={true} divider onClick={() => { this.pressAnnotation(note.id) }}>
              <ListItemText
                primary={note.text}
                secondary={
                  <Fragment>
                    <Typography component={'span'} className={classes.inline}>
                      {note.intervals[0].endTime ?
                        (
                          <Fragment>
                            <DateField source="beginTime" format='dd/MM/yyyy HH:mm:ss' record={{ beginTime: note.intervals[0].beginTime }} simpleText />
                            {' - '}
                            <DateField source="endTime" format='dd/MM/yyyy HH:mm:ss' record={{ endTime: note.intervals[0].endTime }} simpleText />

                          </ Fragment>
                        )
                        :
                        note.intervals.length + ' ' + translate('containers.annotations.observations')
                      }
                    </Typography>

                    <Typography component={'span'} className={classes.right} color="textSecondary">
                      {note.tags.map(tag => {
                        return (
                          <span className={classes.tag_root} key={key++}>
                            <span className={classes.tag_label} > {tag.name}</span>
                          </ span>)
                      })}
                    </Typography>

                  </Fragment>
                }
              />
            </ListItem>
          })
            : <span>{translate('ra.navigation.no_results')}</span>}
        </List>
      </SimpleModal>


      {this.state.openAnnotationShow &&
        <DetailsShow
          open={this.state.openAnnotationShow}
          onClose={this.onCloseAnnotationShow}
          openEdit={() => this.setState({ openAnnotationShow: false, openAnnotationEdit: true, version: this.state.version+1 })}
          record={this.state.details}
          refreshData={this.refreshData}
          key={this.state.version}
        />
      }

      {this.state.openAnnotationEdit &&
        <DetailsEdit open={this.state.openAnnotationEdit}
          onClose={this.onCloseAnnotationEdit}
          record={this.state.details}
          refreshData={this.refreshData}
          key={this.state.version}
        />
      }
    </div>
  }
};

const mapStateToProps = state => ({
  annotationsAction: state.annotations
});

const mapDispatchToProps = dispatch => {
  return ({
    refreshAnnotations: data => dispatch(refreshAnnotations(data)),
    showNotification
})};

const enhance = compose(
  withStyles(styles),
  translate,
  connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(AnnotationDialog);