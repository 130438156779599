import React, { Component } from 'react';
import {connect} from 'react-redux';
import {
  UPDATE,
  refreshView,
  translate
} from 'react-admin';
import compose from 'recompose/compose';
import {
  IconButton,
  Typography,
  withStyles
} from '@material-ui/core'
import TerminateIcon from '@material-ui/icons/Lock'
import { dataProvider } from '../../providers'

const styles = {
  terminated: {
    color: 'red'
  },
  open: {
    color: 'green'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  iconButton: {
    marginLeft: '8px'
  }
}

class LaunchOpenField extends Component {

  onTerminateLaunchClick = () => {
    const { resource, record, refreshView } = this.props;
    dataProvider(UPDATE, resource, { id: `${record.id}/close` })
      .then(_ => {
        refreshView();
      })
  }

  render() {
    const { classes, record, translate } = this.props;

    return record && record.endTimestamp ? <Typography color='primary' classes={{ colorPrimary: classes.terminated }}>{translate('pos.general.closed')}</Typography> :
    <div className={classes.container}>
      <Typography color='primary' classes={{ colorPrimary: classes.open }}>{translate('pos.general.open')}</Typography>
      <IconButton color='primary' classes={{ root: classes.iconButton, colorPrimary: classes.terminated }} onClick={this.onTerminateLaunchClick}><TerminateIcon /></IconButton>
    </div>
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
  connect(null, {refreshView})
)

export default enhance(LaunchOpenField);