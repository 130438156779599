import { MapControl } from 'react-leaflet';
import TimeControl from './timeline-control';
import './timeline.css';
import Timeline from './timeline';

export default class TimelineControl extends MapControl {

  createLeafletElement(props) {
    this.timeline = Timeline(props.geoJSON, props.ctrlLayer, {...props.timelineOptions, map: this.context.map, openAnnotation: props.openAnnotation});
    this.timeline.addTo(this.context.map);
    let leafletElement = TimeControl(this.timeline, Object.assign({}, props.options, props.timecontrolOptions));
    return leafletElement;
  }
  
  updateLeafletElement(oldProps, newProps){
    if(JSON.stringify(newProps.geoJSON) !== JSON.stringify(oldProps.geoJSON)) {
      if(newProps.multiLine) {
        this.timeline.update(newProps.geoJSON, newProps.ctrlLayer, newProps.timelineOptions, this.context.map);
      }
      else {
        this.timeline.remove();
        this.timeline.clearLayers();
        this.timeline.remove();
        this.leafletElement.remove();
        this.leafletElement = this.createLeafletElement(newProps);
        this.leafletElement.addTo(this.context.map);
      }
    }
  }

  componentWillUnmount(){
    this.timeline.remove();
    this.leafletElement.remove();
  }
}