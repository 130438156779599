import React from 'react';
import PropTypes from 'prop-types';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
// import { sanitizeListRestProps } from 'ra-core';
import { Typography } from '@material-ui/core';

import {
  // CardActions,
  CreateButton,
  ExportButton,
  RefreshButton,
  EditButton,
  ListButton
} from 'react-admin';

import HistoryButton from '../defaultActions/HistoryButton';

// Improve component in order to remove "removeListButton"

const DefaultCardActions = ({
  bulkActions,
  basePath,
  currentSort,
  className,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  permanentFilter,
  resource,
  selectedIds,
  showFilter,
  hasExport,
  hasRefresh,
  hasCreate,
  createLabel,
  hasEdit,
  hasList,
  hasShow,
  data,
  history,
  removeListButton,
  hasHistory,
  id,
  title,
  ...rest
}) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div>
      {title && <Typography variant='title'>{title}</Typography>}
    </div>
    <div style={{ right: 0 }}>

      {bulkActions && React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}

      {hasCreate && <CreateButton basePath={basePath} label={createLabel} />}
      {hasEdit && <EditButton basePath={basePath} record={data ? data : { id: id }} />}
      {hasList && <ListButton basePath={basePath} />}

      {hasHistory && <HistoryButton history={history} />}

      {hasExport && <ExportButton
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />}
      {hasRefresh && <RefreshButton />}
    </div>
  </div>
);

DefaultCardActions.propTypes = {
  bulkActions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  basePath: PropTypes.string,
  className: PropTypes.string,
  currentSort: PropTypes.object,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  createLabel: PropTypes.string,
  resource: PropTypes.string,
  onUnselectItems: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  showFilter: PropTypes.func,
  hasExport: PropTypes.bool,
  hasRefresh: PropTypes.bool,
  data: PropTypes.object,
  history: PropTypes.object,
  removeListButton: PropTypes.bool,
};

DefaultCardActions.defaultProps = {
  selectedIds: [],
};

export default onlyUpdateForKeys([
  'hasExport',
  'resource',
  'filters',
  'displayedFilters',
  'filterValues',
  'selectedIds',
])(DefaultCardActions);