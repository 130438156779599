import React, { Component } from 'react';

import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  translate,
  Confirm,
  showNotification
} from 'react-admin';

import {
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@material-ui/icons';

import {
  Tooltip,
  IconButton
} from '@material-ui/core';
import { dataFetch } from '../../providers';
import { refreshView } from 'ra-core';

const ConfirmDialog = translate(({ translate, record, open, handleBuild, handleClose, ...props }) => (
  <Confirm
    isOpen={open}
    title={translate("resources.datasets.buildJob", { datasetName: record && record.name })}
    content={translate("resources.datasets.buildJobWarning")}
    onConfirm={handleBuild}
    onClose={handleClose}
  />
));

class Status extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    };
  }

  handleAction = () => {
    this.setState({
      open: true
    });
  }
  handleBuild = () => {
    // console.log({datasetName: this.props.record.name})
    dataFetch('POST', `/datasets/build/${this.props.record.id}`)
    .then(_ => this.props.showNotification('resources.datasets.buildJobNotification', 'info', {messageArgs: {datasetName: this.props.record.name}}))
    .catch(err => this.props.showNotification('errors.SOMETHING', 'warning'))
    .finally(_ => {
      this.handleClose();
      this.props.refreshView();
    });
  }

  handleClose = () => {
    this.setState({
      open: false
    });
  }


  render() {

    const { source, record } = this.props;
    const { open } = this.state;
    const status = record[source];
    switch (status) {
      case 'UPDATED':
        return (
          <Tooltip title={'Updated'}>
            <div>
              <IconButton disabled={true} onClick={this.handleAction}>
                <CheckCircleIcon style={{ color: 'rgba(50,205,50)' }} />
              </IconButton>
            </div>
          </Tooltip>

        )

      case 'PROCESSING':
      case 'PENDING':
        return (
          <Tooltip title={'Processing'}>
            <div>

              <IconButton disabled={true} onClick={this.handleAction}>
                <RadioButtonUncheckedIcon style={{ color: 'rgba(0,89,255)' }} />
              </IconButton>
            </div>
          </Tooltip>
        )

      case 'OUTDATED':
      case 'CREATED':
      case null:
        return (
          <div>
            {open && <ConfirmDialog record={record} open={open} handleBuild={this.handleBuild} handleClose={this.handleClose}/>}
            <Tooltip title={'Outdated'}>
              <div>
                <IconButton onClick={this.handleAction}>
                  <ErrorOutlineIcon style={{ color: 'rgba(255,165,0)' }} />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        )
      default:
        return (
          <div>
            {open && <ConfirmDialog record={record} open={open} handleBuild={this.handleBuild} handleClose={this.handleClose}/>}
            <Tooltip title={'Error'}>
              <div>
                <IconButton onClick={this.handleAction}>
                  <ErrorOutlineIcon style={{ color: 'rgba(255,0,0)' }} />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        )
    }
  }
}

Status.defaultProps = {
  addLabel: true,
};

const enhance = compose(
  translate,
  connect(null, {
    showNotification,
    refreshView
  })
);

export default enhance(Status);