import React from 'react';
import {
  ExportButton,
  SaveButton
} from 'react-admin';
import SearchIcon from '@material-ui/icons/Search'

import { datasetExporter } from '../../utils/util';

export default ({invalid, handleSubmitWithRedirect, redirect, saving, submitOnEnter, filter, sort, resource, data, ...props}) => {
  return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
    <SaveButton
      style={{marginRight: '16px'}}
      variant='flat'
      handleSubmitWithRedirect={handleSubmitWithRedirect}
      invalid={invalid}
      redirect={redirect}
      saving={saving}
      submitOnEnter={submitOnEnter} 
      label='containers.search.name'
      icon={<SearchIcon/>}
    />
    {Object.keys(data).length > 0 && <ExportButton style={{marginRight: '16px'}} filter={filter} sort={sort} resource={resource} maxResults={100000} exporter={datasetExporter('observations')}/>}
  </div>
}