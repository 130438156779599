import React, { Component } from 'react';
import {
  Typography,
} from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles';

var styles = function styles(theme) {
  var height = 32;
  var backgroundColor = theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700];
  // var deleteIconColor = (0, _colorManipulator.fade)(theme.palette.text.primary, 0.26);
  return {
    /* Styles applied to the root element. */
    tag_root: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(13),
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: height,
      color: theme.palette.getContrastText(backgroundColor),
      backgroundColor: backgroundColor,
      borderRadius: height / 2,
      whiteSpace: 'nowrap',
      transition: theme.transitions.create(['background-color', 'box-shadow']),
      // label will inherit this from root, then `clickable` class overrides this for both
      cursor: 'default',
      // We disable the focus ring for mouse, touch and keyboard users.
      outline: 'none',
      textDecoration: 'none',
      border: 'none',
      // Remove `button` border
      padding: 0, // Remove `button` padding,
      float: 'right',

    },
    tag_label: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 12,
      paddingRight: 12,
      userSelect: 'none',
      whiteSpace: 'nowrap',
      cursor: 'inherit'
    },
    content: { padding: 0 },

    closeButton: {
      marginTop: '0.5em',
      marginRight: '0.5em'
    },
    actionButton: {
      padding: 0,
      minHeight: 0,
      minWidth: 0,
      width: '30px',
      height: '30px'
    },
    inline: {
      display: 'inline',
    },
    right: {
      float: 'right',
    }
  }
}


class TagsField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: {}
    };
  }

  componentDidMount() {
    const tags  = {};
    this.props.tags.forEach((t) => {
      tags[t.id] = t.name;
    });

    this.setState({tags});
  }
  render() {
    const { classes, record } = this.props;
    return (

      <Typography component={'span'} className={classes.right} color="textSecondary">
        {record.tags && record.tags.map((tag, index) => {
          return (
            <span className={classes.tag_root} key={index}>
              <span className={classes.tag_label} > {this.state.tags[tag]}</span>
            </ span>)
        })}
      </Typography>
    )
  }
}

export default withStyles(styles)(TagsField);
