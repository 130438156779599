import React, { Component } from 'react';
import { Button, withStyles } from '@material-ui/core';
import { NoteAdd as NoteAddIcon } from '@material-ui/icons';
import { Control } from 'react-leaflet-control-inesc';
import Details from './Details';

const styles = {
  button: {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    width: '30px',
    height: '30px',
  },
  checkBox: {
    margin: '10px',
    background: "green"
  }
}

class NodeControl extends Component {

  constructor(props) {
    super(props);

    this.state = {
      active: false
    };
  }

  onClose = () => {
    this.setState({ active: false });
  }

  render() {
    const { classes, recordId, refreshData, ...props } = this.props
    const { active } = this.state;
    return (
      <Control position="topleft" className="leaflet-control-zoom leaflet-bar leaflet-control">
        <div style={{ background: 'white' }}>
          <Button
            {...props}
            classes={{ root: classes.button }}
            onClick={() => { this.setState({ active: !active }) }}
          >
            <NoteAddIcon style={{ fontSize: '15px', padding: '8px', color: 'black' }} />
          </Button>
          
          <Details open={active} onClose={this.onClose} recordId={recordId} success={this.success} />
        </div>
      </Control>
    );
  }
}

export default withStyles(styles)(NodeControl);