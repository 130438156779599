import { 
  FETCH_OBSERVATIONS,
  STORE_OVERLAYS,
  SELECT_CAMPAIGN,
} from './actions';

export const fetchedObservations =  (previousState = null, { type, payload }) => {
  switch (type) {
    case FETCH_OBSERVATIONS:
      return payload;
    default:
      return previousState;
  }
};

export const overlays = (previousState = [] , {type, payload}) => {
  switch(type){
    case STORE_OVERLAYS:
      return payload;
    default:
      return previousState;
  }
};

export const selectedCampaign = (previousState = null, {type, payload}) => {
  switch(type){
    case SELECT_CAMPAIGN:
      return payload;
    default:
      return previousState;
  }
};