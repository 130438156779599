import React, { Component } from 'react';
import {
  CardActions,
  Button,
  ShowButton
} from 'react-admin';
import { VpnKey as PasswordIcon } from '@material-ui/icons';

import ChangePasswordDialog from './ChangePasswordDialog';

export default class EditActions extends Component{

  state = {
    open: false
  }

  handleClose = () =>{
    this.setState({open:false});
  }

  render(){
    const { hasShow, basePath, data, resource, id } = this.props;
    return <CardActions>
      <Button onClick={() => this.setState({open:true})} label={'pos.profile.change_password'}><PasswordIcon/></Button>
      <ChangePasswordDialog open={this.state.open} onClose={this.handleClose} resource={resource} resourceId={id}/>
      {hasShow && <ShowButton basePath={basePath} record={data} />}
    </CardActions>
  }

}
