import React, {Component} from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose'
import {
  Button,
  Pagination,
  TextField,
  crudCreate,
  translate,
  setListSelectedIds,
  showNotification,
} from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import {
  DialogContent,
  TextField as SearchField
} from '@material-ui/core'
import { ResponsiveDatagrid } from '../../components';
import dataFetch from '../../providers/dataFetch';

class AddObservationDialogContent extends Component {

  state={
    sort:{
      field: 'id',
      order: 'ASC',
    },
    page: 1,
    perPage: 10,
    selectedDerived: [],
    version: 0,

    data: {},
    ids: [],
    search: ""
  }

  componentDidMount(){
    const {derivedDtsIds, derivedDts} = this.props;

    this.setState({ ids: derivedDtsIds, data: derivedDts });
    
    this.fetchAll()
  }

  componentWillUnmount(){
    this.props.setListSelectedIds('observations',[]);
  }

  componentDidUpdate(oldProps) {
    const {derivedDtsIds, derivedDts} = this.props;
    if(oldProps.derivedDtsIds !== derivedDtsIds){
      this.setState({ ids: derivedDtsIds });
    }
    
    if (oldProps.derivedDts !== derivedDts) {
      this.setState({ data: derivedDts });
    }
  }

  fetchAll(){
    const { search, sort } = this.state;
    const filter = {
      where: {
        q: search
      },
      order: [
        `${sort.field} ${sort.order}`
      ]
    };
    const { datasetId } = this.props;
    const fetch = () => {
      return new Promise( (resolve, reject) => {
        dataFetch('GET', `/derivedsets/dataset/${datasetId}/available?filter=${JSON.stringify(filter)}`)
          .then(data => {
            resolve(data)
          })
          .catch(err => reject(err));
      });
    };

    fetch().then( (result) => {
      let data = {};
      let ids = [];
      Object.keys(result).forEach(key => {
        const obj = result[key]
       
        data[obj.id] = obj;
        ids.push(obj.id)
        delete result[key];
      });
      result = null;
     
      this.setState({ data: data });
      this.setState({ ids: ids });
    })
    .catch(e => {
      this.props.showNotification('ra.notification.http_error', 'warning')
    }); 
  }


  onChangeSearch = (e) => {
    this.setState({search: e.target.value}, this.fetchAll);
  }

  setSort = field => {
    const order =
    this.state.sort.field === field &&
    this.state.sort.order === 'ASC'
    ? 'DESC'
    : 'ASC';
    this.setState({ sort: { field, order } }, this.fetchAll );
  };

  setPage = page => this.setState({page})

  setPerPage = perPage => {this.setState({perPage})}

  toggleItem = (id) => {
    this.state.selectedDerived.includes(id) ? this.setState(state => {
      return {selectedDerived: [], version: state.version+1}
    }) 
    : this.setState(state => {
      return {selectedDerived: [id], version: state.version+1}
    })
  }
  
  selectAll = () => {
    this.props.showNotification('pos.info.one_selectable')
  }

  addObsToDerivedDts = () => {

    const { selectedObsIds, all_data } = this.props;
    let derivedObs = [];
    selectedObsIds.forEach(id => {

      derivedObs.push({
        timestamp: all_data[id].timestamp,
        record: all_data[id].record,
        datasetId: all_data[id].datasetId,
      });

    })
    this.props.crudCreate(`derivedsets/${this.state.selectedDerived}/observations/rel`, derivedObs, '', false);

    this.props.close();
  }

  render(){
    const {listFilters, translate} = this.props;
    const {page, perPage, selectedDerived, version, data, ids} = this.state;

    const paginatedIds = ids.slice((page-1)*perPage,page*perPage)

    return <DialogContent>
      {listFilters && 
          <SearchField type="text" placeholder={translate('pos.search')} onChange={this.onChangeSearch}/>
        }
      <ResponsiveDatagrid data={data} ids={paginatedIds} currentSort={this.state.sort} setSort={this.setSort} onToggleItem={this.toggleItem} selectedIds={selectedDerived} version={version} onSelect={this.selectAll} hasBulkActions>
        <TextField source="name" />
        <TextField source="description" />
      </ResponsiveDatagrid>
      <Pagination page={page} perPage={perPage} setPage={this.setPage} setPerPage={this.setPerPage} total={ids.length}/>
      {selectedDerived.length === 1 && <Button variant='raised' label='ra.action.add' onClick={this.addObsToDerivedDts}><SaveIcon/></Button>}
    </DialogContent>
  }
}

const mapStateToProps = state => { 
  return ({
    derivedDts: state.admin.resources.derivedsets.data,
    derivedDtsIds: state.admin.resources.derivedsets.list.ids
  })
}

export default compose(
  translate,
  connect(mapStateToProps, {
  crudCreate,
  setListSelectedIds,
  showNotification
}))(AddObservationDialogContent)