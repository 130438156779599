import React from 'react';
import params from './params';
import './index.css';
//Input imports

import { withStyles } from '@material-ui/core';
import {
    required,
    translate,
    SelectInput as Select,
    NumberInput
} from 'react-admin';

import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {
    Map,
    Marker,
    TileLayer
} from 'react-leaflet'
import L from 'leaflet';

import { DateInput } from '../../../components';
import CalculateIcon from './icons/Calculate';
import Button from './MediumButton';

import SimpleForm from './Form/SimpleForm';

const listStyles = function styles(theme) {
    return {
        detailsMap: {
            [theme.breakpoints.down('xs')]: {
                minHeight: theme.spacing.unit * 40
            },
            flex: 1,
            display: 'flex',
            paddingTop: '16px',
            marginTop: '16px',
            justifyContent: 'flex-end',
            width: '100%',

        },
        toolbar: {
            [theme.breakpoints.up('sm')]: {
                paddingTop: '10px'
            },
            // [theme.breakpoints.down('xs')]: {
            paddingTop: '-75px',
            // },
            paddingLeft: '24px',
            paddingBottom: '15px',
        },
        options: {
            flex: 0.2,
            flexDirection: 'column',
            display: 'flex',
            marginRight: '2em'
        },
        inputs: {
            [theme.breakpoints.up('sm')]: {
                display: 'flex'
            },
        }
    }
}

// TO DO:
// media diaria de satelites por hora / total - combobox                          - DONE
//    -->Fazer processamento de dados                                             - DONE
//    -->Combobox (front end)                                                     - DONE
// 3 cores para o gradiente dos graficos - checkbox                               - DONE
//    -->Checkbox (front end)                                                     - DONE
//    -->Passar variaveis da componete pai para as filho                          - DONE
// end date, begin date - input                                                   - DONE
//    -->criar boxs de inpute (front end)                                         - DONE
//    -->Passar variaveis da componete filho(Options) para pai(SatellitesCover)   - DONE
//    -->Restringir intervalos de datas inadmissiveis                             - DONE
class Options extends React.Component {
    state = {
        start_date: undefined,
        end_date: undefined,
        lat: undefined,
        lng: undefined,
        alt: undefined,
        zoom: 10,
        minimum_elevation: 10,
        disabled: true
    }

    handleCalculate = () => {
        const { lat, lng, alt, start_date, end_date, minimum_elevation } = this.state;

        this.setState({ disabled: true });
        this.props.fetchData(lat, lng, alt, start_date, end_date, minimum_elevation);
        // .finally(_ => {
        // this.setState({ disabled: false });
        // });
    }

    componentDidMount() {
        const lat = (this.props.record.area.lat).toFixed(3);
        const lng = (this.props.record.area.lng).toFixed(3);
        const alt = params.default_alt;
        const minimum_elevation = this.state.minimum_elevation;
        let start_date, end_date;

        if (new Date(this.props.record.beginDate) >= new Date()) {
            start_date = new Date(this.props.record.beginDate);
            start_date = start_date.getFullYear() + "-" + ('0' + (start_date.getMonth() + parseInt(1))).slice(-2) + "-" + ('0' + start_date.getDate()).slice(-2);
            end_date = new Date(new Date(this.props.record.beginDate).getTime() + params.default_aditional_date);
            end_date = end_date.getFullYear() + "-" + ('0' + (end_date.getMonth() + parseInt(1))).slice(-2) + "-" + ('0' + end_date.getDate()).slice(-2);
        } else {
            start_date = new Date().getFullYear() + "-" + ('0' + (new Date().getMonth() + parseInt(1))).slice(-2) + "-" + ('0' + new Date().getDate()).slice(-2);
            end_date = new Date(new Date().getTime() + params.default_aditional_date);
            end_date = end_date.getFullYear() + "-" + ('0' + (end_date.getMonth() + parseInt(1))).slice(-2) + "-" + ('0' + end_date.getDate()).slice(-2);
        }

        this.props.fetchData(lat, lng, alt, start_date, end_date, minimum_elevation);

        this.setState({
            start_date,
            end_date,
            lat,
            lng,
            alt,
            minimum_elevation,
        });
    }

    handleDateInputChange = (_, after, before, source) => {
        if (source === 'start_date' && (after > new Date(this.state.end_date))) {
            let end_date = after; //new Date(after.getTime() + (1000*60*60*24));
            this.setState({
                [source]: after.getFullYear() + "-" + ('0' + (after.getMonth() + parseInt(1))).slice(-2) + "-" + ('0' + after.getDate()).slice(-2),
                end_date: end_date.getFullYear() + "-" + ('0' + (end_date.getMonth() + parseInt(1))).slice(-2) + "-" + ('0' + end_date.getDate()).slice(-2),
                disabled: false
            });
        }

        if (before)
            this.setState({ [source]: after.getFullYear() + "-" + ('0' + (after.getMonth() + parseInt(1))).slice(-2) + "-" + ('0' + after.getDate()).slice(-2), disabled: false });
    }

    handleMapOnClick = (param) => {
        this.setState({ lat: param.latlng.lat, lng: param.latlng.lng, disabled: false });
    }

    handleMapOnDrag = (param) => {
        this.setState({ lat: param.target._latlng.lat, lng: param.target._latlng.lng, disabled: false });
    }

    handleZoom = (param) => {
        this.setState({ zoom: param.target._zoom });
    }

    render() {
        const { translate, handleUpdate, user_color, classes } = this.props;
        return (
            <>
                <div className={classes.inputs} >
                    <div className={classes.options}>
                        <SimpleForm
                            toolbar={null}
                        >
                            <Select
                                source='color'
                                defaultValue={user_color}
                                onChange={(event, key, ...args) => {
                                    event.preventDefault();
                                    handleUpdate("user_color", key)
                                }}
                                allowEmpty={false}
                                label='resources.campaigns.satellites.charts_color'
                                choices={[
                                    { id: params.colors[0].color, name: 'resources.campaigns.satellites.color0' },
                                    { id: params.colors[1].color, name: 'resources.campaigns.satellites.color1' },
                                    { id: params.colors[2].color, name: 'resources.campaigns.satellites.color2' }
                                ]}
                            />
                            {this.state.start_date && <DateInput
                                validate={required()}
                                source='start_date'
                                label={translate('resources.campaigns.satellites.begin_date')}
                                month={true}
                                options={{
                                    format: 'dd/MM/yyyy',
                                }}
                                onChange={this.handleDateInputChange}
                                start_date={this.state.start_date}
                            />}
                            {this.state.end_date && <DateInput
                                validate={required()}
                                source='end_date'
                                label={translate('resources.campaigns.satellites.end_date')}
                                month={true}
                                options={{
                                    format: 'dd/MM/yyyy',
                                    minDate: this.state.start_date
                                }}
                                onChange={this.handleDateInputChange}
                                end_date={this.state.end_date}
                            />}
                            <NumberInput
                                // disabled
                                label={translate('resources.campaigns.satellites.minimum_elevation')}
                                defaultValue={this.state.minimum_elevation}
                                required
                                onChange={(e, value) => {
                                    e.preventDefault();
                                    value < 0 ?
                                        this.setState({ minimum_elevation: 0, disabled: false }) :
                                        this.setState({ minimum_elevation: value, disabled: false })
                                }}
                            />
                        </SimpleForm>
                        <div className={classes.toolbar}>
                            <Button disabled={this.state.disabled} variant={'raised'} onClick={this.handleCalculate} className={classes.button} color={'primary'} label='resources.campaigns.satellites.calculate_button'>
                                <CalculateIcon />
                            </Button>
                        </ div>
                    </div>
                    <Map
                        center={this.state.lat && this.state.lng ? L.latLng(this.state.lat, this.state.lng) : L.latLng(41.15, -8.611111)}
                        zoom={this.state.zoom}
                        onClick={this.handleMapOnClick}
                        onZoomEnd={this.handleZoom}
                        className={classes.detailsMap}
                    >
                        <TileLayer
                            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {this.state.lat && this.state.lng && <Marker position={L.latLng(this.state.lat, this.state.lng)} onDragend={this.handleMapOnDrag} draggable />}
                    </Map>
                </div>
            </>
        );
    }
}

Options.propTypes = {
    classes: PropTypes.object.isRequired,
};

const enhance = compose(
    translate,
    withStyles(listStyles),
);

export default enhance(Options);