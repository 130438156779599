import moment from 'moment';

export const toWavyGeojson = (data) => {
  const observation = data.observation;
  const observedProperties = data.observedProperties;

  return {
    wavy: {
      id: observation.serialNumber,
      name: observation.serialNumber
    },
    position: [ ObservationToFeature(observation, observedProperties, true) ]
  };
}

export const toWavyGeojsonOld = (data) => {
  
  const { observations, observedProperties, latest, wavys } = data;

  let result = {};

  observations.forEach(obs => {
    const { serialNumber } = obs;
    if(!result[serialNumber]) {
      result[serialNumber] = { 
        wavy : {
          id: serialNumber, 
          name: serialNumber
        },
        position: []
      };
    }

    const pos = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [parseFloat(obs.position.lng), parseFloat(obs.position.lat)]
      },
      style: {
        color: wavys[serialNumber].color
      },
      properties: {
        id: serialNumber,
        key: serialNumber,
        timestamp: new Date(obs.timestamp).getTime()
      }
    };
    
    result[serialNumber].position.push(pos);
  })
  latest.forEach(wavy => {

    const { serialNumber, content, timestamp, position } = wavy[0]; // why 0?
    const props = content.split('|');

    const pos = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [parseFloat(position.lng), parseFloat(position.lat)]
      },
      style: {
        color: wavys[serialNumber].color
      },
      properties: {
        id: serialNumber,
        key: serialNumber,
        timestamp: new Date(timestamp).getTime()
      }
    };

    observedProperties[wavys[serialNumber].modelId].forEach(({index, visible, observedproperty, unitOfMeasurement}) => {
      if(visible) {
        pos.properties[observedproperty.name] = {
          value: props[index],
          unit: unitOfMeasurement
        };
      }
    });

    result[serialNumber].position.push(pos);

  })

  return Object.values(result);
}


// For now it will use the Observation keys
// The objective is to transform the array of objects in a GeoJson (Feature Collection)
export const toObservationData = (data) => {
  const {observations, observedProperties} = data;

  let features = [];
  for (let i = 0; i < observations.length; i++){
    features.push(ObservationToFeature(observations[i], observedProperties[observations[i].datasetId]));
  }

  return {
    type: "FeatureCollection",
    features: features
  }
};

export const toObservationDataSingleWAVY = (data) => {
  const {observations, observedProperties} = data;

  let features = [];
  for (let i = 0; i < observations.length; i++){
    features.push(ObservationToFeature(observations[i], observedProperties));
  }

  return {
    type: "FeatureCollection",
    features: features
  }
};

const ObservationToFeature = (observation, observedProperties, live = false) => { 
  // NOT observedProperties but equipmentModelProperties!!!
  const properties = {
    id: observation['serialNumber'],
    key: observation['serialNumber'],
    timestamp: moment(observation['timestamp']).unix() * 1000,
  };

  if (observedProperties) {
    observedProperties.forEach(observedProperty => {
      const key = observedProperty.observedproperty && observedProperty.observedproperty.name;
      if(key && observedProperty.visible)
        properties[key] = {
          value: observation[key],
          unit: observedProperty.unitOfMeasurement
        }
    });
  } else {
    const hiddenProperties = ['id', 'key', 'serialNumber', 'timestamp', 'createdAt', 'updatedAt', 'datasetId','derivedsetId', 'position', 'properties', 'dataset'];
    Object.keys(observation).forEach(key => {
      if (!hiddenProperties.includes(key)) {
        properties[key] = {
          value: observation[key],
          unit: null
        };
      }
    });
  }

  let color;
  if(live)
    color = observation.equipment && observation.equipment.properties ? observation.equipment.properties.color : "rgb(51, 136, 255)";
  else
    color = observation.properties ? observation.properties.color : "rgb(51, 136, 255)";
  return {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [parseFloat(observation.position.lng), parseFloat(observation.position.lat)]
    },
    style: {
      color: color
    },
    properties: properties
  };
};

// colors....
export const annotationsGeoJSON = (observations, annotations) => {
  let tagsGeoJSON = [];
  
  annotations.forEach(({intervals, tags, id, text}) => {
    intervals.forEach(({beginTime, endTime, record}) => {
      if(record === 0 && observations[new Date(beginTime).getTime()]) {
        tags.forEach(({name, color = "rgb(255, 0, 0)"}) => {
          tagsGeoJSON.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [
                parseFloat(observations[new Date(beginTime).getTime()].position.lng),
                parseFloat(observations[new Date(beginTime).getTime()].position.lat)
              ]
            },
            style: {
              color: color
            },
            properties: {
              description: text,
              tag: name,
              id: `${name}_annotation`,
              key: name,
              multiLineId: id,
              timestamp: new Date(beginTime).getTime()
            }
          });
        });
      }
      else {
        let obs = Object.keys(observations).filter(o => o >= new Date(beginTime).getTime() && o <= new Date(endTime).getTime());

        tags.forEach(({name, color}) => {
          obs.forEach(time => {
            tagsGeoJSON.push({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [
                  parseFloat(observations[time].position.lng),
                  parseFloat(observations[time].position.lat)
                ]
              },
              style: {
                color: color || "rgb(255, 0, 0)"
              },
              properties: {
                description: text,
                tag: name,
                id: `${name}_annotation`,
                key: name,
                multiLineId: id,
                timestamp: time
              }
            });
          });
        });
      }
    });
  });
  return tagsGeoJSON;
};