import React, {Component} from 'react';
import {
  Button,
  Title,
  crudGetOne as crudGetOneAction,
  fetchEnd,
  fetchStart,
  translate,
  showNotification
} from 'react-admin';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ShowChart as ChartIcon,
  ViewList as TableIcon,
  List as DatasetIcon
} from '@material-ui/icons';
import { stringify } from 'query-string';
import { Typography } from '@material-ui/core';

import TimeControlledMap from '../../components/map/TimeControlledMap';
import { toObservationDataSingleWAVY } from '../../factory'; 
import { dataFetch } from '../../providers';

class DatasetMap extends Component{

  state = {
    datasetData: null,
    load: false
  }

  getObservations = datasetId => {
    const filter = {
      where: {datasetId: datasetId, record: 0},
      order: ["timestamp ASC"]
    };

    return new Promise((resolve, reject) => {
      dataFetch('GET', `/observations?filter=${JSON.stringify(filter)}`)
        .then(data => resolve(data))
        .catch(err => reject(err));
    });
  };

  // Imporve this call - check crudGetOne
  getObservedProperties = datasetId => {
    return new Promise((resolve, reject) => {
      dataFetch('GET', `/datasets/${datasetId}?filter=${JSON.stringify({include: 'equipment'})}`)
        .then(data => {
          const equipmentmodelId = data && data.equipment && data.equipment.equipmentmodelId;
          if (equipmentmodelId) {
            const filter = JSON.stringify({
              include: 'observedproperty',
              where: {equipmentmodelId: equipmentmodelId}
            });

            dataFetch('GET', `/modelproperties?filter=${filter}`)
              .then(result => {
                // let datasets = {};
                // datasets[datasetId] = result;

                resolve(result);
              })
              .catch(err => reject(err));
          } else {
            resolve();
          }
        }).catch(err => reject(err));
    });
  };

  componentDidMount() {
    const { crudGetOne, match, dataset } = this.props;
  
    if(!dataset) {
      crudGetOne('datasets', match.params.id, 'datasets');
    }
    else {
      this.processData();
    }
  }

  processData = () => {
    this.setState({load: true});
      
    const { fetchEnd, fetchStart, match } = this.props;

    const promises = [
      this.getObservations(match.params.id),
      this.getObservedProperties(match.params.id)
    ];

    fetchStart();
    Promise.all(promises)
      .then(data => {
        const observations = data[0];
        const observedProperties = data[1];

        this.setState({datasetData: toObservationDataSingleWAVY({
          observations: observations,
          observedProperties: observedProperties
        })});
      })
      .catch(err => {
        console.error(err)
        this.props.showNotification('containers.derivedsets.map.loadObsError', 'warning');
      })
      .finally(_ => {
        fetchEnd();
      });
  }

  componentDidUpdate(){
    const { dataset } = this.props;
    const { load } = this.state;
    if(dataset && !load) {
      this.processData();
    }
  }

  render(){
    const { translate, dataset } = this.props;
    const { datasetData } = this.state;
    const datasetId = parseInt(this.props.match.params.id);

    //
    // DOWNLOAD GEOJSON POINT - For João test grafana
    //

    // const downloadObjectAsJson = (exportObj, exportName="teste") => {
    //   var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    //   var downloadAnchorNode = document.createElement('a');
    //   downloadAnchorNode.setAttribute("href",     dataStr);
    //   downloadAnchorNode.setAttribute("download", exportName + ".json");
    //   document.body.appendChild(downloadAnchorNode); // required for firefox
    //   downloadAnchorNode.click();
    //   downloadAnchorNode.remove();
    // }
    // datasetData && downloadObjectAsJson(datasetData,dataset.name)
  
    return (
      <div style={{display:'flex', flex: '1', flexDirection:'column'}}>
        <Title title={translate('containers.maps.datasets.name')} />
        <div style={{marginBottom:'8px', display:'flex', justifyContent:'space-between'}}>
          <Typography variant='title' >{dataset && dataset.name}</Typography>
          <div>
            <Button style={{marginRight:'8px'}} component={Link} label='pos.general.chart' to={`/datasets/${datasetId}/charts`}><ChartIcon/></Button>
            <Button style={{marginRight:'8px'}} component={Link}
              label='pos.general.table'
              to={{
                pathname: '/observations',
                search: stringify({
                  page: 1,
                  perPage: 25,
                  filter: JSON.stringify({ datasetId: datasetId }),
                })
              }}><TableIcon/></Button>
              <Button component={Link} label={translate('pos.general.list',{smart_count:2})} to={'/datasets'}><DatasetIcon/></Button>
            </div>
        </div>
        <TimeControlledMap geodata={datasetData} legend={dataset && dataset.name}/>
      </div>
    );
  }
};

const mapStateToProps = (state, props) => ({
  dataset: state.admin.resources.datasets.data[props.match.params.id],
  observations: state.fetchedObservations,
  overlays: state.overlays
});

const enhance = compose(
  translate,
  connect(mapStateToProps, {
    crudGetOne: crudGetOneAction,
    fetchEnd,
    showNotification,
    fetchStart
  })
);

export default enhance(DatasetMap);