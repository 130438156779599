import React from 'react';
import { NumberInput } from 'react-admin';
import compose from 'recompose/compose';
import { translate } from 'ra-core';

export default compose(translate)(({ translate, ...props }) => (
  <NumberInput
    source="identifier"
    label={translate('resources.equipment.fields.identifier')}
  />
));
