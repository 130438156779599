import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  GET_LIST,
  DateField,
  TextField,
  crudGetManyReference as crudGetManyReferenceAction,
  fetchEnd,
  fetchStart,
  nameRelatedTo
} from 'react-admin';

import {
  ResponsiveList
} from '../../components';
import { dataProvider } from '../../providers';

// const prop_order = [
//   'record',
//   'latitude',
//   'longitude',
//   'satellites',
//   'nb_gnss_sat',
//   'height',
//   'payload',
//   'speed',
//   'course',
//   'validity',
//   'hdop',
//   'vdop',
//   'pdop',
//   'sep',
//   'sampleimu',
//   'temperature',
//   'temp_imu',
//   'sampletemperature',
//   'samplepressure',
//   'temp_1',
//   'temp_2',
//   'temp_3',
//   'temp_4',
//   'atm',
//   'Hm0',
//   'Tm01',
//   'Tm02',
//   'Tp',
//   'Mdir',
//   'PkDir',
//   'DirSpr',
//   'PkDirSpr',
//   'WmaxH',
//   'bat',
//   'rssi'
// ];

// const remove_props = [
//   "gpstime", 
//   "timestamp", 
//   "derivedsetId", 
//   "datasetId", 
//   "id", 
//   "position", 
//   "serialNumber", 
//   "properties", 
//   "references", 
//   "year", 
//   "second", 
//   "day", 
//   "hour", 
//   "minute", 
//   "month"
// ];

class EquipObs extends Component {

  constructor(props) {
    super(props)
    this.state = {
      observedProperties: []
    }

    this.fetchObservedProperties()

  }

  fetchObservedProperties() {
    const equipmentId = parseInt(this.props.match.params.id)
    const {fetchEnd, fetchStart} = this.props;

    const observedPropertiesQuery = {
      filter: {
        id: equipmentId
      },
      sort: {
        field: 'id',
        order: 'ASC'
      },
      pagination: {
        page: 0,
        perPage: -1
      },
      fields: ["equipmentmodelId", "id"],
      include: {
        relation: "equipmentmodel",
        scope: {
          fields: ["observedproperties"],
          include: {
            relation: "observedproperties"
          }
        }
      }
    };

    fetchStart();
    dataProvider(GET_LIST, 'equipment', observedPropertiesQuery)
      .then(({ data }) => {
        const observedProperties = data[0].equipmentmodel.observedproperties;
        //Do not change the order of this two lines, for an unknown reason state change needs to happen before fetchEnd
        this.setState({ observedProperties })
        fetchEnd();
      })
      .catch(error => {
        console.error(error)
        fetchEnd();
      })


  }

  componentDidMount() {
    if (!this.props.relatedDatasets.length) {
      const equipmentId = parseInt(this.props.match.params.id)
      this.props.crudGetManyReference('datasets', 'equipmentId', equipmentId, nameRelatedTo('datasets', equipmentId, 'equipment', 'equipmentId'), { page: 1, perPage: 0 }, { sort: { field: 'id', order: 'ASC' } }, {})
    }
  }

  render() {

    const { crudGetManyReference, relatedDatasets, staticContext, fetchEnd, fetchStart, ...props } = this.props;
    const { observedProperties } = this.state;
    const relDtsQuery = relatedDatasets.map(datasetId => ({ datasetId }));

    const filter = {
      or: relDtsQuery
    };

    return !!relDtsQuery.length && <ResponsiveList basePath='/observations' sort={{ field: 'time', order: 'ASC'}} perPage={{ small: 10, medium: 25 }} hasCreate={false} hasEdit={false} hasList={true} hasShow={false} resource='observations' {...props} filter={relDtsQuery.length ? filter : null}>
      <DateField source="timestamp" showTime />
      {
        observedProperties.length > 0 && observedProperties.map((property, index) => {
          return <TextField key={index} source={property.name} sortable={false}/>
        })
      }
    </ResponsiveList>

  }

}

const mapStateToProps = (state, props) => {
  const equipDtsRelLoc = state.admin.references.oneToMany[nameRelatedTo('datasets', parseInt(props.match.params.id), 'equipment', 'equipmentId')];

  return {
    data: state.admin.resources.observations.data,
    relatedDatasets: (equipDtsRelLoc && Object.values(equipDtsRelLoc.ids)) || []
  }
}

export default connect(mapStateToProps, {
  crudGetManyReference: crudGetManyReferenceAction,
  fetchEnd,
  fetchStart
})(EquipObs)