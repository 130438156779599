import { DateTime, Info } from 'luxon';

export default class LuxonUtils {
  parse = DateTime.fromFormat;

  constructor({ locale } = {}) {
    this.locale = locale || 'en';
    this.current_timezone = DateTime.local().toFormat('z');
  }

  date(value, utc = false) {
    if (value instanceof Date) {
      return utc ? DateTime.fromJSDate(value).toUTC() : DateTime.fromJSDate(value).setZone(this.current_timezone);
    }

    if (typeof value === 'string') {
      return utc ? DateTime.fromJSDate(new Date(value)).toUTC() : DateTime.fromJSDate(new Date(value)).setZone(this.current_timezone);
    }

    if (value instanceof DateTime) {
      return utc ? value.toUTC() : value.setZone(this.current_timezone);
    }

    return utc ? DateTime.local().toUTC() : DateTime.local().setZone(this.current_timezone);
  }

  addDays(date, count, utc = false) {
    if (count < 0) {
      return utc ? date.toUTC().minus({ days: Math.abs(count) }) : date.setZone(this.current_timezone).minus({ days: Math.abs(count) });
    }

    return utc ? date.toUTC().plus({ days: count }) : date.setZone(this.current_timezone).plus({ days: count });
  }

  isValid(date) {
    return date.isValid;
  }

  isEqual(value, comparing) {
    if (value === null && comparing === null) {
      return true;
    }

    return value === comparing;
  }

  isSameDay(value, comparing) {
    return value.hasSame(comparing, 'day');
  }

  isAfter(value, comparing) {
    return value > comparing;
  }

  isAfterDay(value, comparing) {
    const diff = value.diff(comparing, 'days').toObject();
    return diff.days > 0;
  }

  isAfterYear(value, comparing) {
    const diff = value.diff(comparing, 'years').toObject();
    return diff.years > 0;
  }

  isBefore(value, comparing) {
    return value < comparing;
  }

  isBeforeDay(value, comparing) {
    const diff = value.diff(comparing, 'days').toObject();
    return diff.days < 0;
  }

  isBeforeYear(value, comparing) {
    const diff = value.diff(comparing, 'years').toObject();
    return diff.years < 0;
  }

  getDiff(value, comparing) {
    if (typeof comparing === 'string') {
      comparing = DateTime.fromJSDate(new Date(comparing));
    }

    return value.diff(comparing, 'milliseconds');
  }

  startOfDay(value, utc = false) {
    return utc ? value.toUTC().startOf('day') : value.startOf('day');
  }

  endOfDay(value, utc = false) {
    return utc ? value.toUTC().endOf('day') : value.endOf('day');
  }

  format(date, format, utc = false) {
    if(utc) {
      return date.toUTC().setLocale(this.locale).toFormat(format);
    }
    else {
      return date.setZone(this.current_timezone).setLocale(this.locale).toFormat(format);
    }
  }

  formatNumber(number) {
    return String(number);
  }

  getHours(value, utc = false) {
    return utc ? value.toUTC().get('hour'): value.setZone(this.current_timezone).get('hour');
  }

  setHours(value, count, utc = false) {
    return utc ? value.toUTC().set({ hour: count }) : value.setZone(this.current_timezone).set({ hour: count });
  }
  
  getMinutes(value, utc = false) {
    return utc ? value.toUTC().get('minute'): value.setZone(this.current_timezone).get('minute');
  }
  
  setMinutes(value, count, utc = false) {
    return utc ? value.toUTC().set({ minute: count }) : value.setZone(this.current_timezone).set({ minute: count });
  }
  
  getSeconds(value) {
    return value.get('second');
  }
  
  setSeconds(value, count) {
    return value.set({ second: count });
  }
  
  getMonth(value, utc = false) {
    // See https://github.com/moment/luxon/blob/master/docs/moment.md#major-functional-differences
    return utc ? value.toUTC().get('month') - 1 : value.setZone(this.current_timezone).get('month') - 1;
  }
  
  getYear(value, utc = false) {
    return utc ? value.toUTC().get('year') : value.setZone(this.current_timezone).get('year');
  }
  
  setYear(value, year, utc = false) {
    return utc ? value.toUTC().set({ year }) : value.setZone(this.current_timezone).set({ year });
  }

  mergeDateAndTime(date, time) {
    return this.setMinutes(this.setHours(date, this.getHours(time)), this.getMinutes(time));
  }

  getStartOfMonth(value, utc = false) {
    return utc ? value.toUTC().startOf('month') : value.setZone(this.current_timezone).startOf('month');
  }

  getNextMonth(value) {
    return value.plus({ months: 1 });
  }

  getPreviousMonth(value) {
    return value.minus({ months: 1 });
  }

  getWeekdays() {
    return Info.weekdaysFormat('narrow', { locale: this.locale });
  }

  getWeekArray(date, utc = false) {
    const { days } = utc ? 
      date
        .toUTC()
        .endOf('month')
        .endOf('week')
        .diff(date.startOf('month').startOf('week'), 'days')
        .toObject() :
      date
        .setZone(this.current_timezone)
        .endOf('month')
        .endOf('week')
        .diff(date.startOf('month').startOf('week'), 'days')
        .toObject();

    const weeks = [];
    new Array(Math.round(days))
      .fill()
      .map((_, i) => i)
      .map(day => date.startOf('month').startOf('week').plus({ days: day }))
      .forEach((v, i) => {
        if (i === 0 || ((i % 7 === 0) && i > 6)) {
          weeks.push([v]);
          return;
        }

        weeks[weeks.length - 1].push(v);
      });

    return weeks;
  }

  getYearRange(start, end) {
    start = this.date(start);
    end = this.date(end).plus({ years: 1 });
    const { years } = end.diff(start, 'years').toObject();
    if (!years || years <= 0) {
      return [];
    }

    return new Array(Math.round(years))
      .fill()
      .map((_m, i) => i)
      .map(year => start.plus({ years: year }));
  }

  getMeridiemText(ampm) {
    return Info
      .meridiems({ locale: this.locale })
      .find(v => v.toLowerCase() === ampm.toLowerCase());
  }

  getCalendarHeaderText(date, utc = false) {
    return this.format(date, 'MMMM yyyy', utc);
  }

  getDatePickerHeaderText(date, utc = false) {
    return this.format(date, 'ccc, MMM d', utc);
  }

  getDateTimePickerHeaderText(date, utc = false) {
    return this.format(date, 'MMM d', utc);
  }

  getDayText(date, utc = false) {
    return this.format(date, 'd', utc);
  }

  getHourText(date, ampm, utc = false) {
    if (ampm) {
      return utc ? date.toUTC().toFormat('hh') : date.setZone(this.current_timezone).toFormat('hh');
    }

    return utc ? date.toUTC().toFormat('HH') : date.setZone(this.current_timezone).toFormat('HH');
  }

  getMinuteText(date, utc = false) {
    return utc ? date.toUTC().toFormat('mm') : date.setZone(this.current_timezone).toFormat('mm');
  }

  getSecondText(date, utc = false) {
    return utc ? date.toUTC().toFormat('ss') : date.setZone(this.current_timezone).toFormat('ss');
  }

  getYearText(date, utc = false) {
    return utc ? date.toUTC().toFormat('yyyy') : date.setZone(this.current_timezone).toFormat('yyyy');
  }

  isNull(date) {
    return date === null;
  }

  dateTime12hFormat = 'ff';

  dateTime24hFormat = 'f';

  time12hFormat = 't';

  time24hFormat = 'T';

  dateFormat = 'DD';
}
