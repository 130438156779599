import React, { Component } from 'react'
import { Button, UPDATE } from 'react-admin'
import { Toolbar } from '@material-ui/core'
import { stringify } from 'query-string';
import { 
  Add as AddIcon,
  Lock as LockIcon
} from '@material-ui/icons';

import { connect } from 'react-redux';
import { refreshView } from 'react-admin';

import { LinkButton } from '../../components';
import { dataProvider } from '../../providers';

class LaunchesToolbar extends Component {
  areLaunchesTerminated(){
    return this.props.record.launches.find((launch) => launch.endTimestamp == null) === undefined;
  }

  onTerminateAllLaunchClick = () => {
    const { resource, record, refreshView } = this.props;
    dataProvider(UPDATE, resource, { id: `${record.id}/launches/close` })
      .then(_ => {
        refreshView();
      })
  }

  render(){
    return <Toolbar>
      <LinkButton variant='raised' label='resources.launches.buttons.create' 
        to={{
          pathname:'/launches/create',
          search:stringify({
            campaignId: this.props.record.id
          })
        }}
        hasIcon
        icon={<AddIcon/>}
      />
      <Button variant='raised' label='resources.launches.buttons.terminateAll' style={{marginLeft:'2em'}} onClick={this.onTerminateAllLaunchClick}><LockIcon/></Button>
    </Toolbar>
  }
}

export default connect(null, { refreshView })(LaunchesToolbar)