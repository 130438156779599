import React, { Component } from 'react';
import { Control } from 'react-leaflet-control-inesc';
import Button from '@material-ui/core/Button';
import { ViewList } from '@material-ui/icons';
import {
  withStyles,
} from '@material-ui/core';

import compose from 'recompose/compose';

import AnnotationsDialog from './AnnotationsDialog';

var styles =  {
    actionButton: {
      padding: 0,
      minHeight: 0,
      minWidth: 0,
      width: '30px',
      height: '30px'
    }
  }

class toMap extends Component {
  state = {
    inserted_annotations: 0,
    open: false
  };

  insertedAnnotations = () => {
    this.setState({ inserted_annotations: this.state.inserted_annotations + 1 });
  }

  closedModal = () => {
    this.setState({ open: false });
    this.props.modalClose();
  }

  componentDidUpdate = (oldProps) => {
    const {annotationIdDialog } = this.props;
  
    if(annotationIdDialog && oldProps.annotationIdDialog !== annotationIdDialog) {
      this.setState({ open: true });
    }
  }

  render(){
    const { classes,recordId, tagAnnotationDialog, annotationIdDialog } = this.props;

    return <div>
      <Control position='topright'>
      <div style={{ backgroundColor: 'white' }}>
        <Button classes={{ root: classes.actionButton }} onClick={() => { this.setState({ open: true }); this.insertedAnnotations(); }}>
          <ViewList style={{ fontSize: '15px', padding: '8px', color: 'black' }} />
        </Button>
        
        <AnnotationsDialog tagAnnotationDialog={tagAnnotationDialog} annotationIdDialog={annotationIdDialog} modalOpen={this.state.open} modalClose={this.closedModal} recordId={recordId} inserted_annotations={this.state.inserted_annotations}/>
      </div>
    </Control>
    </div>
  }
};

const enhance = compose(
  withStyles(styles)
)

export default enhance(toMap);