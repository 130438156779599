import React, { Component } from 'react';
import { Button, withStyles } from '@material-ui/core';
import { 
  GpsFixed,
  GpsNotFixed,
  GpsOff
} from '@material-ui/icons';

import { Control } from 'react-leaflet-control-inesc';
import L from 'leaflet';
import '../map/location.css';

const styles = {
  button: {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    width: '30px',
    height:'30px',
  }
}

class LocationControl extends Component {
  state = {
    marker: null,
    geoId: null,
    status: 0
  };

  updateMarker = (position) => {
    const { marker } = this.state;
    const { map } = this.props;
    const { latitude, longitude, accuracy } = position.coords;

    if (!marker) {
      const innerCircle = L.marker([latitude, longitude], {
        icon: L.divIcon({className: 'location-icon'})
      });
  
      const circle = L.circle([latitude, longitude], {
        opacity: 0.4,
        fillOpacity: .1,
        weight: 1,
        radius: accuracy
      });
  
      const pin = L.layerGroup([innerCircle, circle]).addTo(map);
      map.setView(new L.LatLng(latitude, longitude), 18);
      this.setState({marker: pin, status: 1});
    } else {
      marker.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          layer.setLatLng([latitude, longitude]);
        } else if (layer instanceof L.Circle) {
          layer.setLatLng([latitude, longitude]);
          layer.setRadius(accuracy);
        }
      });
    }
  };

  currentLocation = () => {
    const { marker, geoId } = this.state;
    const { map } = this.props;
    if(!marker) {
      const geoId = navigator.geolocation.watchPosition(position => {
          this.updateMarker(position);
        }, _ => { 
          this.setState({status: -1});
        }, {
          enableHighAccuracy: false,
        });
  
      this.setState({geoId: geoId});
    } else {
      navigator.geolocation.clearWatch(geoId);
      map.removeLayer(marker);
      this.setState({marker: null, geoId: null, status: 0});
    }
  };

  render() {
    const {classes, ...props} = this.props
    const {status} = this.state;

    let button;
    switch (status) {
      case 0:
        button = <GpsFixed style={{fontSize:'15px', padding:'8px', color:'black'}}/>;
        break;
      case 1:
        button = <GpsNotFixed style={{fontSize:'15px', padding:'8px', color:'black'}}/>;
        break;
      default:
        button = <GpsOff style={{fontSize:'15px', padding:'8px', color:'black'}}/>;
        break;
    }

    return (
      <Control position="topleft" className="leaflet-control-zoom leaflet-bar leaflet-control">
        <div style={{background:'white'}}>
          <Button 
            {...props}
            classes={{root:classes.button}}
            onClick={this.currentLocation}
          >
            {button}
          </Button>
        </div>
      </Control>
    );
  }
}

export default withStyles(styles)(LocationControl);