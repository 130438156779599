import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  BooleanField,
  Create,
  // DateField,
  ListButton,
  Pagination,
  Show,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  BooleanInput,
  crudGetOne,
  required,
  translate,
  fetchStart,
  fetchEnd,
  EditButton,
  showNotification,
} from 'react-admin';

import moment from 'moment';
import TabbedShowLayoutTabs from '../../utils/CustomTabbedShowLayoutTabs';
import {
  ReferenceManyManyField,
  Edit,
  Relation,
  RelationShow,
  ResponsiveDatagrid,
  LocalSearch,
  DateTimeInput,
  DateField,
  BuildButton,
} from '../../components';
import compose from 'recompose/compose';
import {
  Typography,
  withStyles
} from '@material-ui/core';
import { parse } from 'query-string';

import LaunchesToolbar from './LaunchesToolbar';
import { Delete } from '@material-ui/icons';
import { dataFetch } from '../../providers';
import { refreshView } from 'ra-core';
// import DateFieldCustom from './CustomDateField'
const styles = {
  tabs: {
    width: 0
  }
};

const redirect = (basePath, id, data) => {
  return `/campaigns/${data.campaignId}/show/launches`;
}

const validateCreate = (values, { translate }) => {
  const errors = {};
  const beginTimestamp = moment(values.beginTimestamp);

  if (!beginTimestamp.isValid()) {
    errors.beginTimestamp = [translate('ra.validation.required')];
  }

  return errors;
};

const validateEdit = (values, { translate }) => {
  const errors = {};
  const beginTimestamp = moment(values.beginTimestamp);
  // const endTimestamp = moment(values.endTimestamp);

  if (!beginTimestamp.isValid()) {
    errors.beginTimestamp = [translate('ra.validation.required')];
  }
  //else if (endTimestamp.isValid && !endTimestamp.isAfter(beginTimestamp)) {
  //   errors.endTimestamp = [translate('error.END_TIMESTAMP_EARLIER')];
  // }

  return errors;
};

const LaunchActions = ({ campaign, title, ...props }) => {
  return <Fragment>
    <Typography variant='title'>{title}</Typography>
  </Fragment>
}

class LaunchesCreateView extends Component {

  componentDidMount() {
    if (!this.props.campaign) {
      const search = parse(this.props.location.search, { arrayFormat: 'bracket' })

      this.props.crudGetOne('campaigns', search.campaignId, '/campaigns');
    }
  }

  render() {
    const { campaign, crudGetOne, translate, ...props } = this.props;

    return <Create actions={<LaunchActions title={campaign && campaign.name} />} {...props}>
      <SimpleForm redirect={redirect} validate={validateCreate}>
        <TextInput source='name' validate={required()} />
        <TextInput source='description' />
        <DateTimeInput
          validate={required()}
          source='beginTimestamp'
          label={translate('resources.launches.fields.beginTimestamp')}
          options={{
            format: 'dd/MM/yyyy HH:mm',
            clearable: true,
            selectTimezone: true
          }}
        />
        <DateTimeInput
          source='endTimestamp'
          label={translate('resources.launches.fields.endTimestamp')}
          options={{
            format: 'dd/MM/yyyy HH:mm',
            clearable: true,
            selectTimezone: true
          }}
        />
        <BooleanInput source="public" {...props}/>
        {/* <BooleanInput source="autoPublish" {...props}/> */}
      </SimpleForm>
    </Create>
  }
}

class EditActionsClass extends Component {
  state = {}

  static getDerivedStateFromProps(props) {

    if (props.data && !props.campaign) {
      props.crudGetOne('campaigns', props.data.campaignId, '/launches')
    }

    return null
  }

  render() {
    return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant='title'>{this.props.campaign && this.props.campaign.name}</Typography>
      {this.props.data && <ListButton to={`/campaigns/${this.props.data.campaignId}/show/launches`} />}
    </div>
  }
}

const mapStateToEditActionProps = (state, props) => {
  return { campaign: props.data ? state.admin.resources.campaigns.data[props.data.campaignId] : null }
}

const EditActions = connect(mapStateToEditActionProps, { crudGetOne })(EditActionsClass);

const CustomSimpleForm = ({ record, redirect, ...props }) => (
  <SimpleForm {...props} record={record} redirect={`/campaigns/${record.campaignId}/show/launches`}>
    {props.children}
  </SimpleForm>
)

class LaunchesEditView extends Component {

  render() {
    const { campaign, translate, ...props } = this.props;
    return <Edit actions={<EditActions />} {...props} >
      <CustomSimpleForm redirect={redirect} validate={validateEdit} toolbar={<LaunchesToolbar />}>
        <TextInput source='name' validate={required()} />
        <TextInput source='description' />
        <DateTimeInput
          validate={required()}
          source='beginTimestamp'
          label={translate('resources.launches.fields.beginTimestamp')}
          options={{
            utc: true,
            format: 'dd/MM/yyyy HH:mm',
            clearable: true,
            selectTimezone: true
            ,
          }}
        />
        <DateTimeInput
          source='endTimestamp'
          label={translate('resources.launches.fields.endTimestamp')}
          options={{
            format: 'dd/MM/yyyy HH:mm',
            clearable: true,
            selectTimezone: true
          }}
        />
        <BooleanInput source="public" {...props}/>
        {/* <BooleanInput source="autoPublish" {...props}/> */}
      </CustomSimpleForm>
    </Edit>
  }
}

class ShowActionsClass extends Component {

  state = {}

  static getDerivedStateFromProps(props) {

    if (props.data && !props.campaign) {
      props.crudGetOne('campaigns', props.data.campaignId, '/launches')
    }

    return null
  }

  render() {
    const { campaign, data, basePath } = this.props;
    return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        {data && <Typography variant='title'>{data.name}</Typography>}
        {campaign && <Typography variant='caption'>{campaign.name}</Typography>}
      </div>
      <div style={{ right: 0 }}>
        {data && <EditButton record={{ id: data.id }} basePath={basePath} />}
        {data && <BuildButton record={{ id: data.id }} recordId="id" />}
        {data && <ListButton to={`/campaigns/${data.campaignId}/show/launches`} />}
      </div>
    </div>
  }
}

const mapShowPropsToState = (state, props) => {
  return {
    campaign: props.data ?
      state.admin.resources.campaigns.data[props.data.campaignId] ? state.admin.resources.campaigns.data[props.data.campaignId] : undefined
      : undefined
  }
}

const ShowActions = connect(mapShowPropsToState, { crudGetOne })(ShowActionsClass);

const dialogContent = (props) => {
  return <ReferenceManyManyField recordId={"campaignId"} reference="equipment" limit={0} prefix="campaigns" basePath="launches" addLabel={false} params={{
    include: {
      relation: "launches",
      scope: {
        where: {
          endTimestamp: null
        }
      }
    }
  }}>
    <Relation basePath='launches'
      recordId={props.id}
      referencePath="/equipment"
      pagination={<Pagination />}
      reference={`/launches/${props.id}/equipment`}
      changeIdOnRequest={'id'}
      listFilters={<LocalSearch filter="serialNumber" label={props.translate("resources.equipment.fields.serialNumber")} />}
      manyField={true}
    >
      <ResponsiveDatagrid>
        <TextField source='name' />
        <TextField source='number' />
        <TextField source='serialNumber' />
        <BooleanField source='import' />
      </ResponsiveDatagrid>
    </Relation>
  </ReferenceManyManyField>
}

class LaunchesShowView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      version: 0,
      selectedIds: [],
    };

    this.selectOne = this.selectOne.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.deleteMultiple = this.deleteMultiple.bind(this);
  }

  deleteMultiple() {//ver para apagar notas
    const { selectedIds } = this.state;

    if (selectedIds.length > 0) {
      const launchId = this.props.id;

      const { showNotification, refreshView } = this.props;
      const requests = [];
      selectedIds.forEach(id => {
        requests.push(dataFetch('DELETE', `/launches/${launchId}/equipment/rel/${id}`));
      });

      Promise.all(requests)
        .then(values => {
          showNotification('resources.equipment.removed_eq');
          this.setState({ selectedIds: [], version: this.state.version + 1 })
        })
        .catch(({ error }) => {
          if (error && error.name === 'PUBLISHED')
            showNotification('resources.equipment.dataset_published', 'warning');
          else
            showNotification('errors.SOMETHING', 'warning');
        })
        .finally(_ => {
          refreshView();
        })
    }
  }

  selectOne(e) {
    let { selectedIds } = this.state;
    const index = selectedIds.indexOf(e);
    if (index === -1)
      selectedIds.push(e)
    else
      selectedIds.splice(index, 1);

    this.setState({ selectedIds: selectedIds, version: this.state.version + 1 })
  }

  selectAll(e) {
    this.setState((state) => ({ selectedIds: [...e], version: state.version + 1 }))
  }


  render() {
    const { classes, translate, fetchStart, fetchEnd, showNotification, crudDeleteMany, refreshView, ...props } = this.props;
    const { version, selectedIds } = this.state;
    return (
      <Show {...props} actions={<ShowActions />}>
        <TabbedShowLayout tabs={<TabbedShowLayoutTabs classes={{ scrollable: classes.tabs }} scrollable scrollButtons={'on'} />}>
          <Tab label='resources.launches.tabs.details'>
            <SimpleShowLayout>
              <TextField source="name" />
              <TextField source="description" />
              <DateField source="beginTimestamp" format='dd/MM/yyyy HH:mm' localDate />
              <DateField source="endTimestamp" format='dd/MM/yyyy HH:mm' localDate />
              <BooleanField source="public" />
              {/* <BooleanField source="autoPublish" /> */}
            </SimpleShowLayout>
          </Tab>
          <Tab label='resources.launches.tabs.equipment'>
            <ReferenceManyManyField addLabel={false} reference='equipment' pagination={<Pagination />}>
              <RelationShow reference={'equipment'} resource={'launch'}
                dialogContent={dialogContent({ translate, ...props })}
                secondPress={selectedIds.length > 0 ? this.deleteMultiple : undefined}
                Icon={Delete}
                secondLabel={translate('pos.general.remove_button')}
              >
                <ResponsiveDatagrid version={version} hasBulkActions={true} selectedIds={selectedIds} onToggleItem={this.selectOne} onSelect={this.selectAll} /*{...props}*/>
                  <TextField source='name' />
                  <TextField source='number' />
                  <TextField source='serialNumber' />
                  <BooleanField source='import' />
                </ResponsiveDatagrid>
              </RelationShow>
            </ReferenceManyManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}

const enhanceLaunchesShow = compose(
  translate,
  withStyles(styles),
  connect(null, {
    showNotification,
    fetchEnd,
    fetchStart,
    refreshView,
  })
)

const mapStateToProps = (state, props) => {
  const search = parse(props.location.search, { arrayFormat: 'bracket' })
  return {
    campaign: state.admin.resources.campaigns.data[search.campaignId]
  }
}

const enhanceCreateEdit = compose(
  translate,
  connect(mapStateToProps, {
    crudGetOne
  })
)

export const LaunchesShow = enhanceLaunchesShow(LaunchesShowView);
export const LaunchesCreate = enhanceCreateEdit(LaunchesCreateView);
export const LaunchesEdit = enhanceCreateEdit(LaunchesEditView);
// export const LaunchesShowView = enhanceCampaignShow();