import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  GET_LIST,
  fetchEnd,
  fetchStart
} from 'react-admin';

import { dataProvider } from '../../providers';

class RefModelProp extends Component {

  state={
    data: {},
    ids: [],
    sort: {
      field: 'index',
      order: 'ASC'
    },
    page: 1,
    perPage: -1,
    total: 0
  }

  componentDidMount(){
    const { fetchEnd, fetchStart, record } = this.props;
    const { page, perPage, sort } = this.state;

    fetchStart();
    dataProvider(GET_LIST, 'modelproperties', {
      filter: {
        equipmentmodelId: record.id
      },
      sort,
      pagination:{
        page,
        perPage
      }
    })
    .then(resp => {
      resp.data.splice(0, 0, {
        name: "EquipmentId",
        unitOfMeasurement: 'N/A',
        visible: true,
        index: 0,
        id: -1
      })

      const data = resp.data.reduce((acc, property, index) => {
        acc[index] = property
        return acc;
      }, {});

      this.setState({ data, total: Object.keys(data).length })
      fetchEnd();
    })
    .catch(_ => {
      fetchEnd();
    })
  }

  render(){
    const { data, sort } = this.state;
    return React.cloneElement(this.props.children, {data, currentSort: sort, ids: Object.keys(data)} )
  }

}

export default connect(null, {
  fetchEnd,
  fetchStart
})(RefModelProp)