import { downloadCSV } from 'react-admin'
import { unparse as convertToCSV } from 'papaparse/papaparse.min'
import * as moment from 'moment'

export const datasetExporter = (name) => {
  return (records, fetchRelatedRecords) => {
    if (records.length > 0) {
      const removeProperties = ['id', 'timestamp', 'createdAt', 'updatedAt', 'annotationsCount', 'datasetId', 'position'];
      const fields = Object.keys(records[0]).filter(element => {
        return !removeProperties.includes(element);
      });

      const data = records.map(record => {
        return record;
      });

      downloadCSV(convertToCSV({ data, fields }), name);
    }
  };
};

export const exporter = (relatedModelsObj = {}, exportName) => {
  
  return (data, fetchRelated, dispatch) => {
    const entries = Object.entries(relatedModelsObj)
    if(entries.length > 0 ){
      //Fetch the first related model
      fetchRelated(data, entries[0][0], entries[0][1])
      .then(dataWithRelation => {

        //For each data item add a new entry with name of related model and deletes entry with id
        data.forEach(item => {
          item[entries[0][0].match(/\S[\w-]+(?=-?id)/i)] = dataWithRelation[item[entries[0][0]]].name
          delete item[entries[0][0]]
        })

        //Removes de first related model
        delete relatedModelsObj[entries[0][0]]

        exporter(relatedModelsObj, exportName)(data, fetchRelated, dispatch)
      })
      
    }else{
      const exportData = data.map( item => {
        //Remove irrelevant data for CSV
        const { createdAt, updatedAt, ...relevantItem } = item
        
        //Fetch indexes of date or time strings
        const dateOrTimeIndexes = []
        const itemKeys = Object.keys(item)
        itemKeys.forEach((key, index) => {
          if(isKeyFromDateOrTimeString(key)) dateOrTimeIndexes.push(index)
        })

        //Iterates over found indexes and converts item value to simplified date or time string
        dateOrTimeIndexes.forEach((index) => {
          //Checks if value of date or time string is not null
          if( relevantItem[itemKeys[index]] ){
            const date = moment(relevantItem[itemKeys[index]])
            if( isKeyFromDateString(itemKeys[index]) ){
              relevantItem[itemKeys[index]] = getMomentDateString(date)
            }else{
              relevantItem[itemKeys[index]] = `${getMomentDateString(date)} ${getMomentTimeString(date)}`
            }
          }
        })

        Object.entries(relevantItem).forEach(entry => {
          //Sends properties of nested obj to root and deletes the nested object property
          boilerUpNestedObjToRoot(entry, relevantItem, '')  
        }) 
    
        return relevantItem
      }) 
    
      const csv = convertToCSV({
        data: exportData,
        fields: Object.keys(exportData[0]),
      })

      downloadCSV(csv, exportName);

    }
  }
}

const boilerUpNestedObjToRoot = (entry, root, cumulativeName) => {
  const nestedName = cumulativeName.length > 0 ? `${cumulativeName}.${entry[0]}` : entry[0]
  if( entry[1] instanceof Object){
    Object.entries(entry[1]).forEach(entry => {boilerUpNestedObjToRoot(entry, root, nestedName)})
    if(root[entry[0]]) delete root[entry[0]]

  }
  else{
    if(!root[nestedName]){
      root[nestedName] = entry[1]
    }
  }
}

const isKeyFromDateOrTimeString = (value) => (
  value.includes('date') || value.includes('timestamp')
)

const isKeyFromDateString = (value) => (
  value.includes('date')
)

const getMomentDateString = date => ( date.utc().format('DD-MM-YYYY') )
const getMomentTimeString = time => ( time.utc().format('HH:mm:ss') )

export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)})` : hex;
}

export const rgbToHex = x => {
  if(x) return '#' + x.match(/\d+/g).map(z => ((+z < 16)?'0':'') + (+z).toString(16)).join('');
}