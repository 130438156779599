export const YEAR = 'year';

export const DATE = 'date';

export const HOUR = 'hours';

export const MINUTES = 'minutes';

export const SECONDS = 'seconds';

