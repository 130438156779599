import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset as resetFormAction } from 'redux-form';
import compose from 'recompose/compose';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  translate,
  Labeled,
  TextField,
  DeleteWithConfirmButton,
} from 'react-admin';
import {
  Dialog, IconButton,
  DialogTitle,
  DialogContent,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { Close as CloseIcon } from '@material-ui/icons'
import PropTypes from 'prop-types';
import EditToolbar from './EditToolbar';
import { DateField } from '../';

const styles = function styles(theme) {

  var height = 32;
  var backgroundColor = theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700];
  // var deleteIconColor = (0, _colorManipulator.fade)(theme.palette.text.primary, 0.26);
  return {
    /* Styles applied to the root element. */
    tag_root: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(14),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: height,
      color: theme.palette.getContrastText(backgroundColor),
      backgroundColor: backgroundColor,
      borderRadius: height / 2,
      whiteSpace: 'nowrap',
      transition: theme.transitions.create(['background-color', 'box-shadow']),
      // label will inherit this from root, then `clickable` class overrides this for both
      cursor: 'default',
      // We disable the focus ring for mouse, touch and keyboard users.
      outline: 'none',
      textDecoration: 'none',
      border: 'none',
      position: 'relative',
      top: '15px',
      // left: '24px',
      // Remove `button` border
      padding: 0, // Remove `button` padding,
      float: 'left',

    },
    root: {
      minWidth: 300
    },
    tag_label: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 12,
      paddingRight: 12,
      userSelect: 'none',
      whiteSpace: 'nowrap',
      cursor: 'inherit'
    },
    content: { padding: 0 },
    titleDiv: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    closeButton: {
      marginTop: '0.5em',
      marginRight: '0.5em'
    },
    TextField: {
      maxWidth: "80%",
      display: 'flex',
      margin: '0 auto'
    },

    times: {
      paddingLeft: "24px",
      paddingRight: "24px"
    },
    table: {
      maxWidth: 475

    },
    cell: {
      padding: '4px 56px 4px 2px',
      backgroundColor: 'green'
    },
    overrides: {
      MuiTableCell: {
        root: {
          padding: '4px 56px 4px 2px',
          backgroundColor: 'green'
        }
      }
    },
    cell2: {
      padding: '4px 56px 4px 2px',
      backgroundColor: 'pink'
    }
  }
};

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        // paddingLeft: 1,
        padding: '0px 30px 0px 0px',
        "&:last-child": {
          paddingRight: 0
        }
      }
    }
  }
});

const sanitizeRestProps = ({
  basePath,
  classes,
  crudDelete,
  filterValues,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  label,
  pristine,
  resource,
  saving,
  selectedIds,
  submitOnEnter,
  redirect,
  refreshView,
  showNotification,
  push,
  hasList,
  hasCreate,
  hasShow,
  hasEdit,
  translate,
  openEdit,
  fetchEnd,
  fetchStart,
  reset,
  refreshData,
  ...rest
}) => rest;

class DetailsShow extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    this.props.reset('annotation');
  }
  
  render() {
    const { translate, onClose, fullWidth, open, classes, record, openEdit, refreshData } = this.props

    let key = 0;
    return (
      <Dialog open={open} onClose={onClose} fullWidth={fullWidth}>
        <span className={classes.titleDiv}>
          <DialogTitle >
            {translate('containers.annotations.modalTitle')}
          </DialogTitle>

          <IconButton classes={{ root: classes.closeButton }} onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </span>
        <DialogContent onClick={this.handleCLick} className={classes.root}>

          {record.interval ?
            <div className={classes.times}>
              {/* <DateField source='beginTime' format='dd/MM/yyyy HH:mm:ss' label='beginTime' {...sanitizeRestProps(this.props)}/> */}

              <Labeled label={'containers.annotations.begin'}>
                <DateField source="beginTime" format='dd/MM/yyyy HH:mm:ss' record={record} />
              </Labeled>

              <br />
              <Labeled label={'containers.annotations.end'}>
                <DateField source="endTime" format='dd/MM/yyyy HH:mm:ss' record={record} />
              </Labeled>

              <br />

              <Labeled label={'containers.annotations.description'}>
                <TextField
                  {...sanitizeRestProps(this.props)}
                  source='text'
                />
              </Labeled>
              <br />

              <Labeled label={'containers.annotations.tags'}>
                <span>
                  {record.tags && record.tags.map(tag => {
                    return (
                      <span className={classes.tag_root} key={key++}>
                        <span className={classes.tag_label}> {tag.name} </span>
                      </ span>
                    )
                  })}
                </span>
              </Labeled>
            </div>

            : // single
            <div className={classes.times}>
              <Labeled label={'containers.annotations.description'}>
                <TextField
                  {...sanitizeRestProps(this.props)}
                  source='text'
                />
              </Labeled>
              <br />

              <Labeled label={'containers.annotations.tags'}>
                <span>
                  {record.tags && record.tags.map(tag => {
                    return (
                      <span className={classes.tag_root} key={key++}>
                        <span className={classes.tag_label}> {tag.name} </span>
                      </ span>
                    )
                  })}
                </span>
              </Labeled>

              <MuiThemeProvider theme={theme}>

                <Table className={classes.table}>
                  <TableHead>
                    <TableRow >
                      <TableCell >{translate('containers.annotations.time')}</TableCell>
                      <TableCell>{translate('containers.annotations.record')}</TableCell>
                      <TableCell>{translate('ra.action.delete')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {record.intervals.map((single, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell > <DateField source={`beginTime`} format='dd/MM/yyyy HH:mm:ss' record={single} /></TableCell>
                          <TableCell>
                            <TextField
                              {...sanitizeRestProps(this.props)}
                              source={`intervals[${index}].record`}
                              record={record}
                            />
                          </TableCell>
                          <TableCell>
                            <DeleteWithConfirmButton
                              className={classes.deleteButton}
                              resource={`annotations/${record.id}/intervals`}
                              source="intervals"
                              record={{ id: single.id }}
                              label=""
                              redirect={() => refreshData()}
                            />
                          </TableCell>

                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </MuiThemeProvider>

            </div>
          }

        </DialogContent>

        <EditToolbar editing={() => openEdit()} edit />

      </Dialog>
    )
  }
}

DetailsShow.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

const enhance = compose(
  translate,
  withStyles(styles),
  connect(null, {
    fetchStart,
    fetchEnd,
    showNotification,
    reset: resetFormAction,

  })
);

export default enhance(DetailsShow);