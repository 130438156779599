import React, { Component } from 'react';
import {
  EditController,
  EditView,
  Filter,
  TextField,
  Create,
  SimpleForm,
  ImageInput,
  ImageField,
  NumberInput,
  TextInput,
  SearchInput,
  ShowController,
  ShowView,
  ShowButton,
  SimpleShowLayout,
  required,
  translate,
  TabbedShowLayout,
  Tab,
  showNotification,
  EditButton,
  ListButton,
  // crudCreate as crudCreateAction, 
  SelectInput
} from 'react-admin';

import {
  DefaultCardActions,
  GeoCoordinatesField,
  GeoCoordinatesInput,
  ResponsiveList
} from '../../components';
import dataFetch from '../../providers/dataFetch';

import Icon from '@material-ui/icons/Landscape';
// import withStyles from '@material-ui/core/styles/withStyles';
import { storage } from 'react-admin-loopback';
import { URL } from '../../Configuration';
import TabbedShowLayoutTabs from '../../utils/CustomTabbedShowLayoutTabs';
import compose from 'recompose/compose';
import { connect } from 'react-redux'

import { Typography, withStyles } from '@material-ui/core';
export const OverlayIcon = Icon;

const listStyles = {

};

const OverlayFilter = translate(({translate, ...props}) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn label={translate('pos.search')} />
  </Filter>
));

export const OverlaysList = withStyles(listStyles)( ({...props}) => {
  return <ResponsiveList
    {...props}
    listFilters={<OverlayFilter />}
    recordActions={[<ShowButton />]}
  >
    <TextField source="name" />
    <TextField source="opacity" />
    <GeoCoordinatesField source="northEast" />
    <GeoCoordinatesField source="southWest" />
  </ResponsiveList>
});

class OverlaysSimpleForm extends Component {
  state={
    isImage: false,
    
    selected: 0
  }

  choices = [
    {id: 0, name: "resources.overlays.fields.image", type: "image"},
    {id: 1, name: "resources.overlays.fields.kml", type: "kml"},
    {id: 2, name: "resources.overlays.fields.wms", type: "wms"}
  ]

  change = (_, selected) => this.setState({selected: selected});

  submitUrl = (history) => ( overlay ) => {
    overlay.overlayType = this.choices[overlay.type].type;
    overlay.remote = true;

    if(overlay.type !== 1 && (!overlay.opacity || (overlay.opacity && isNaN(overlay.opacity))))
      overlay.opacity = 1;

    this.fetchData(new Headers({
      Accept: 'application/json',
      "Content-Type": "application/json"
    }), JSON.stringify(overlay), history);
  }

  submitFile = (history) => ( overlay ) => {
    
    const formData = new FormData()
    formData.append('name', overlay.name)
    formData.append('opacity', overlay.opacity)
    formData.append('southWest', JSON.stringify(overlay.southWest))
    formData.append('northEast', JSON.stringify(overlay.northEast))
    formData.append('file', overlay.overlays.rawFile)

    this.fetchData(new Headers({
      Accept: 'application/json',
    }), formData, history);
  };


  fetchData = (headers, data, history) => {
    dataFetch('POST', '/overlays', headers, data)
    .then(_ => {
      this.props.showNotification('inputs.general.create')
      history.push('/overlays')

    }).catch(e => {
      console.log("ERROR", e);
      this.props.showNotification('errors.SOMETHING')
    })
  }

  render(){
    const {save, history, crudCreate, showNotification, ...props} = this.props
    return(
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs scrollable scrollButtons={'on'}/>} {...props} >
        <Tab label='resources.overlays.file' style={{display:'flex', flexDirection:'row'}}>
          <SimpleForm save={this.submitFile(history)} redirect='show' >

            <ImageInput source='overlays' accept='' validate={required()}options={{onDropAccepted: (files)=>{
              this.setState({isImage:files[0].type.includes('image')})}
            }}>
              <ImageField source='file' title='fileName'/>
            </ImageInput>
            <TextInput source='name' validate={required()}/>
            {this.state.isImage ? 
              [<NumberInput key='1' source='opacity' defaultValue="1" validate={required()}/>,
              <GeoCoordinatesInput key='2' source='northEast' />,
              <GeoCoordinatesInput key='3' source='southWest' />]
            : null}

          </SimpleForm>
        </Tab>
        
        <Tab label='resources.overlays.url' path='url'>
          <SimpleForm save={this.submitUrl(history)} redirect='show' >

            <SelectInput source='type' defaultValue={0} choices={this.choices} translateChoice={true} onChange={this.change}/>
            <TextInput source='name' validate={required()}/>
            <TextInput source='filePath' validate={required()}/>

            { // image
              this.state.selected === 0 && [
                <NumberInput key='1' source='opacity' defaultValue="1" validate={required()}/>,
                <GeoCoordinatesInput key='2' source='northEast' />,
                <GeoCoordinatesInput key='3' source='southWest' />
              ]
            }

            { // wms
              this.state.selected === 2 && [
                <NumberInput source='opacity' key="1" defaultValue="1" validate={required()}/>,
                <TextInput source='layers' key="1.2" validate={required()}/>
              ]
            }
          </SimpleForm>
        </Tab>
      </TabbedShowLayout>
    )
  }
};

export const OverlaysCreate = compose(
  translate, 
  withStyles(listStyles),
  connect(null, {
    showNotification,})
    )( ({showNotification, ...props}) => (
  <Create actions={<DefaultCardActions/>} {...props}>
    <OverlaysSimpleForm showNotification={showNotification} history={props.history} />
  </Create>
));

const CustomImageField = ({record}) => {
  const recordComplete = record
  if(!record.remote && record.overlayType.includes('image')) {
    recordComplete.url = `${URL}${record.filePath}?access_token=${storage.load('lbtoken').id}`
  }
  else if(record.remote && record.overlayType.includes('image')) {
    recordComplete.url = record.filePath;
  }
  else {
    recordComplete.url = `/kml.png`
  }
  return <ImageField source='url' className='imageField' record={recordComplete} />
};

const OverlaysActions = ({data, basePath, ...props}) => {
  return <div style={{display:'flex', justifyContent:'space-between'}}>
    <div>
      {data && <Typography variant='title'>{data.name}</Typography>}
      <Typography variant='caption'> </Typography>
    </div>
    <div style={{right: 0}}>
      { data && <EditButton record={{ id: data.id }} basePath={basePath}/> }
      { data && <ListButton basePath={basePath} /> }
    </div>
  </div>
}


export const OverlaysShow = withStyles(listStyles)( ({...props}) => {
  console.log(props)
  return <ShowController {...props}>
    {
      controllerProps => 
      <ShowView actions={<OverlaysActions/>} {...props} {...controllerProps}>
        <SimpleShowLayout>
          <TextField source='name' />
          { controllerProps.record && controllerProps.record.remote && <TextField source='filePath' /> }

          {controllerProps.record && controllerProps.record.overlayType.includes('image') && 
            [ <TextField key={1} source='opacity' />, 
              <GeoCoordinatesField key={2} source="northEast" />,
              <GeoCoordinatesField key={3} source="southWest" />,
              <CustomImageField key={4} source='filePath' /> 
          ]}

          { controllerProps.record && controllerProps.record.remote && controllerProps.record.overlayType === 'wms' && 
            [ <TextField key={1} source='opacity' />,
              <TextField key={2} source='layers' />
          ]}
           
        </SimpleShowLayout>
      </ShowView>
    }
  </ShowController>
});

export const OverlayEdit = withStyles(listStyles)( ({...props}) => {
  return <EditController undoable={false} {...props}>
    {
      controllerProps => 
      <EditView {...props} {...controllerProps}>
        <SimpleForm>
          <TextInput source='name' validate={required()}/>
          { controllerProps.record && controllerProps.record.remote && <TextInput source='filePath' />}
          { controllerProps.record && controllerProps.record.overlayType.includes('image') && 
            [ <NumberInput key="1" source='opacity' defaultValue="1" validate={required()}/>,
              <GeoCoordinatesInput key="1.2" source='northEast' />,
              <GeoCoordinatesInput key="1.3" source='southWest' />
          ]}

          { controllerProps.record && controllerProps.record.remote && controllerProps.record.overlayType === 'wms' &&
            [ <NumberInput source='opacity' key="1" defaultValue="1" validate={required()}/>,
              <TextInput source='layers' key="2.1" validate={required()}/>
          ]}
        </SimpleForm>
      </EditView>
    }
  </EditController>
})