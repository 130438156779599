import React from 'react'
import { List } from '@material-ui/core'

import AccordionItem from './AccordionItem'

export default ({data, ids }) => (
  <List>
    {
      ids.map((id, index) => (<AccordionItem key={index} record={data[id]} />)) 
    }
  </List>
)