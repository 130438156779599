import React, { Component } from 'react';
import {
  Title,
  translate,
  CardActions,
  Button,
} from 'react-admin';

import {
  
  Card,
  CardContent
} from '@material-ui/core'

import { storage as localStorage } from 'react-admin-loopback';
import EditIcon from '@material-ui/icons/Create';
import ShowIcon from '@material-ui/icons/RemoveRedEye';
import PasswordIcon from '@material-ui/icons/VpnKey';

import ProfileForm from './ProfileForm';
import ProfileShow from './ProfileShow';
import PasswordForm from './PasswordForm';

class Profile extends Component {
  state = {
    user: {},
    disableForm: true,
    passwordForm: false
  };

  componentWillMount() {
    this.storageToState();
  }

  storageToState = () => {
    if (localStorage.load('lbtoken').user) {
      this.setState({
        user: localStorage.load('lbtoken').user
      });
    }
  }

  updateStorage = (user) => {
    const lbtoken = localStorage.load('lbtoken');
    lbtoken.user = user;
    localStorage.update('lbtoken', lbtoken);

    this.setState({ user });
  }

  changeEditSettings = () => {
    let b = !this.state.disableForm;
    this.setState({
      disableForm: b,
      passwordForm: false
    });
  }

  changePasswordState = () => {
    let b = !this.state.passwordForm;
    this.setState({
      passwordForm: b
    });
  }

  render() {
    const { user, disableForm, passwordForm } = this.state;
    const { translate } = this.props;

    let edit_label = translate('ra.action.edit');
    let edit_icon = <EditIcon />;
    if(!disableForm) {
      edit_label = translate('ra.action.show');
      edit_icon = <ShowIcon />;
    }
    return (
      <Card>
        <Title title={translate('pos.profile.profile')} />

        <CardContent>
          <CardActions>
            {!disableForm && !passwordForm && 
              <Button
                onClick={this.changePasswordState}
                label={translate('pos.profile.change_password')}
              >
                <PasswordIcon />
              </Button>
            }

            <Button
              onClick={this.changeEditSettings}
              label={edit_label}
            >
              {edit_icon}
            </Button>
          </CardActions>

          {disableForm && <ProfileShow value={user} />}

          {!disableForm && !passwordForm &&
            <ProfileForm 
              value={user} 
              changeEditSettings={this.changeEditSettings}
              updateStorage={this.updateStorage}
            />
          }

          {!disableForm && passwordForm && 
            <PasswordForm
              changeEditSettings={this.changeEditSettings} 
            />
          }

        </CardContent>
      </Card>
    );
  }
}

export default translate(Profile);