import React from 'react';
import { SimpleModal } from '../../components';
import DialogContent from './AddDerivedObservationDialogContent';

const AddDerivedObservationDialog =({ onClose, open, selectedObsIds, title, searchLabel, datasetId, all_data, ...props}) => {

    return <SimpleModal open={open} onClose={onClose} title={title}>
      <DialogContent resource='derivedsets' selectedObsIds={selectedObsIds} close={onClose} datasetId={datasetId} all_data={all_data} listFilters={true}/>
    </SimpleModal>
 }

 export default AddDerivedObservationDialog;