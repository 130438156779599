import React, {Component} from 'react';
import {
  Button,
  fetchStart,
  fetchEnd,
  showNotification 
} from 'react-admin'
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@material-ui/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updateObj } from './localStorage'
import { connect } from 'react-redux';
import dataFetch from '../../providers/dataFetch';
import { URL } from '../../Configuration';
import { storage } from 'react-admin-loopback';

const TOKEN = "{*ACCESS_TOKEN*}";

class ManualInfo extends Component{
  constructor(props){
    super(props);
    this.state = {
      isEditing: false,
      text: ''
    };
  }

  static getDerivedStateFromProps(props, state){
    if(props.manualEntry && props.manualEntry.text && state.text === '') {
      if(props.manualEntry.text != null)
        state.text = props.manualEntry.text.split(TOKEN).join(`access_token=${storage.load('lbtoken').id}`);
    }
    return state;
  }

  handleClick = () => {    
    if(!this.state.isEditing) {
      this.setState({ isEditing: true });
    }
    else {
      let data = this.props.manualEntry;
      
      let txt = this.state.text;
      const regex = /(<img\s?src.*?\?)(access_token=.*?)(">)/i;

      while(txt.match(regex)) {
          txt = txt.replace(regex, "$1"+TOKEN+"$3");
      }

      data.text = txt;

      this.changeState = this.props.changeState;
      updateObj(data.id, data, this.props.type, this);
      this.setState({ isEditing: false });
    }
  }

  handleChange = (value) => {
    if(value != null) {
      const new_text = value.split(TOKEN).join(`access_token=${storage.load('lbtoken').id}`);
      this.setState({ text: new_text });
    }
  }
  
  imageHandler() {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();

      formData.append('file', file);

      // Save current cursor state
      const range = this.quill.getSelection(true);

      // Insert temporary loading placeholder image
      this.quill.insertEmbed(range.index, 'image', `${ window.location.origin }/images/loaders/placeholder.gif`); 

      // Move cursor to right side of image (easier to continue typing)
      this.quill.setSelection(range.index + 1);
      
      const headers = new Headers({
        Accept: 'application/json',
      });

      dataFetch('POST', '/manual_files/upload', headers, formData)
        .then(result => {
          const res = `${URL}${result.filePath}?access_token=${storage.load('lbtoken').id}`
          
          // Remove placeholder image
          this.quill.deleteText(range.index, 1);

          // Insert uploaded image
          this.quill.insertEmbed(range.index, 'image', res); 
        });
    }
  }

  modules = {
    toolbar: {
      container: [
            [{ 'font': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{ 'align': [] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image'],
            ['clean'],
          ],  // Selector for toolbar container
      handlers: {
        image: this.imageHandler
      }
    }
  };

  componentDidMount() {
    const {text} = this.props.manualEntry;
    if(text != null) {
      const new_text = text.split(TOKEN).join(`access_token=${storage.load('lbtoken').id}`);
      this.setState({ text: new_text });
    }
  }

  render(){
    const {isEditing, text} = this.state;

    return <div style={{paddingLeft: '24px'}}>
      {this.props.permissions === 'sysadmin' ?
        <div> 
        <Button onClick={this.handleClick} label={isEditing ? 'ra.action.save' : 'ra.action.edit'}>{isEditing ? <SaveIcon/> : <EditIcon/>}</Button>
        {isEditing ? 
          <ReactQuill 
            theme="snow" 
            value={text}
            onChange={this.handleChange}
            modules={this.modules}
          /> 
          : 
          <ReactQuill 
            theme="snow" 
            value={text}
            readOnly
            modules={{toolbar: false}}
          />}
        </div>
      :
        <ReactQuill 
          theme="snow" 
          value={text}
          readOnly
          modules={{toolbar: false}}
        />
      }
    </div>
  }
}

const enhance = connect(null, {
        showNotification,
        fetchStart,
        fetchEnd
});

export default enhance(ManualInfo);