import ReactDOM from "react-dom";
import React from "react";

import '../leaflet-extra-markers-improved/js/leaflet.extra-markers.min.js';
import '../leaflet-extra-markers-improved/css/leaflet.extra-markers.min.css';
import L from 'leaflet';

L.Timeline = L.GeoJSON.extend({
  interval: null,
  initialize(geojson, controlLayers, options = {}) {
    const defaultOptions = {
      marker: false,
      style: function (feature) {
        return feature.style
      }
    };
    L.GeoJSON.prototype.initialize.call(this, null, options);

    L.Util.setOptions(this, defaultOptions);
    L.Util.setOptions(this, options);

    // this.data = geojson;
    this.data = {
      features: [],
      type: "FeatureCollection"
    };

    this.openAnnotation = options.openAnnotation;

    this.layersKeys = [];

    let data = JSON.parse(JSON.stringify(geojson));
    // let data = geojson;

    this.data.features = data.features.map(feature => {
      feature.geometry.coordinates.reverse();
      return feature;
    });

    this.controlLayers = controlLayers;
    //Get min, max and keys from geojson
    this.interval = this._getInterval();
    this.marker = L.marker;
    this.annotations = (pos, color = 'rgb(0,255,0)') => {
      color = color.replace(')', ', 0.8)').replace('rgb', 'rgba');
      return L.circleMarker(pos, {
        // pane: 'markers1',
        radius: 8,
        stroke: true,
        color: 'rgb(0,0,0)', //"grey",
        weight: 1,
        opacity: 1,
        fillOpacity: 1,
        fillColor: color,
        className: "marker"
      });
    }

    this._buildLayers();
  },
  update(geojson, controlLayers, options = {}, map) {

    this.data = {
      features: [],
      type: "FeatureCollection"
    };

    // map.clearLayers();


    const data = JSON.parse(JSON.stringify(geojson));

    this.data.features = data.features

    this.data.features = data.features.map((feature) => {
      feature.geometry.coordinates.reverse();
      return feature;
    });

    let geojson2 = this.convertPointTrace(this.data, this.interval.min);

    const exists = [];
    let to_delete = [];
    const to_add = [];

    if (geojson2) {
      geojson2.features.forEach(({ properties }) => {
        if (properties && properties.key) {

          if (this.layersKeys.includes(properties.key)) {
            exists.push(properties.key);
          } else {
            to_add.push(properties.key);
          }
        }
      });

      to_delete = this.layersKeys.filter((key) => !to_add.includes(key) && !exists.includes(key));
    }


    geojson2.features = geojson2.features.filter(({ properties }) => to_add.includes(properties.key));

    this.addData(geojson2)

    this.eachLayer(layer => {
      if (to_delete.includes(layer.feature.properties.key)) {
        delete this._layers[layer._leaflet_id]; //The only way to delete...
        map.removeLayer(layer);
        this.controlLayers.removeLayer(layer);
      } else if (to_add.includes(layer.feature.properties.key)) {
        this.controlLayers.addOverlay(layer, layer.feature.properties.key);
      }
    });

    this.layersKeys = [...to_add, ...exists];
    this.updateDisplayedLayers(this.currentTime);
  },
  // get interval from each feature->properties.timestamp
  _getInterval() {
    let times = [];
    const features = this.data.features;
    for (var i in features) {
      if (!features[i].properties.multiLineId && times.indexOf(features[i].properties.timestamp) < 0)
        times.push(features[i].properties.timestamp);
    }
    times = times.sort();

    return {
      'steps': times,
      'min': times[0],
      'max': times[times.length - 1],
    };
  },
  _buildLayers() {
    let geojson = this.convertPointTrace(this.data, this.interval.min);
    let latlngs = [];

    if (geojson) {
      this.addData(geojson)
      geojson.features.forEach(({ geometry }) => {
        if (geometry.geometries)
          geometry.geometries.forEach(({ coordinates }) => {
            latlngs = [...latlngs, ...coordinates];
          });
      });
    }

    this.eachLayer(layer => {
      if (!layer.feature.properties.annotation) {
        this._buildMarker(layer);
      }

      this.controlLayers.addOverlay(layer, layer.feature.properties.key);
      this.layersKeys.push(layer.feature.properties.key);
    });


    if (latlngs.length > 0 && this.options.map) {
      this.options.map.fitBounds(latlngs, { padding: [100, 100], maxZoom: 15 });
    }
  },
  _buildMarker(layer) {
    let marker;
    let coordinates = [];
    layer.getLayers().forEach(l => {
      if (l instanceof L.Marker) {
        marker = l;
      } else {
        coordinates = l.getLatLngs();
      }
    });

    if ((this.options.marker !== false) && (coordinates.length > 0)/* && !layer.feature.properties.id.includes("invalid")*/) {
      if (marker) {
        marker.setLatLng(coordinates[coordinates.length - 1]);
        if (this.options.markerPopup)
          marker.setPopupContent(this.options.markerPopup(layer.feature.properties));
      } else {
        if (typeof (this.options.marker) !== "boolean")
          this.marker = this.options.marker;

        let mrk = this.marker(coordinates[coordinates.length - 1], layer.feature.properties);
        if (this.options.markerPopup)
          mrk.bindPopup(this.options.markerPopup(layer.feature.properties));
        layer.addLayer(mrk);
      }
    } else {
      if (marker) {
        layer.removeLayer(marker);
      }
    }
  },
  getIntervalValues() {
    return this.interval;
  },
  // This function must be improved
  updateDisplayedLayers(time) {
    this.currentTime = time;
    let geometries = {};
    let properties = {};
    let geojson = this.convertPointTrace(this.data, time);

    if (geojson) {
      geojson.features.forEach(feature => {

        if (feature.properties?.annotation)
          geometries[feature.properties.id] = feature.geometry.geometries;
        else
          geometries[feature.properties.id] = feature.geometry.geometries[0];
        properties[feature.properties.id] = feature.properties;
      });
    }

    this.eachLayer(layer => {
      layer.getLayers().forEach(l => {
        if (!(l instanceof L.Marker) && this.layersKeys.includes(layer.feature.properties.key)) {
          // Clone coordinates
          let geometry = geometries[layer.feature.properties.id].coordinates;
          // Update properties
          layer.feature.properties = properties[layer.feature.properties.id];
          // Update Geometry

          if (layer.feature.properties.annotation) {

            l.getLayers().forEach((nl) => {
              if ((nl instanceof L.CircleMarker)) {
                l.removeLayer(nl);
              }
            });

            geometries[layer.feature.properties.id].forEach((MultiPoint) => {
              MultiPoint.coordinates.forEach((coordinate) => {
                const popupNode = document.createElement('div');
                ReactDOM.render(
                  <div>
                    <div>
                      <h3 style={{ display: 'inline' }}>Tag: </h3>
                      <div style={{ display: 'inline' }}>{MultiPoint.properties.key}</div>
                    </div>
                    <br />
                    <div>
                      <h3 style={{ display: 'inline' }}>Annotation: </h3>
                      <div style={{ display: 'inline' }}>{MultiPoint.properties.description}</div>
                    </div>
                    {/* <br />
                    <div>
                      <h3 style={{ display: 'inline' }}>Timestamp: </h3>
                      <div style={{ display: 'inline' }}>{MultiPoint.properties.timestamp}</div>
                    </div> */}
                    {/* <div>Annotation: {MultiPoint.properties.description}</div> */}
                    <br />
                    <button onClick={() => { this.openAnnotation && this.openAnnotation(MultiPoint.properties?.pointId) }}>
                      OPEN
                    </button>
                  </div>, popupNode)

                let mrk = this.annotations(coordinate, layer.feature.style?.color).bindPopup(popupNode);

                l.addLayer(mrk);
              })
            })

          } else
            l.setLatLngs(geometry);

          // Rebuild Marker
          if (!layer.feature.properties.annotation) {
            this._buildMarker(layer);
          }
        }
      });
    });
  },
  // Must be a GeometryCollection in order to build a LayerGroup, otherwise it will create a layer and will not allow to add the Marker
  convertPointTrace(geojson, time) {
    let elements = {};
    //TODO: CHANGE
    if (geojson.features) {
      geojson.features.forEach(feature => {
        if (!elements[feature.properties.id]) {
          elements[feature.properties.id] = {
            properties: {
              id: feature.properties.id,
              key: feature.properties.key,
              metadata: {},
            },
            polyline: [],
            multilineCoordinates: {},
          };

          if (feature.style)
            elements[feature.properties.id]['style'] = feature.style;
          if (feature.properties.multiLineId) {
            elements[feature.properties.id].properties.multiLine = true;
          }
        }

        if (elements[feature.properties.id].properties.multiLine && !elements[feature.properties.id].properties.metadata[feature.properties.multiLineId]) {
          elements[feature.properties.id].properties.metadata[feature.properties.multiLineId] = {
            description: feature.properties.description,
            id: feature.properties.id,
            key: feature.properties.key,
            tag: feature.properties.tag,
            multiLineId: feature.properties.multiLineId
          }
        }

        if (feature.properties.timestamp <= time) {
          if (feature.properties.multiLineId) {
            if (!elements[feature.properties.id].multilineCoordinates[feature.properties.multiLineId])
              elements[feature.properties.id].multilineCoordinates[feature.properties.multiLineId] = [];

            elements[feature.properties.id].multilineCoordinates[feature.properties.multiLineId].push(feature.geometry.coordinates);
          }
          else {
            elements[feature.properties.id]['polyline'].push(feature.geometry.coordinates);
            elements[feature.properties.id]['properties'] = feature.properties;
          }

        }
      });


      let features = [];
      Object.keys(elements).forEach((i) => {
        let feature = {
          type: "Feature",
          geometry: {
            "type": "GeometryCollection",
            geometries: [] //[{ type: "LineString", coordinates: elements[i].polyline }]
          },
          properties: elements[i].properties
        };


        if (feature.properties.multiLine) {
          feature.properties.multiLineIds = Object.keys(elements[i].multilineCoordinates);

          if (feature.properties.multiLineIds.length === 0) {
            feature.geometry.geometries.push({
              type: "MultiPoint",
              coordinates: [],
              properties: { ...feature.properties }
            });
          }

          feature.properties.multiLineIds.forEach((id) => {
            const coordinates = elements[i].multilineCoordinates[id];
            const multiPoint = {
              type: "MultiPoint",
              coordinates,
              properties: { ...feature.properties.metadata[id], pointId: id }
            };

            delete multiPoint.properties.multiLineId;
            delete multiPoint.properties.multiLineIds;
            delete multiPoint.properties.timestamp;
            if (elements[i])
              multiPoint.style = elements[i].style;

            feature.geometry.geometries.push(multiPoint);
          });

          feature.properties.annotation = true;
        }

        else {
          feature.geometry.geometries.push({ type: "LineString", coordinates: elements[i].polyline });
        }

        if (elements[i])
          feature['style'] = elements[i].style

        features.push(feature);
      });

      return {
        type: "FeatureCollection",
        features: features
      };
    } else {
      return null;
    }
  },
  onRemove(map) {
    this.eachLayer(layer => {
      map.removeLayer(layer);
      this.controlLayers.removeLayer(layer);
    })
  }
});

export default L.timeline = (geojson, controlLayers, options) => new L.Timeline(geojson, controlLayers, options);
