import { USER_LOGIN_SUCCESS, changeLocale, showNotification } from 'react-admin';
import { put, takeEvery } from 'redux-saga/effects';
import { storage } from './providers';
import { changeTheme } from './containers/configuration/actions';
import { REFRESH_ANNOTATIONS, UPDATE_ANNOTATIONS } from './containers/derivedsets/actions';
import dataFetch from './providers/dataFetch';

function* setSettings(){
  const userSettings = storage.load('lbtoken').user.settings;
  if(userSettings){
    const {language, theme} = userSettings;
    if(language) yield put(changeLocale(language));
    if(theme) yield put(changeTheme(theme));
  }
}

function* updateAnnotations({payload}){
  const filter = {
    where: {
      derivedsetId: payload
    },
    // include: ["tags", "intervals"]
    include: [{ relation: "tags" }, { relation: "intervals", scope: { order: ['beginTime ASC'] } }]
  };

  try {
    const data = yield dataFetch('GET', `/annotations?filter=${JSON.stringify(filter)}`)
    yield put({type: UPDATE_ANNOTATIONS, payload: data})
  } catch(e) {
    yield put(showNotification('resources.annotations.messages.getAnnotations', 'warning'));
  }
}


export function* watchLoginSuccess(){
  yield takeEvery(USER_LOGIN_SUCCESS, setSettings);
  yield takeEvery(REFRESH_ANNOTATIONS, updateAnnotations);
}
