import React, { Fragment, Component} from 'react';
import {
  Button,
  fetchEnd,
  translate,
  fetchStart,
  refreshView,
  showNotification
} from 'react-admin';

import DeleteIcon from '@material-ui/icons/Delete'
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import dataFetch from '../../providers/dataFetch';
import { refreshAnnotations } from '../../containers/derivedsets/actions'


const removeObj = (props) => {
  const { derivedsetId, refreshAnnotations, fetchStart, showNotification, refreshView, fetchEnd, selectedIds, url, allData } = props;

  let toDelete = selectedIds.map(id => {
    return {
      datasetId: allData[id].datasetId,
      derivedsetId: allData[id].derivedsetId,
      timestamp: allData[id].timestamp,
      record: allData[id].record,
      id: allData[id].id
    }
  })

  const headers = new Headers({
    Accept: 'application/json',
    "Content-Type": "application/json"
  });

  fetchStart(); 
  
  dataFetch('DELETE', url, headers, JSON.stringify(toDelete))
    .then((result) => {
      refreshView()
      showNotification('resources.derivedsets.fields.removed_noti', 'info',  { messageArgs: { id: selectedIds.length } });
    })
    .catch(err => {
      if(err.error && err.error.code)
        showNotification(`errors.${err.error.code}`, 'warning');
      else
        showNotification(`errors.SOMETHING`, 'warning');
    })
    .finally(_ => {
      fetchEnd();
      refreshAnnotations(derivedsetId);
    });
}

class MultipleDeleteDerivesetObs extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    removeObj(this.props)
  }

  render() {
    return (
      <Fragment>
        <Button 
            label={this.props.translate('resources.derivedsets.fields.remove')}
            onClick={this.handleClick}
        >
          <DeleteIcon/>
        </Button>
      </Fragment>
    )
  }
}

export default compose(
  translate,
  connect(null, {
    fetchEnd,
    fetchStart,
    refreshView,
    showNotification,
    refreshAnnotations
}))(MultipleDeleteDerivesetObs)