import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset as resetFormAction } from 'redux-form';
import compose from 'recompose/compose';
import {
  LongTextInput,
  fetchEnd,
  fetchStart,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  SimpleForm,
  showNotification,
  translate,
  // required
} from 'react-admin';
import {
  Dialog, IconButton,
  DialogTitle,
  DialogContent,
  withStyles
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons'
import PropTypes from 'prop-types';
import ShowTime from './ShowTime';
import { dataFetch } from '../../providers';

const styles = {
  content: { padding: 0 },
  titleDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeButton: {
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  TextField: {
    maxWidth: "80%",
    display: 'flex',
    margin: '0 auto'
  },

  times: {
    paddingLeft: "24px",
    paddingRight: "24px"
  }
};

class Details extends Component {
  handleSave = (record) => {
    const { beginTime, endTime, recordId, showNotification, onClose, success } = this.props
    const headers = new Headers({
      Accept: 'application/json',
      "Content-Type": "application/json"
    });


    const filter = {
      intervals: [
        {
          beginTime: beginTime, //new Date(beginTime),
          endTime: endTime //new Date(endTime)
        },
      ],
      derivedsetId: recordId,
      text: record.description,
      tags: record.tagIds
    }

    const method = 'POST', url = '/annotations';

    dataFetch(method, url, headers, JSON.stringify(filter))
      .then(result => {
        onClose();
        success();
      })
      .catch(err => showNotification('resources.annotations.messages.createAnnotations', 'warning'));
  }

  componentDidMount() {
    this.props.reset('annotation');
  }

  render() {
    const { beginTime, endTime, translate, onClose, fullWidth, open, classes, description, tagIds } = this.props
    return (<Dialog open={open} onClose={onClose} fullWidth={fullWidth}>
      <span className={classes.titleDiv}>
        <DialogTitle >
          {translate('containers.annotations.modalTitle')}
        </DialogTitle>

        <IconButton classes={{ root: classes.closeButton }} onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </span>
      <DialogContent onClick={this.handleCLick}>
        <div className={classes.times}>
          <p > {ShowTime(translate('containers.annotations.begin'), beginTime)} </p>
          <p > {ShowTime(translate('containers.annotations.end'), endTime)} </p>
        </div>
        <SimpleForm form='annotation' save={this.handleSave} resource={'annotations'}>
          <LongTextInput source='description' defaultValue={description || ""} />
          <ReferenceArrayInput reference='tags' source='tagIds'>
            <Selection selected={tagIds} />
          </ReferenceArrayInput>
        </SimpleForm>
      </DialogContent>
    </Dialog>
    )
  }
}
const Selection = ({ selected, basePath, tagIds, ...props }) => {
  props.input.value = props.input.value === "" ? selected : props.input.value;

  return <SelectArrayInput {...props}>
    <ChipField source='name' />
  </SelectArrayInput>;
}
Details.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

const enhance = compose(
  translate,
  withStyles(styles),
  connect(null, {
    fetchStart,
    fetchEnd,
    showNotification,
    reset: resetFormAction,

  })
);

export default enhance(Details);