import React, { Component } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  withStyles
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const styles = {
  titleDiv:{
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeButton:{
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  paper:{
    overflowY: 'hidden',
  }
}

class SimpleModal extends Component {
  render(){
    const { children, classes, footer, maxWidth, onClose, onEntering, open, title, scroll } = this.props
    return (
      <Dialog classes={{paper: classes.paper}} paper={classes.paper} open={open} onClose={onClose} onEntering={onEntering} fullWidth scroll={scroll || 'paper'} maxWidth={maxWidth || false }>
        <div className={classes.titleDiv}>
          <DialogTitle>{title}</DialogTitle>
          <IconButton classes={{root: classes.closeButton}} onClick={() => onClose()}>
            <CloseIcon/>
          </IconButton>
        </div>
        <Divider/>
        <DialogContent>
          {children}
        </DialogContent>
        {footer}
      </Dialog>
    )
  }
}

export default withStyles(styles)(SimpleModal)