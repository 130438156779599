import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { translate } from 'react-admin';
import compose from 'recompose/compose';

const styles = {
  backButton: {
    marginTop: '10%',
    marginBottom: '10px',
    float: "left",
    padding: 0,
    minHeight: "19px",
    minWidth: "15%",
    color: "white",
    backgroundColor: 'rgba(63, 81, 181, 0.8)',
    "&:hover": {
      background: 'rgba(63, 81, 181, 1)'
    }
  },
  nextButton: {
    marginTop: '10%',
    marginBottom: '10px',
    float: "right",
    padding: 0,
    minHeight: 0,
    width: "auto",
    minWidth: "35%",
    color: "white",
    backgroundColor: 'rgba(63, 81, 181, 0.8)',
    "&:hover": {
      background: 'rgba(63, 81, 181, 1)'
    }
  },
  icon: {
    height: "17px"
  },
};

export default compose(translate, withStyles(styles))(({ classes, back = true, nextStageControl, prestage, nextstage, translate, ...props }) => {
  const nextClick = () => {
    if(nextStageControl)
      nextStageControl();
    else
      nextstage();
  }

  return (
    <div style={{ overflow: "hidden" }}>
      {back &&
        <Button
          classes={{ root: classes.backButton }}
          onClick={() => { prestage() }}
        >
          <ArrowBackIcon classes={{ root: classes.icon }} />
        </Button>
      }
      <Button
        classes={{ root: classes.nextButton }}
        onClick={nextClick}
      >
        { translate('containers.annotations.next') }
      </Button>
    </div>
  );
});