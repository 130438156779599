import React, { Component } from 'react';
import {
  LayerGroup,
  Marker,
  Polyline,
} from 'react-leaflet';

import '../../plugins/leaflet-extra-markers-improved/js/leaflet.extra-markers.min.js';
import '../../plugins/leaflet-extra-markers-improved/css/leaflet.extra-markers.min.css';
// import '../../plugins/leaflet-extra-markers/js/leaflet.extra-markers.min.js';
// import '../../plugins/leaflet-extra-markers/css/leaflet.extra-markers.min.css';

import L from 'leaflet';

import PathPopup from './PathPopup';

class PathLayer extends Component {

  render () {
    const { layer } = this.props;
    const path = layer.position.map((pos) => pos.geometry.coordinates.slice().reverse());
    return  <LayerGroup>
              <Polyline positions={path} color={layer.position[0].style.color}/>
              <Marker position={path[path.length - 1]}
                icon={L.ExtraMarkers.icon({
                  icon: layer.wavy.id.length > 4 ? 'fb-number' : 'fa-number',
                  markerColor: 'blue',
                  prefix: 'fa',
                  number: `${layer.wavy.id.substr(0, 2)}\n${layer.wavy.id.substr(2, layer.wavy.id.length)}`,
                })}    
                id={layer.wavy.id}
              >
                <PathPopup element={layer.wavy} last={layer.position[path.length - 1]} />
              </Marker>
            </LayerGroup>
  }
}

export default PathLayer;