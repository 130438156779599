import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles'
import { Control } from 'react-leaflet-control-inesc';
import Button from '@material-ui/core/Button';
import ClearMarkersIcon from '@material-ui/icons/LayersClear';
import { connect } from 'react-redux'
import { clearData } from './actions'

const styles = {
  actionButton: {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    width:'30px',
    height: '30px'
  }
}

const ClearMarkers = ({classes, clearData}) => {
  return <Control position="topright" >
    <div style={{backgroundColor:'white'}}>
      <Button classes={{root:classes.actionButton}} onClick={clearData}>
        <ClearMarkersIcon style={{fontSize:'15px', padding:'8px', color:'black'}}/>
      </Button>
    </div>
  </Control>
};

export default withStyles(styles)(connect(null, {clearData})(ClearMarkers));