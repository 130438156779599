export const OPEN_SECTION_MODAL = 'OPEN_SECTION_MODAL';
export const OPEN_SUBSECTION_MODAL = 'OPEN_SUBSECTION_MODAL';
export const CLOSE_MANUAL_MODALS = 'CLOSE_MANUAL_MODALS';
export const EDIT_SECTION = 'SELECT_SECTION';
export const EDIT_SUBSECTION = 'SELECT_SUBSECTION';

export const openSectionModal = (sectionId) => ({
  type: OPEN_SECTION_MODAL,
  payload: sectionId
});

export const openSubSectionModal = (subSectionId) => ({
  type: OPEN_SUBSECTION_MODAL,
  payload: subSectionId
});

export const closeManualModals = () => ({
  type: CLOSE_MANUAL_MODALS
});

export const editingSection = (section) => ({
  type: EDIT_SECTION,
  payload: section
})

export const editingSubSection = (subSection) => ({
  type: EDIT_SUBSECTION,
  payload: subSection
})