import React, { Component } from 'react';
import {
  Title,
  translate,
  fetchEnd,
  fetchStart,
} from 'react-admin';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { WEBSOCKET_URL } from '../../Configuration';
import LeafletMap from '../../components/map/LeafletMap';
import { onNewLiveData as newLiveDataAction, onOldLiveData as oldLiveDataAction } from './actions';
import ClearMarkers from './ClearMarkers';
import LoadOldData from './LoadOldData';
import OverviewControl from './OverviewControl';
import dataFetch from '../../providers/dataFetch';

import io from 'socket.io-client';
import { storage } from 'react-admin-loopback';

// import { 
//   emit as emitWS,
//   insideRoom as insideRoomWS,
//   listen as listenWS,
//   stopListen as stopListenWS,
// } from '../../sockets'


class Live extends Component {
  state = {
      observedProperties: {},
      viewport: {
        center: [41.1619, -8.5835],
        zoom: 5,
      },
      last_transmission: {},
      socket: undefined
    };

  componentDidMount() {
    const id = storage.load('lbtoken').id;
    const userId = storage.load('userId');
    const socket = io(WEBSOCKET_URL, { transports: ['websocket'/*, 'polling', 'flashsocket'*/] });
    
    socket.on('connect', () => {
      socket.emit('authentication', {id: id, userId: userId });
      socket.on('authenticated', () => {
        socket.emit('enter', {room : 'live'});
      });

      socket.on('unauthorized', (err) => {
        this.setState({socket: undefined});
        console.error("Socket authentication fail");
      });
    });

    socket.on('live', (data) => { 
      const {last_transmission} = this.state;
      if(!last_transmission[data.serialNumber] || new Date(data.timestamp) > last_transmission[data.serialNumber]) {
        this.setState({last_transmission: {...last_transmission, [data.serialNumber]: new Date(data.timestamp)}});
        this.getObservedProperties(data)
        .then(observedProperties => {
          this.props.newLiveData({observation: data, observedProperties: observedProperties});
        })
        .catch(err => console.error(err));
      }
    });

    this.setState({socket: socket});
    
    // this.props.emit('enter', {room : 'live'})
    // .catch(err => {
      //   if(SOCKET_DEBUG)
      //     console.log("err LIVE", err);
    // });
    
    // setTimeout(() => {
      //   this.props.insideRoom('live')
      // }, 5000)
      
      // this.props.listen('live', this.newLiveData)
      // .catch(err => {
        //   if(SOCKET_DEBUG)
        //     console.log("err LIVE", err);
        // });
  }
  
  componentWillUnmount() {
    
    if(this.state.socket) {
      this.state.socket.emit('leave', {room : 'live'});
      this.state.socket.disconnect();
      this.setState({socket: undefined});
    }
    // this.props.stopListen('live')
    // .catch(err => {
    //   if(SOCKET_DEBUG)
    //     console.log("err LIVE", err);
    // });

    // this.props.emit('leave', {room : 'live'})
    // .catch(err => {
    //   if(SOCKET_DEBUG)
    //     console.log("err LIVE", err);
    // });
  }

  getObservedProperties = (data) => {
    return new Promise((resolve, reject) => {
      const equipmentModelId = data && data.equipment &&
        data.equipment.equipmentmodelId;

      const { observedProperties } = this.state;
      if (equipmentModelId) {
        if (observedProperties[equipmentModelId]) {
          resolve(observedProperties[equipmentModelId]);
        } 
        else {
          const filter = JSON.stringify({
            include: 'observedproperty',
            where: {equipmentmodelId: equipmentModelId}
          });

          dataFetch('GET', `/modelproperties?filter=${filter}`)
            .then((result) => {
              observedProperties[equipmentModelId] = result;
              this.setState({observedProperties: observedProperties});
              resolve(result);
            }).catch(err => reject(err));
        }
      } else {
        reject('unknown');
      }
    });
  };

  loadOldData = () => {
    // var now = new Date();
    var yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
    const filter_old = {
      where: {
        and: [
          {timestamp: {
            gt: yesterday
            // gt: new Date(`${now.getFullYear()}-${now.getUTCMonth() + 1}-${now.getUTCDate()}`)
          }},
          {position: {
            neq: "null"
          }}
        ]
      },
      fields: [
        "timestamp",
        "serialNumber",
        "position",
        "id"
      ],
      order: ["timestamp ASC"]
    };


    let wavys = [], old = [], modelIds = {}, ids = [];

    this.props.fetchStart();
    dataFetch('GET', `/realtime_observations?filter=${JSON.stringify(filter_old)}`)
      .then(rto => {
        
        rto.forEach(observation => {
          if(!wavys.includes(observation.serialNumber))
            wavys.push(observation.serialNumber);
        });
        old = rto;
        const filter_equipment = {
          where: { 
            serialNumber: {
              inq: wavys
            }
          },
          fields: ["serialNumber", "properties", "equipmentmodelId"]
        };

        return dataFetch('GET', `/equipment?filter=${JSON.stringify(filter_equipment)}`);
      })
      .then(equip => {
        equip.forEach(model => {
          if(!modelIds[model.serialNumber])
            modelIds[model.serialNumber] = {modelId: model.equipmentmodelId, color: model.properties ? model.properties.color : "rgb(51, 136, 255)"};
        });

        ids = [...new Set(Object.values(modelIds).map(model => model.modelId))];
        const { observedProperties } = this.state;
        ids = ids.filter(id => (observedProperties[id] === undefined || observedProperties[id] === null) ? true : false);

        const filter_observedProperty = {
          include: {
            relation: "observedproperty"
          },
          where: {
            equipmentmodelId: {
              inq: ids
            }
          }
        };

        const filter_lastPos = (wavy) => ({
          where: {
            and: [
              {serialNumber: wavy},
              {position: {
                neq: "null"
              }}
            ]
          },
          order: "timestamp DESC",
          limit: 1
        });

        const promises = [];
        if(ids.length > 0)
          promises.push(dataFetch('GET', `/modelproperties?filter=${JSON.stringify(filter_observedProperty)}`));
        else
          promises.push({onState: true});

        wavys.forEach(wavy => {
          promises.push(dataFetch('GET', `/realtime_observations?filter=${JSON.stringify(filter_lastPos(wavy))}`));
        });
        
        return Promise.all(promises);
      })
      .then(result => {
        let { observedProperties, last_transmission } = this.state;
        const obsRequest = result.shift();

        if(obsRequest.length > 0 && !obsRequest.onState) {
          ids.forEach(model => {
            observedProperties[model] = obsRequest.filter(obs => obs.equipmentmodelId === model);
          });

          this.setState({observedProperties: observedProperties});
        }

        const last_transmission_to_add = {};
  
        result.forEach((obs) => {
          const data = obs[0];
          if(!last_transmission[data.serialNumber] || new Date(data.timestamp) > last_transmission[data.serialNumber]) {
            last_transmission_to_add[data.serialNumber] = new Date(data.timestamp);
          }
        });
        this.setState({last_transmission: {...last_transmission, ...last_transmission_to_add}});
        
        this.props.oldLiveData({observations: old, observedProperties: observedProperties, latest: result, wavys: modelIds});

      }).finally(_ => this.props.fetchEnd())
  }

  render() {
    const { translate, observations } = this.props;
    return (
      <div style={{display:'flex', flex: '1'}}>
        <Title title={translate('containers.maps.live.name')} />
        <LeafletMap note={true} viewport={this.state.viewport} overlays={observations} currentLocation={true}>
          <LoadOldData loadOldData={this.loadOldData} />
          <ClearMarkers />
          <OverviewControl />
        </LeafletMap>
      </div>
    );
  }
}

const mapStateToProps = state => ({
    observations: state.liveData
});

const mapDispatchToProps = dispatch => {
  return ({
  newLiveData: data => dispatch(newLiveDataAction(data)),
  oldLiveData: data => dispatch(oldLiveDataAction(data)),
  fetchEnd: () => dispatch(fetchEnd()),
  fetchStart: () => dispatch(fetchStart()),
  // listen: (...data) => dispatch(listenWS(...data)),
  // stopListen: (...data) => dispatch(stopListenWS(...data)),
  // emit: (...data) => dispatch(emitWS(...data)),
  // insideRoom: (...data) => dispatch(insideRoomWS(...data))
})};

const enhance = compose(
  translate,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(Live);