import React from 'react';
import './index.css';
import params from './params';
import Chart from './Chart';
import Chart2 from './Chart2';
import Options from './Options';

import { connect } from 'react-redux';
import compose from 'recompose/compose';

import {
    fetchStart,
    fetchEnd,
    showNotification
} from 'react-admin';

import { DateTime } from 'luxon';

import {
    dataFetch,
} from '../../../providers';

function countSatellites(data, minimum_elevation = 5) {
    const parsed = {};
    const MAX_DEGREE = 40;
    data.root.forEach(({ begin, middle, end, middleElevation }, index) => {
        begin = DateTime.fromJSDate(new Date(begin));
        end = DateTime.fromJSDate(new Date(end));
        middle = DateTime.fromJSDate(new Date(middle));

        // const max_probability = middleElevation / 40 > 1 ? 1 : middleElevation / 40;
        // const max_probability = middleElevation / 90;


        const duration = end.diff(begin, 'seconds').toObject().seconds;

        // seconds since begin to the middleElevation
        let mid_duration = middle.diff(begin, 'seconds').toObject().seconds;

        const degreeByTime = (time, ascend) => {
            // m = ∆y / ∆x
            // y – y0 = m (x – x0)

            const m = (ascend ? mid_duration : (duration - mid_duration)) / (90 - minimum_elevation);

            // time = m * (x - minimum_elevation); 
            // time = (m * x ) - (m * minimum_elevation); 
            // time = (m * x ) - (m * minimum_elevation); 
            // m * x = time + (m * minimum_elevation);

            return (time + (m * minimum_elevation)) / m;
        }

        let ascend = true;
        let count_seconds = 0;

        let now = begin;

        // satellites per hour
        const begin_hour = begin.get('hour');
        if (!parsed[begin_hour]) {
            parsed[begin_hour] = {
                metadata: {
                    satellites: 1
                }
            };
        } else {
            parsed[begin_hour].metadata.satellites += 1
        }

        const end_hour = end.get('hour');

        if (end_hour !== begin_hour) {
            if (!parsed[end_hour]) {
                parsed[end_hour] = {
                    metadata: {
                        satellites: 1
                    }
                };
            } else {
                parsed[end_hour].metadata.satellites += 1
            }
        }

        // columns
        const COLUMNS_SIZE = 10;
        let now_2 = begin;
        now_2.set({ second: 0 });
        const minute = now_2.get('minute');

        const mod = minute % COLUMNS_SIZE;

        if (mod > 0) {
            now_2 = now_2.minus({ minutes: mod });
        }

        while (now_2 < end) {

            if (!parsed[now_2.get('hour')]) {
                parsed[now_2.get('hour')] = {
                    [now_2.get('minute')]: {
                        satellites: 0,
                        seconds: 0,
                        probability: 0,
                        index: [],
                        total: 0
                    }
                };
            }

            else if (!parsed[now_2.get('hour')][now_2.get('minute')]) {
                parsed[now_2.get('hour')][now_2.get('minute')] = {
                    satellites: 0,
                    seconds: 0,
                    probability: 0,
                    index: [],
                    total: 0
                };
            }

            parsed[now_2.get('hour')][now_2.get('minute')].total += 1;

            now_2 = now_2.plus({ minutes: COLUMNS_SIZE });
        }

        while (now < end) { // ??? lt or le ???
            const minute = now.get('minute');
            const hour = now.get('hour');
            let seconds = 60;

            // if is begin time => ex 06:35:20 seconds are 40 and set to 0
            if (begin.get('minute') === minute && begin.get('hour') === hour) {
                seconds = 60 - now.get('second');
                now.set({ second: 0 });
            }
            else if (end.get('minute') === minute && end.get('hour') === hour) {
                seconds = now.get('second');
            }
            else if ( // can be possible ?
                begin.get('minute') === minute && begin.get('hour') === hour &&
                end.get('minute') === minute && end.get('hour') === hour
            ) {
                seconds = duration;
            }

            now = now.plus({ minutes: 1 });

            if (!parsed[hour][minute]) {
                parsed[hour][minute] = {
                    satellites: 0,
                    seconds: 0,
                    probability: 0,
                    index: [],
                    // total: 0
                };
            }

            parsed[hour][minute].satellites += 1;
            parsed[hour][minute].seconds += seconds;

            // if(minute % 10 === 0) {
            //     parsed[hour][minute].total += 1;
            // }
            let probability = 0;

            if (middle.get('minute') === minute && middle.get('hour') === hour) {
                ascend = false;

                // const start = max_probability * count_seconds / mid_duration;
                // const mid = max_probability * (count_seconds + middle.get('second')) / mid_duration;

                // mid_duration = duration - mid_duration;

                // const end = max_probability * (duration - (count_seconds + seconds)) / mid_duration;

                // const probability_p_minute = seconds / 60;
                // const degree = degreeByTime(mid_duration, true);
                probability = middleElevation / MAX_DEGREE > 1 ? 1 : middleElevation / MAX_DEGREE;//((start + end) / 2);

                // probability = max_probability; //((start + end) / 2);
            }
            else {
                if (ascend) {
                    const start_prob = degreeByTime(count_seconds, true) / MAX_DEGREE;
                    const start = start_prob > 1 ? 1 : start_prob;

                    const end_prob = degreeByTime(count_seconds + seconds, true) / MAX_DEGREE;
                    const end = end_prob > 1 ? 1 : end_prob;
                    // const start = max_probability * count_seconds / mid_duration;
                    // const end = max_probability * (count_seconds + seconds) / mid_duration;

                    probability = ((start + end) / 2);

                } else {
                    const start_prob = degreeByTime(duration - count_seconds, false) / MAX_DEGREE;
                    const start = start_prob > 1 ? 1 : start_prob;

                    const end_prob = degreeByTime(duration - (count_seconds + seconds), false) / MAX_DEGREE;
                    const end = end_prob > 1 ? 1 : end_prob;


                    // const start = max_probability * (duration - count_seconds) / mid_duration;
                    // const end = max_probability * (duration - (count_seconds + seconds)) / mid_duration;

                    probability = ((start + end) / 2);
                }
            }

            parsed[hour][minute].index.push({ index, probability });
            parsed[hour][minute].probability += probability;

            count_seconds += seconds;


        }
    });

    return parsed;
}

const parseSatellites = (days, data) => {
    let final_data = [];

    const intervals = 5; // in minutes and 60%intervals=0

    let now = DateTime.local().set({ hour: 0, minute: 0, second: 0 });
    const final = now.plus({ days: 1 });


    while (now <= final) {
        const interval_object = {
            // total_satellites: 0,
            probability: 0,
            avg: 0,
            // total: 0
        };

        const hour = now.get('hour');
        const minute = now.get('minute');

        let satellites = 0;
        let probability = 0;
        let minutes_without_data = 0;

        for (let i = minute; i < minute + intervals; i++) {
            if (!data[hour] || !data[hour][i]) {
                minutes_without_data += 1;
            } else {
                probability += data[hour][i].probability;
                satellites += data[hour][i].satellites;
            }
        }

        if (data[hour] && data[hour][minute] && minute % 10 === 0) {
            interval_object.total = data[hour][minute].total;
            interval_object.avg = (data[hour][minute].total / Math.round(days)).toFixed(1);
        }

        if (satellites > 0) {
            interval_object.probability = (probability / (satellites + minutes_without_data) * 100).toFixed(1);
        }

        if (now.get('minute') === 0 && data[hour]) {
            interval_object.total_satellites = data[hour].metadata.satellites;
        }

        if (Object.keys(interval_object).length > 0) {
            interval_object.date = now.toJSDate();
            final_data.push(interval_object);

            if (now.get('minute') === 0 && now.plus({ hours: 1 }) <= final && interval_object.total_satellites) {
                interval_object.date_st = `${now.toFormat('HH:mm')} - ${now.plus({ hours: 1 }).toFormat('HH:mm')}`;
            }
        } else if (now.get('minute') === 0) {
            interval_object.date = now.toJSDate();
            final_data.push(interval_object);
        }

        now = now.plus({ minutes: intervals });
    }
    return final_data;
};

class SatelliteCover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: undefined,
            total_days: undefined,
            user_color: params.colors[0].color,
            chart1_color: params.colors[0].chart1_color,
            chart1_color_prob: params.colors[0].chart1_color_prob,
            chart2_color: params.colors[0].chart2_color,
            // minimum_elevation: 10
        };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    handleUpdate(property, value) {
        if (property === 'user_color') {
            switch (value) {
                case params.colors[0].color:
                    this.setState({ chart2_color: params.colors[0].chart2_color, chart1_color: params.colors[0].chart1_color, chart1_color_prob: params.colors[0].chart1_color_prob });
                    break;
                case params.colors[1].color:
                    this.setState({ chart2_color: params.colors[1].chart2_color, chart1_color: params.colors[1].chart1_color, chart1_color_prob: params.colors[1].chart1_color_prob });
                    break;
                case params.colors[2].color:
                    this.setState({ chart2_color: params.colors[2].chart2_color, chart1_color: params.colors[2].chart1_color, chart1_color_prob: params.colors[2].chart1_color_prob });
                    break;
                default:
                    this.setState({ chart2_color: params.colors[0].chart2_color, chart1_color: params.colors[0].chart1_color, chart1_color_prob: params.colors[0].chart1_color_prob });
            }
        }
        this.setState({ [property]: value });
    };

    fetchData(lat, lng, alt, start_date, end_date, minimum_elevation) {
        return new Promise((resolve, reject) => {
            this.props.fetchStart();

            let url = '/satellites?begin=' + start_date + '&end=' + end_date + '&lon=' + lng + '&lat=' + lat + '&alt=' + alt + "&minEle=" + minimum_elevation;
            dataFetch('GET', url)
                .then((data) => {

                    //TODO: tratar o erro qnd não recebemos satélites
                    if (!data.root[0]) {
                        this.setState({ data: undefined });
                        resolve();
                    }
                    const begin = DateTime.fromJSDate(new Date(data.root[0].begin));
                    const end = DateTime.fromJSDate(new Date(data.root[data.root.length - 1].end));

                    const total_days = end.diff(begin, 'days').toObject().days;
                    const data_tmp = parseSatellites(total_days, countSatellites(data, minimum_elevation));

                    this.setState({ data: data_tmp });
                    resolve();
                })
                .catch((error) => {
                    this.props.showNotification('resources.campaigns.satellites.request_error', 'warning');
                    reject(error);
                })
                .finally(_ => this.props.fetchEnd());
        })
    };

    render() {
        return (
            <div>
                <Options fetchData={this.fetchData} user_color={this.state.user_color} handleUpdate={this.handleUpdate} {...this.props} style={{ display: 'flex', flexDirection: 'row' }} />
                {this.state.data && <Chart data={this.state.data} chart1_color={this.state.chart1_color} chart2_color={this.state.chart2_color} chart1_color_prob={this.state.chart1_color_prob} />}
                {this.state.data && <Chart2 data={this.state.data} chart1_color={this.state.chart1_color} chart2_color={this.state.chart2_color} />}
            </div>
        );
    }
}

const enhance = compose(
    connect(
        null,
        {
            fetchEnd,
            fetchStart,
            showNotification
        }
    )
);

export default enhance(SatelliteCover);