import React, { Component } from 'react';
import { Button, withStyles } from '@material-ui/core';
import { FilterCenterFocus as CenterIcon } from '@material-ui/icons';

import { Control } from 'react-leaflet-control-inesc';
import L from 'leaflet';

const styles = {
  button: {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    width: '30px',
    height:'30px',
  }
}

class CenterControl extends Component {
  centerMap = () => {
    const { map } = this.props;
    let latlngs = [];

    map.eachLayer(layer => {
      // Not valid for live map - No GeoJSON available
      // if(layer instanceof L.GeoJSON)
        // latlngs = layer.getBounds();
      if ((layer instanceof L.Polyline) && (layer.getLatLngs().length > 0))
        latlngs = latlngs.concat(layer.getLatLngs());
    });

    if (latlngs.length > 0)
      map.fitBounds(latlngs);
  };

  render() {
    const {classes, ...props} = this.props
    return (
      <Control position="topleft" className="leaflet-control-zoom leaflet-bar leaflet-control">
        <div style={{background:'white'}}>
          <Button 
            {...props}
            classes={{root:classes.button}}
            onClick={this.centerMap}
          >
            <CenterIcon style={{fontSize:'15px', padding:'8px', color:'black'}}/>
          </Button>
        </div>
      </Control>
    );
  }
}

export default withStyles(styles)(CenterControl);