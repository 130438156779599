import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { 
  fetchStart,
  fetchEnd,
  SimpleForm,
  showNotification,
  TextInput,
  translate,
} from 'react-admin';

import { API_URL } from '../../Configuration';
import { storage as localStorage } from 'react-admin-loopback';

class PasswordForm extends Component {
  state = {
    password: {
      old_password: null,
      new_password: null,
      repeat_password: null
    }
  }

  changePassword = (form) => {
    const { showNotification, fetchStart, fetchEnd } = this.props;
    if (form.new_password !== form.repeat_password) {
      showNotification('pos.profile.different_passwords', 'warning')
      return;
    }

    fetchStart();
    // Could not use the data provider
    fetch(`${API_URL}/users/change-password?access_token=${localStorage.load('lbtoken').id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify({
        oldPassword: form.old_password,
        newPassword: form.new_password,
      }),
    }).then(response => {
      if (response.status === 204){
        showNotification('pos.profile.password_changed');
      } else {
        return response.json();
      }
    }).then(data => {
      if(typeof data === 'undefined' || data === null) {
        this.props.changeEditSettings();
        return;
      }
      showNotification(`error.${data.error.code}`, 'warning');
    }).finally(fetchEnd);
  }

  render() {
    const { 
      translate
    } = this.props;

    return ( 
      <SimpleForm record={this.state.password} save={this.changePassword}>
        <TextInput label={translate('pos.profile.old_password')} source="old_password" type="password" />
        <TextInput label={translate('pos.profile.new_password')} source="new_password" type="password" />
        <TextInput label={translate('pos.profile.repeat_password')} source="repeat_password" type="password" />
      </SimpleForm>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    fetchEnd,
    fetchStart,
    showNotification,
  })
);
export default enhance(PasswordForm);