import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles'
import { Control } from 'react-leaflet-control-inesc';
import Button from '@material-ui/core/Button';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';

const styles = {
  actionButton: {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    width:'30px',
    height: '30px'
  }
}

const LoadOldData = ({classes, loadOldData}) => (
       <Control position="topright" >
          <div style={{backgroundColor:'white'}}>
          <Button classes={{root:classes.actionButton}} onClick={loadOldData}>
              <SystemUpdateAltIcon style={{fontSize:'15px', padding:'8px', color:'black'}}/>
          </Button>
          </div>
      </Control>
);

export default withStyles(styles)(LoadOldData);