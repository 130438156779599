import React, { Component } from 'react';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import compose from 'recompose/compose';
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import {
  SimpleForm,
  TextInput,
  translate,
  showNotification,
  UPDATE
} from 'react-admin';

import { dataProvider } from '../../providers';

const FORM_NAME = 'change_password';

class ChangePasswordDialog extends Component {

  validatePasswords = ({password, repeat_password}) => {
    const errors = {};
    const { translate } = this.props;
    if(!password){
      errors.password = [translate('ra.validation.required')];
    }
    if(!repeat_password){
      errors.repeat_password = [translate('ra.validation.required')];
    }
    if(password && repeat_password && password !== repeat_password){
      errors.repeat_password = [translate('pos.profile.different_passwords')];
    }

    return errors
  }

  handleSave = (data) => {
    const { resource, resourceId, onClose, destroyForm, showNotification } = this.props;
    
    const params = {
      id: resourceId,
      data: {
        password: data.password
      }
    }
    dataProvider(UPDATE, resource, params)
    .then(_ => {
      destroyForm(FORM_NAME);
      onClose();
      showNotification('pos.profile.password_changed');
    })
    .catch(error => {
      showNotification('ra.notification.http_error');
    })
  }

  render(){
    const { open, onClose, translate } = this.props
    return <Dialog  open={open} onClose={onClose} fullWidth>
      <DialogTitle>{translate('pos.profile.change_password')}</DialogTitle>
      <DialogContent>
        <SimpleForm validate={this.validatePasswords} save={this.handleSave} form={FORM_NAME}>
          <TextInput type='password' source='password' label='pos.profile.new_password'/>
          <TextInput type='password' source='repeat_password' label='pos.profile.repeat_password'/>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  }

}

export default compose(
  translate,
  connect(null, { showNotification, destroyForm: destroy })
)(ChangePasswordDialog)