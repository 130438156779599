import React, { Component } from 'react';
import {
  Button,
} from 'react-admin';
import {
  ListItem,
  ListItemText
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ManualInfo from './ManualInfo';

class ManualSubsection extends Component {
  state = {
    subsectionOpen : false
  };
  
  handleClick = () => {
    if(!this.state.subsectionOpen) {
      this.setState({ subsectionOpen: true });
    }
    else {
      this.setState({ subsectionOpen: false });
    }
  }

  handleEditClick = () => {
    this.props.onEdit(this.props.subsection);
  }

  render(){
    const { subsection, permissions, changeState } = this.props;

    return (
      <div style={{marginRight: '16px', marginLeft: '16px'}}>
        <div style={{display: 'flex', width:'100%', flexDirection: 'row'}}>
          <ListItem style={{flex:1}} button onClick={this.handleClick}>
            <ListItemText secondary={`${subsection.section}.${subsection.subsection} ${subsection.title}`}/>
          </ListItem>
          {this.props.permissions === 'sysadmin' && <Button onClick={this.handleEditClick} label={'ra.action.edit'}>{<EditIcon/>}</Button>}
        </div>
        {this.state.subsectionOpen && <ManualInfo changeState={changeState} type={"subsections"} manualEntry={subsection} permissions={permissions}/>}
      </div>
    )
  }
}

export default ManualSubsection;