import React from 'react';
import get from 'lodash/get';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import luxon from '../input/Picker/utils/luxon-utils.js'
import {
  translate
} from 'react-admin';

export const DateField = ({
  className,
  locales,
  format,
  options,
  record,
  source,
  addLabel,
  allowEmpty,
  basePath,
  cellClassName,
  formClassName,
  headerClassName,
  label,
  linkType,
  locale,
  resource,
  sortable,
  sortBy,
  textAlign,
  translateChoice,
  localDate,
  translate,
  noFields,
  simpleText,
  ...rest
}) => {
  if (!record) {
    return null;
  }

  const value = get(record, source);
  if (value == null) {
    if(simpleText) return null
    return (
      <Typography
        component="span"
        variant="body1"
        className={className}
        {...rest}
      >
        {translate('dates.not_defined')}

      </Typography>
    );
  }
  const util = new luxon();

  const dateUTC = util.date(value, true).toFormat(format) + (noFields ? '' : ' UTC');
  const dateLocal = util.date(value, false).toFormat(format) + ' ' + util.date(value, false).toFormat('ZZZZ');

  return simpleText ?
    dateUTC
    :
    (
      <Typography
        component="span"
        variant="body1"
        className={className}
        {...rest}
      >
        {dateUTC}
        {localDate && <br />}
        {localDate && dateLocal}

      </Typography>
    );
};

const EnhancedDateField = compose(translate)(DateField);
// const EnhancedDateField = pure(DateField);

EnhancedDateField.defaultProps = {
  addLabel: true,
  format: 'dd/MM/yyyy HH:mm:ss',
  localDate: false,
  simpleText: false
};

EnhancedDateField.displayName = 'EnhancedDateField';

export default EnhancedDateField;