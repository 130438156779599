import React, { Component } from 'react'
import {
  Button,
  crudGetList as crudGetListAction,
  crudUpdateMany as crudUpdateManyAction, 
  translate,
  showNotification,
} from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import {
  DialogContent,
  withStyles,
} from '@material-ui/core'
import { Save } from '@material-ui/icons'
import memoize from 'memoize-one'
import dataFetch from '../../providers/dataFetch';

const styles = {
  button:{
    marginTop: '2em',
    marginBottom: '2em'
  }
};
/*
  basePath, recordId, pagination => url to save (button)
  pagination
  reference => if needs do a new request (Pagination problem)
  changeIdOnRequest => on newrequest, we need change a result data, if we need change a object key (needed if reference)
  listFilters={<LocalSearch filter=""/>}> => search => deprecated

  manyField => resource vs props => launch equip vs campaign equip
*/

class Relation extends Component{

  //TODO: Throw error if no current related are specified
  constructor(props){
    super(props);

    this.state = {
      selectedIds: [],
      count:0,
      sort: props.currentSort,
      version: 0,
      page: 1,
      perPage: props.perPage || 10,
      
      ids: [], // list of Ids to show on DialogBox
      data: undefined, // obj - content data related with ids
      newRelatedData: undefined, // if need new request 
      needUpdate: false, // if new request and need wait for data
      disabledSaveButton: false,
      search: ""
    };
    this.doRequest = this.doRequest.bind(this);
  }

  doRequest(url) {
    const { changeIdOnRequest } = this.props;

    const fetch = () => {
      return new Promise( (resolve, reject) => {
        dataFetch('GET', url)
          .then(data => {
            resolve(data)
          })
          .catch(err => reject(err));
      });
    };

    fetch().then( (result) => {
      let relatedData = {};

      Object.keys(result).forEach(key => {
        const obj = result[key]
        if (changeIdOnRequest) {
          relatedData[obj[changeIdOnRequest]] = obj;
        }
        else {
          relatedData[key] = obj;
        }
        delete result[key];
      });
      result = null;
      this.setState({ newRelatedData: relatedData });
      this.updateDialogData();
    })
    .catch(err => {
      if(err.error && err.error.code)
        showNotification(`errors.${err.error.code}`, 'warning');
      else
        showNotification(`errors.SOMETHING`, 'warning');
    }); 
  }

  filterRelatedIds = memoize( (allIds, relatedData) => {
    if(allIds && relatedData){
      const orderedIds = allIds.reduce((acc, id) => { 
        if(!relatedData[id]){
          acc.push(id) ;
        }
        return acc;
      }, []);
      return orderedIds;
    }
    return [];
  });

  filterRelatedData = memoize( (allData, relatedData) => {
    if(allData && relatedData){
      const filteredData = Object.assign({}, allData);

      //Maybe try to reduce instead of delete
      Object.keys(relatedData).map(relatedId => { delete filteredData[relatedId]; return relatedId} );        
      return filteredData;
    }
    return null;
  });

  onChangeSearch = (e) => {
    this.setState({search: e.target.value}, this.fetchAll);
  }

  componentWillMount(){
    const { reference, manyField, allData, allIds } = this.props;

    if(manyField) {
      this.setState({ data: this.filterRelatedData(allData, this.state.newRelatedData) });
      this.setState({ ids: this.filterRelatedIds(allIds, this.state.newRelatedData) });
    }
    else {
      this.fetchAll();
    }

    if(reference) {
      this.doRequest(reference);
    }
    else {
      this.setState({ needUpdate: true });
    }
  }
  
  setSort = field => {
    const order =
    this.state.sort.field === field &&
    this.state.sort.order === 'ASC'
    ? 'DESC'
    : 'ASC';
    this.setState({ sort: { field, order } }, this.fetchAll );
  }
  
  toggleItem = (id) => {
    this.state.selectedIds.includes(id) ? this.setState(state => {
      state.selectedIds.splice(state.selectedIds.indexOf(id), 1)
      return {selectedIds: state.selectedIds, version: state.version+1}
    }) 
    : this.setState(state => {
      state.selectedIds.push(id)
      return {selectedIds: state.selectedIds, version: state.version+1}
    })
  }
  
  selectedIds = (allIds) => {
    this.state.selectedIds.length < allIds.length ? 
      this.setState((state)=>({selectedIds: allIds, version: state.version+1})) 
    : this.setState((state)=>({selectedIds: [], version: state.version+1}))
  }

  fetchAll(){
    const { resource, crudGetList } = this.props
    crudGetList(resource, {page:-1, perPage:0}, this.state.sort, {q:this.state.search})
  }

  relateData = () => {
    this.setState({disabledSaveButton: true})
    const { basePath, recordId, referencePath, crudUpdateMany } = this.props
    crudUpdateMany(`${basePath}/${recordId}${referencePath}/rel`, this.state.selectedIds, {}, '')
  }

  setPage = page => this.setState({page})

  setPerPage = perPage => {this.setState({perPage})}

  componentDidUpdate(oldProps) {
    const {allIds, allData} = this.props;

    if(oldProps.allIds !== allIds && oldProps.allData !== allData) {
      this.updateDialogData();
    }
  }

  updateDialogData() {
    if(this.state.needUpdate || this.props.manyField) {
      const {reference, allIds, relatedData, allData} = this.props;
     
      if(reference) {
        this.setState({ data: this.filterRelatedData(allData, this.state.newRelatedData) });
        this.setState({ ids: this.filterRelatedIds(allIds, this.state.newRelatedData) });
      }
      else {
        this.setState({ data: this.filterRelatedData(allData, relatedData) });
        this.setState({ ids: this.filterRelatedIds(allIds, relatedData) });
      }
    }
    else {
      this.setState({ needUpdate: true });
    }
  }

  render(){
    const {allData, classes, children, pagination, resource, listFilters } = this.props;
    const {page, perPage, data, ids} = this.state;

    const paginatedIds = ids.slice((page-1)*perPage,page*perPage)
    return (
      <DialogContent>
        {listFilters && React.cloneElement(listFilters, {
          /*
            label, => (optional)
            Parent: this,
            indexArray: string, => name of array on state (contains ids of obj)
            obj: string, => name of obj on state (contains all data)
            filter: string => name of param inside obj to filter 
          */
            Parent: this,
            indexArray: 'ids',
            obj: 'data'
        })}


        {React.cloneElement(children, {
          currentSort: this.state.sort, 
          data: data ? data : {}, //: allData,
          ids: paginatedIds,
          setSort: this.setSort, 
          hasBulkActions: true,
          onToggleItem: this.toggleItem,
          onSelect: this.selectedIds,
          resource,
          selectedIds: this.state.selectedIds,
          version: this.state.version
        })}
        {pagination && React.cloneElement(pagination, {
          isLoading: Object.values(allData).length === 0,
          page,
          perPage,
          setPage: this.setPage,
          setPerPage: this.setPerPage,
          total: ids.length
        })}

        {ids.length > 0 ? <Button disabled={this.state.disabledSaveButton} className={classes.button} variant='raised' label='ra.action.save' onClick={this.relateData}><Save/></Button> : null}
      </DialogContent>
    )
  }
}

function mapStateToProps(state, props){
  let resourceState, data, ids;
  if(props.manyField) {
    resourceState = props
    ids = resourceState.ids;
    data = resourceState.data;
  }
  else {
    resourceState = state.admin.resources[props.resource]
    ids = resourceState.list.ids;
    data = resourceState.data;
  }
  return {
    allData: data,
    allIds: ids
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
  connect(
    mapStateToProps,
    { 
      showNotification,
      crudGetList: crudGetListAction,
      crudUpdateMany: crudUpdateManyAction,
    }
  )
)

export default enhance(Relation)