import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  ListButton,
  SimpleForm,
  TextInput,
  crudGetOne,
  required,
  translate,
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  TabbedShowLayout,
  Tab,
  Datagrid
} from 'react-admin';

import {
  Edit,
  DateTimeInput,
  ReferenceManyManyField,
} from '../../components';
import compose from 'recompose/compose';
import {
  Typography,
  withStyles
} from '@material-ui/core';
import { parse } from 'query-string';

const listStyles = function styles(theme) {
  const height = 32;
  let backgroundColor = theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700];
  // var deleteIconColor = (0, _colorManipulator.fade)(theme.palette.text.primary, 0.26);
  return {
    tag_root: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(13),
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: height,
      color: theme.palette.getContrastText(backgroundColor),
      backgroundColor: backgroundColor,
      borderRadius: height / 2,
      whiteSpace: 'nowrap',
      transition: theme.transitions.create(['background-color', 'box-shadow']),
      // label will inherit this from root, then `clickable` class overrides this for both
      cursor: 'default',
      // We disable the focus ring for mouse, touch and keyboard users.
      outline: 'none',
      textDecoration: 'none',
      border: 'none',
      // Remove `button` border
      padding: 0, // Remove `button` padding,

    },
    tag_label: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 12,
      paddingRight: 12,
      userSelect: 'none',
      whiteSpace: 'nowrap',
      cursor: 'inherit'
    }
  }
}
class _NoteTagField extends Component {
  render(){
    let key = 0;
    const {classes, record} = this.props;

      return (
        <span  className={classes.tag_root} key={key++}>
          <span className={classes.tag_label} > {record.name}</span>
        </ span>
    )
  }
}

const NoteTagField = withStyles(listStyles)(_NoteTagField);

const redirect = (basePath, id, data) => {
    return `/campaigns/${data.campaignId}/show/notes`;
  }

class EditActionsClass extends Component {
    state={}
  
    static getDerivedStateFromProps(props){
  
      if(props.data && !props.campaign){
        props.crudGetOne('campaigns', props.data.campaignId, '/notes')
      }
  
      return null
    }
  
    render() {
      return <div style={{display:'flex', justifyContent:'space-between'}}>
        <Typography variant='title'>{this.props.campaign && this.props.campaign.name}</Typography>
        {this.props.data && <ListButton to={`/campaigns/${this.props.data.campaignId}/show/notes`}/>}
      </div>
    }
  }
  
const mapStateToEditActionProps = (state, props) => {
    return { campaign: props.data ? state.admin.resources.campaigns.data[props.data.campaignId] : null }
}
  
const EditActions = connect(mapStateToEditActionProps, { crudGetOne })(EditActionsClass);

const CustomSimpleForm = ({record, redirect, ...props}) => (
    <SimpleForm {...props} record={record} redirect={`/campaigns/${record.campaignId}/show/notes`}>
      {props.children}
    </SimpleForm>
);

class NotesEditView extends Component {

    render() {
      const { campaign, translate, ...props } = this.props;
      return <Edit actions={<EditActions />} {...props} >
        <CustomSimpleForm redirect={redirect}    >
          <TextInput source='description' />
          <DateTimeInput
            validate={required()}
            source='beginTime'
            label={translate('resources.launches.fields.beginTimestamp')}
            options={{
              format: 'dd/MM/YYYY HH:mm',
              clearable: true
            }}
          />
        </CustomSimpleForm>
      </Edit>
    }
  }

  class NotesShowView extends Component {

    render() {
      const { campaign, classes, translate, record,...props } = this.props;
      return      (
      <Show actions={<EditActions />} {...props}>      
        <TabbedShowLayout>
          <Tab label='resources.notes.tabs.details'>
            <SimpleShowLayout>
              <TextField source="description" />
              <DateField source="beginTime" showTime />
            </SimpleShowLayout>
          </Tab>
          <Tab label='tags'>
            <ReferenceManyManyField addLabel={false} reference="tags" target="noteid">
              <Datagrid>
              <NoteTagField label='resources.notes.tabs.description' source={record} classes={classes}/>
              </Datagrid>
            </ReferenceManyManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>)
    }
  }
  const mapStateToProps = (state, props) => {
    const search = parse(props.location.search, { arrayFormat: 'bracket' })
    return {
      campaign: state.admin.resources.campaigns.data[search.campaignId]
    }
  }
  const enhanceCreateEdit = compose(
    translate,
    connect(mapStateToProps, {
      crudGetOne
    })
  )
export const NotesEdit = enhanceCreateEdit(NotesEditView);
export const NoteShow = enhanceCreateEdit(NotesShowView);
