import lodashMemoize from 'lodash/memoize';

const getMessage = (message, messageArgs, value, values, props) =>
typeof message === 'function'
    ? message({
      args: messageArgs,
      value,
      values,
      ...props,
      })
    : props.translate(message, {
      _: message,
      ...messageArgs,
    });

const memoize = fn => lodashMemoize(fn, (...args) => JSON.stringify(args));

export const matchingPasswords = memoize(
  (message = 'pos.profile.different_passwords') => (value, values, props) => values.password !== values.rewrite_password 
  ? getMessage(message, 2, value, values, props)
  : undefined
);
