import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Card,
  CardContent, 
  CardActions
} from '@material-ui/core'
import { 
  FieldTitle
} from 'react-admin';

class MobileDatagridCard extends Component {
  
  handleToggle = event => {
    this.props.onToggleItem(this.props.id)
    event.stopPropagation()
  }
  
  render() {
    const {
      actions,
      basePath,
      children,
      classes,
      id,
      record,
      resource,
      hasBulkActions,
      checked,
    } = this.props;

    return (
      <Card key={id} className={classes.card}>
        {
          hasBulkActions && <Checkbox checked={checked} onChange={this.handleToggle}/>
        }
        { actions && <CardActions classes={{root:classes.cardActions}}>{actions.map((component, index) => React.cloneElement(component, {basePath, record, resource, key:index, ...component.props}))}</CardActions>}
        <CardContent className={classes.cardContent}>
          {React.Children.map(
            children,
            (field, index) =>
              field ? (
                <div>
                  <FieldTitle 
                    label={field.props.label}
                    source={field.props.source}
                    resource={resource}
                  />
                  {React.cloneElement(field, {
                    record,
                    basePath: field.props.basePath || basePath,
                    resource
                  })}
                </div>
              ) : null
          )}
        </CardContent>
      </Card>
    );
  }
}

MobileDatagridCard.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  id: PropTypes.any,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string,
};

export default MobileDatagridCard;