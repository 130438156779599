import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { DirectionsBoat as BoatIc } from '@material-ui/icons';

import CampaignDialog from './CampaignDialog';
import { Control } from 'react-leaflet-control-inesc';

class CampaignControl extends Component {
  state = {
    open: false,
    selectedValue: null,
  };

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <Control position="topright" className="leaflet-bar">
        <div>
          <Button 
            style={{
              padding: 0,
              minHeight: 0,
              minWidth: 0,
              background: 'white'
            }}
            onClick={this.handleClickOpen}
          >
            <BoatIc style={{fontSize:'15px', padding:'8px', color:'black'}}/>
          </Button>
          <CampaignDialog 
            open={this.state.open}
            onClose={this.handleClose}
          />
        </div>
      </Control>
    );
  }
}

export default CampaignControl;