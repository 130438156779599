import React from 'react';
import { translate } from 'react-admin';
import Toolbar from './Toolbar';
import compose from 'recompose/compose';

export default compose(translate)(({translate, ...props}) => {
  return (
    <div style={{ marginTop: "5%", paddingLeft: '10px', paddingRight: '10px' }}>
        
        <div>
          { translate('containers.annotations.choose', { hour: translate('containers.annotations.begin')}) }
        </div>
        
        <Toolbar back={false} {...props}/>
      </div>
  );
});