import React, { Component } from 'react';
import { ChipField, translate } from 'react-admin';
import {
  Button,
  IconButton,
  ListItem,
  ListItemText,
  Collapse,
  Typography
} from '@material-ui/core';

import { ExpandLess, ExpandMore } from '@material-ui/icons';

import ReferenceManyManyField from '../field/ReferenceManyManyField';
import EditAnnotationsDialog from '../../resources/annotations/CreateAnnotationsDialog';

const TagsIterator = ({ data }) => {
  return Object.values(data).map((tag) => (
    <ChipField key={tag.id} record={tag} source='name'/> 
  ));
}

//WARNING: Component is not scalable
class AccordionItem extends Component {
 
  state = {
    open: false,
    editOpen: false
  }

  handleOpen = () => {
    this.setState((state) => ({open: !state.open}))
  }

  handleEditOpen = () => {
    this.setState({editOpen:true})
  }

  render(){
    return <div>
      <ListItem divider >
        <ListItemText>{this.props.record.title}</ListItemText>
        <Button style={{marginRight: '1em'}} variant='raised' color='primary' onClick={this.handleEditOpen} >{this.props.translate('ra.action.edit')}</Button>
        <ReferenceManyManyField record={this.props.record} basePath='/annotation' resource='annotations' reference='tags'>
          <TagsIterator/>
        </ReferenceManyManyField>
        <IconButton onClick={this.handleOpen}>
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <ReferenceManyManyField record={this.props.record} basePath='/annotation' resource='annotations' reference='tags'>
          <EditAnnotationsDialog open={this.state.editOpen} record={this.props.record} onClose={()=>this.setState({editOpen:false})} />
        </ReferenceManyManyField>
      </ListItem>
      <Collapse in={this.state.open}>
        <ListItem divider>
          <ListItemText>
            {
              this.props.record.text.split('\n').map((annotationText, index) => <Typography key={index}>{annotationText}</Typography>)
            }
          </ListItemText>
        </ListItem>
      </Collapse>
    </div>
  }
}

export default translate(AccordionItem);