import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const Status = ({source, record, className, ...props}) => {

  let status = record[source];

  if(status === 'ERROR' && record.comments && record.comments.includes('DUPLICATED'))
    status = 'DUPLICATED';

  switch(status) {
    case 'SUCCESS': 
    case 'SUCCESS_INCOHERENCE': 
      return (
        <Tooltip title={'Success'}>
          <CheckCircleIcon style={{ color: 'rgba(50,205,50)' }}/>
        </Tooltip>
      )

    case 'PENDING': 
      return (
        <Tooltip title={'Pending'}>
          <RadioButtonUncheckedIcon style={{ color: 'rgba(255,165,0)' }}/>
        </Tooltip>
      )

    case 'DUPLICATED': 
      return (
        <Tooltip title={'Duplicated'}>
          <ErrorOutlineIcon style={{ color: 'rgba(255,165,0)' }}/>
        </Tooltip>
      )

    default: 
      return (
        // <Tooltip title={`Error`}>
        <Tooltip title={record.comments ? record.comments : 'Error'}>
          <ErrorOutlineIcon style={{ color: 'rgba(255,0,0)' }}/>
        </Tooltip>
      )
  }
}

Status.defaultProps = {
  addLabel: true,
};

export default Status;