import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Note as NoteIconIcon } from '@material-ui/icons';

import {
  Button,
  fetchStart,
  fetchEnd,
  showNotification,
  translate
} from 'react-admin';

import AnnotationsDialog from './AnnotationsDialog';

class AnnnotationsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    }
  }

  render() {
    return (
      <>
        <Button
          label='containers.annotations.modalTitle'
          onClick={() => this.setState({modalOpen: true})}
          disabled={this.props.disabled}
        >
          <NoteIconIcon />
        </Button>

        <AnnotationsDialog recordId={this.props.recordId} modalOpen={this.state.modalOpen} modalClose={() => this.setState({modalOpen: false})} inserted_annotations={this.props.inserted_annotations}/>

      </>
    )
  }
}

export default compose(
  translate,
  connect(null, {
    fetchStart,
    fetchEnd,
    showNotification,
  })
)(AnnnotationsButton);