import * as actions from './actions';

const defaultState = {
  sectionModalOpen: false,
  subSectionModalOpen: false,
  section: null,
  subsection: null
}

export default (previousState = defaultState, {type, payload}) => {
  switch(type){
    case actions.OPEN_SECTION_MODAL: {
      return Object.assign({}, previousState, {sectionModalOpen: payload})
    }
    case actions.OPEN_SUBSECTION_MODAL: {
      return Object.assign({}, previousState, {subSectionModalOpen: payload})
    }
    case actions.EDIT_SECTION:{
      return Object.assign({}, previousState, {section: payload})
    }
    case actions.EDIT_SUBSECTION:{
      return Object.assign({}, previousState, {subsection: payload})
    }
    case actions.CLOSE_MANUAL_MODALS:{
      return defaultState;
    }
    default: return previousState
  }
};