import { Component } from 'react'
import {
  GET_LIST,
  showNotification,
  fetchStart,
  fetchEnd
} from 'react-admin'
import { connect } from 'react-redux'

import dataProvider from '../../providers/dataProvider'

class ReferenceManyManyFieldController extends Component {

  constructor(props){
    super(props)
    this.state = {
      sort: props.sort,
      page: 1,
      perPage: props.perPage,
      total: 0
    }
  }

  componentWillMount() {
    this.fetchRelated()
  }

  componentDidUpdate(oldProps) {
    if(this.props.keepUpdating && JSON.stringify(oldProps.filter) !== JSON.stringify(this.props.filter)) {
      this.fetchRelated()
    }
  }

  fetchRelated({ filter, params={}, record, recordId, parentrecord, resource, reference, showNotification, fetchStart, fetchEnd, prefix } = this.props){
    if(prefix)
      resource = prefix

    if(!record && recordId) 
      record = { id: parentrecord[recordId] }

    fetchStart()
    dataProvider(GET_LIST,`${resource}/${record.id}/${reference}`,{
      sort: this.state.sort,
      pagination: {
        page: this.state.page,
        perPage: this.state.perPage
      },
      include: params.include,
      filter
    })
    .then(response => {
      const ids = []
      const data = response.data.reduce((acc, record) => {
        ids.push(record.id)
        return {...acc, [record.id]: record}
      }, {})
      this.setState({data, ids, total:response.total})
    })
    .catch(e => {
      console.error("ERROR-> ", e)
      showNotification('ra.notification.http_error')
    })
    .finally(fetchEnd)
  } 
  
  setSort = field => {
    const order =
        this.state.sort.field === field &&
        this.state.sort.order === 'ASC'
            ? 'DESC'
            : 'ASC';
    this.setState({ sort: { field, order } }, this.fetchRelated);
  };

  setPage = page => this.setState({ page }, this.fetchRelated);

  setPerPage = perPage => this.setState({ perPage }, this.fetchRelated);

  render(){
    const { resource, reference, children, basePath, record } = this.props
    const { page, perPage, total } = this.state;

    const referenceBasePath = basePath.replace(resource, reference);

    return children({
      parentrecord: record,
      currentSort: this.state.sort,
      data: this.state.data,
      ids: this.state.ids,
      isLoading: typeof this.state.ids === 'undefined',
      page,
      perPage,
      referenceBasePath,
      setPage: this.setPage,
      setPerPage: this.setPerPage,
      setSort: this.setSort,
      total
    })
  }
}

ReferenceManyManyFieldController.defaultProps = {
  perPage: 25,
  sort: {field: 'id', order: 'DESC'}
}

export default connect( null, {
  showNotification,
  fetchStart,
  fetchEnd
})(ReferenceManyManyFieldController)