import React, {Fragment} from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import ReferenceManyManyFieldController from './ReferenceManyManyFieldController';

const styles = {
  progress: {marginTop: '1em'}
};

export const ReferenceManyManyFieldView = ({
  children,
  classes = {},
  className,
  currentSort,
  data,
  ids,
  isLoading,
  page,
  pagination,
  perPage,
  reference,
  referenceBasePath,
  setPerPage,
  setPage,
  setSort,
  total,
  parentrecord,
}) => (
  isLoading ? 
    <LinearProgress className={classes.progress} />
  :
      <Fragment>
        {React.cloneElement(children, {
          className,
          resource: reference,
          ids,
          data,
          basePath: referenceBasePath,
          currentSort,
          setSort,
          total,
          parentrecord
        })}
        {pagination && React.cloneElement(pagination, {
          page,
          perPage,
          setPage,
          setPerPage,
          total
        })}
      </Fragment>
);

export const ReferenceManyManyField = ({ children, ...props}) => {
  if(React.Children.count(children) !== 1) {
    throw new Error( '<ReferenceManyField> only accepts a single child (like <Datagrid>)' )
  }
  if(props.limit !== undefined) {
    props.perPage = props.limit;
  }

  return <ReferenceManyManyFieldController {...props}>
    {controllerProps => (<ReferenceManyManyFieldView 
    {...props} 
    {...{children, ...controllerProps}} /> )}
  </ReferenceManyManyFieldController>
}

ReferenceManyManyField.defaultProps = {
  filter: {},
  perPage: 25,
  sort: {field: 'id', order:'DESC' },
  source: 'id'
}

const EnhancedReferenceManyManyField = withStyles(styles)(ReferenceManyManyField)

EnhancedReferenceManyManyField.defaultProps = {
  addLabel: true,
  source: 'id'
}

export default EnhancedReferenceManyManyField