import { MapControl } from 'react-leaflet';
import L from 'leaflet';
import './leaflet-mobmeasure.js';
import './leaflet-mobmeasure.css';

export default class MobMeasureControl extends MapControl {
	createLeafletElement(props) {
		// const { minSize = 10, markerCluster, markerOptions, ...options } = props;
		return L.mobMeasureControl(props);
	}

	componentDidMount() {
		const { map } = this.context;
		this.leafletElement.addTo(map);
	}
}