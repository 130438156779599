import { NEW_LIVE_DATA, CLEAR_DATA, OLD_LIVE_DATA } from './actions';

export default (previousState = [], { type, payload }) => {
  switch (type) {
    case NEW_LIVE_DATA:
      let index = previousState.findIndex(item => item.wavy.id === payload.wavy.id);
      let copy = previousState.slice();
      
      if (index >= 0) {
        copy[index].position.push(payload.position[0]);
        return copy;
      } else {
        copy.push(payload);
        return copy;
      }
    case OLD_LIVE_DATA:
      return payload;
    case CLEAR_DATA: 
      return [];
    default:
      return previousState;
  }
};