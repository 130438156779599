import React from "react";
import { translate } from 'react-admin';
import compose from "recompose/compose";
import { withWidth } from '@material-ui/core';
import params from './params';

//Chart imports
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

class Chart extends React.Component {
    constructor(props) {
        super(props)
        this.fontSize = this.fontSize.bind(this)
    }

    fontSize = function () {
        if (this.props.width && this.props.width === 'xs') {
            return params.font_size_mobile;
        } else {
            return params.font_size_desktop;
        }
    };

    componentDidMount() {
        // Themes begin
        am4core.useTheme(am4themes_animated);

        // Create chart instance
        let chart = am4core.create("chartdiv", am4charts.XYChart);
        chart.padding(30, 0, 0, 0);
        chart.responsive.enabled = true;


        //Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.skipEmptyPeriods = false;
        dateAxis.periodChangeDateFormats.setKey("hour", "H");
        dateAxis.dateFormats.setKey("hour", "H");
        dateAxis.renderer.minGridDistance = 35;
        dateAxis.title.text = this.props.translate('resources.campaigns.satellites.chart1_xAxis_title');
        dateAxis.title.fontSize = this.fontSize();
        dateAxis.renderer.labels.template.fontSize = this.fontSize();
        dateAxis.renderer.labels.template.location = 0.0001;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = this.props.translate('resources.campaigns.satellites.chart1_yAxis_avereage_title');
        valueAxis.title.fontSize = this.fontSize();
        valueAxis.renderer.labels.template.fontSize = this.fontSize();
        valueAxis.cursorTooltipEnabled = false;

        let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.renderer.opposite = true;
        valueAxis2.title.text = this.props.translate('resources.campaigns.satellites.chart1_yAxis_probability_title');
        valueAxis2.title.fontSize = this.fontSize();
        valueAxis2.renderer.fontSize = this.fontSize();
        valueAxis2.cursorTooltipEnabled = false;

        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        // series.dataFields.valueY = "total";
        series.dataFields.valueY = "avg";
        series.dataFields.dateX = "date";
        series.name = "[font-size: " + this.fontSize() + "px]" + this.props.translate('resources.campaigns.satellites.chart1_avereageSeries_name') + "[/]";
        series.dataItems.template.locations.dateX = 1;
        // series.columns.template.width = am4core.percent(1000);
        series.columns.template.width = am4core.percent(150);
        series.cursorTooltipEnabled = false;
        // series.stroke = am4core.color(this.props.chart1_color);
        series.columns.template.fill = am4core.color(this.props.chart1_color);
        series.columns.template.tooltipText = "Avg: [bold]{valueY}[/] sat";
        series.columns.template.tooltipPosition = "pointer";
        // series.columns.template.label.textAlign = "middle";
        // series.columns.template.label.textValign = "bottom";
        // series.tooltipText = "Avg: [bold]{valueY}[/] sat/h";


        // Create series 2
        let series2 = chart.series.push(new am4charts.LineSeries());
        series2.yAxis = valueAxis2;
        series2.dataFields.valueY = "probability";
        series2.dataFields.dateX = "date";
        series2.name = "[font-size: " + this.fontSize() + "px]" + this.props.translate('resources.campaigns.satellites.chart1_probabilitySeries_name') + "[/]";
        series2.stroke = am4core.color(this.props.chart1_color_prob);
        series2.fill = am4core.color(this.props.chart1_color_prob);
        series2.strokeWidth = 2;
        // series2.opacity = 0;
        // series2.tooltip.background.cornerRadius = 5;
        // series2.tooltip.background.strokeOpacity = 0;
        // series2.tooltip.label.minWidth = 20;
        // series2.tooltip.label.minHeight = 20;
        // series2.tooltip.label.textAlign = "left";
        // series2.tooltip.label.textValign = "top";
        series2.tooltip.pointerOrientation = "left";
        series2.tooltipText = "Cov: [bold]{valueY}[/]%";

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineY.disabled = true;

        // var bullet = series2.bullets.push(new am4charts.CircleBullet());
        // bullet.circle.strokeWidth = 0.00001;
        // bullet.circle.stroke = am4core.color(this.props.chart1_color_prob);
        // bullet.circle.fill = am4core.color(this.props.chart1_color_prob);
        // bullet.tooltipText = "Prob: {valueY}%";

        // Add legend
        chart.legend = new am4charts.Legend();

        this.chart = chart;
        this.series = series;
        this.series2 = series2;
        // this.bullet = bullet;
        this.dateAxis = dateAxis;
        this.valueAxis = valueAxis;
        this.valueAxis2 = valueAxis2;
        this.chart.data = this.props.data;

    }
    componentDidUpdate(oldProps) {
        if (oldProps.chart1_color !== this.props.chart1_color || oldProps.chart2_color !== this.props.chart2_color || oldProps.chart1_color_prob !== this.props.chart1_color_prob) {
            this.series.columns.template.fill = am4core.color(this.props.chart1_color);
            this.series.fill = am4core.color(this.props.chart1_color);
            this.series.stroke = am4core.color(this.props.chart1_color);

            this.series2.stroke = am4core.color(this.props.chart1_color_prob);
            this.series2.fill = am4core.color(this.props.chart1_color_prob);
            // this.bullet.circle.stroke = am4core.color(this.props.chart1_color_prob);
            // this.bullet.circle.fill = am4core.color(this.props.chart1_color_prob);
        }
        if (oldProps.width !== this.props.width && (this.props.width === 'xs' || oldProps.width === 'xs')) {
            this.dateAxis.title.fontSize = this.fontSize();
            this.dateAxis.renderer.labels.template.fontSize = this.fontSize();
            this.valueAxis.renderer.labels.template.fontSize = this.fontSize();
            this.valueAxis.title.fontSize = this.fontSize();
            this.valueAxis2.renderer.fontSize = this.fontSize();
            this.valueAxis2.title.fontSize = this.fontSize();
            this.series.name = "[font-size: " + this.fontSize() + "px]" + this.props.translate('resources.campaigns.satellites.chart1_avereageSeries_name') + "[/]";
            this.series2.name = "[font-size: " + this.fontSize() + "px]" + this.props.translate('resources.campaigns.satellites.chart1_probabilitySeries_name') + "[/]";
        }
        if (oldProps.data !== this.props.data) {
            this.chart.data = this.props.data;
        }
    }
    componentWillUnmount() {
        this.chart.dispose();
    }
    render() {
        return (<div id="chartdiv" className="chart-div">
        </div>);
    }
}

const enhance = compose(
    translate,
    withWidth()
);

export default enhance(Chart);