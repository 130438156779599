import ReactDOM from 'react-dom'
import { MapControl } from 'react-leaflet'
import LeafletControl from './LeafletControl'

export default class Control extends MapControl {

  componentWillMount(){
    this.leafletElement = this.createLeafletElement(this.props)
    this.leafletElement.addTo(this.context.map)
  }

  componentDidMount(){

  }

  createLeafletElement() {
    return new LeafletControl(this.props)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.leafletElement.getContainer())
  }

}
